import React ,{useEffect, useState} from "react";
import { Container ,Row, Button,Form, Modal , FloatingLabel, Toast} from "react-bootstrap";
import { useParams } from "react-router-dom";
import HeaderStatic from "../Shared/HeaderStatic";
import SearchTable from "../../components/SearchTable/SearchTable";
import FooterResult from "../Shared/FooterResult";
import Gallery from 'react-grid-gallery';
import axios from "axios";
import MaskedInput from 'react-maskedinput';
import logo from '../../Content/pdf.png';

function Detail(props) {
    const loginUser= props.loginUser;
    const { companyId } = useParams();
    const [company,setCompany] = useState({
        "id": 27,
        "email": "ahmet@example.com",
        "userName": "ahmet",
        "phoneNumber": "1536594562",
        "fullName": "ahmet maranki",
        "emailConfirmed": false,
        "address": "Yıldırım Beyazıt Mah Teknopark no:57",
        "city": "Kayseri",
        "district": "Melikgazi",
        "taxNumber": "613231232",
        "companyUserId": null,
        "companyName": "Ülker",
        "companyOfficalName": "Ahmet Maranki",
        "memberShipType": 0,
        "roleId": 2,
        "userRoles": [
            {
                "userId": 27,
                "roleId": 2,
                "role": {
                    "id": 2,
                    "name": "User",
                    "normalizedName": "USER",
                    "active": true
                }
            },
            {
                "userId": 27,
                "roleId": 3,
                "role": {
                    "id": 3,
                    "name": "Customer",
                    "normalizedName": "CUSTOMER",
                    "active": true
                }
            },
            {
                "userId": 27,
                "roleId": 4,
                "role": {
                    "id": 4,
                    "name": "Company",
                    "normalizedName": "COMPANY",
                    "active": true
                }
            }
        ],
        "userPrices": [],
        "userBrands": [],
        "active": true
    });
    const [filteredProducts, setFilteredProducts] = useState([]);
    
    const getProducts = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Product/Search?DistributorId="+companyId,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
            setFilteredProducts(response.data.Data.Data.filter(t=> t.Id !== 0));
          }).catch(function (error) {
                console.log(error.response);
          })
    }
    useEffect(() => {
        getProducts();
    },[]);
    const getCompany = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/User/"+companyId,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
              setCompany(response.data);
          }).catch(function (error) {
                console.log(error.response);
          })
    };
    useEffect(() => {
        getCompany();
    },[]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);    
    const [showAlert, setShowAlert] = useState(false);
    const handleCloseSuggest = () => setshowSuggest(false);
    const [showSuggest, setshowSuggest] = useState(false);
    const [validated, setValidated] = useState(false);
    const [IMAGES, setIMAGES] = useState([])
    const [catalog, setCatalog] = useState([])
    const [catalogName, setCatalogName] = useState([]);
    const getAllMedia = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Media/GetDistrubutorMedia?userId=" + companyId ,
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            // console.log(response.data.Data);
            var tempData = [];
            for(var i=0 ; i<response.data.Data.length; ++i) {
                tempData.push("https://www.listefix.com\\" + response.data.Data[i].substring(response.data.Data[i].indexOf("uploads"),response.data.Data[i].length))
            }            
            let tempImages = [];
            let tempCatalog = [];
            tempData.map( (image) => {
                    if(image.includes(".jpg") || image.includes(".png") || image.includes(".jpeg") || image.includes(".webp")) {
                        tempImages.push(
                            {
                                src: image,
                                thumbnail: image,
                                thumbnailWidth: 320,
                                thumbnailHeight: 174
                            }
                        );  
                    }
                    if(image.includes(".pdf")) {
                        tempCatalog.push(image);
                    }

                }
            )
            setCatalog(tempCatalog);
            setIMAGES(tempImages);
            axios({
                method: "get",
                url: "https://www.listefix.com/api/Media/GetDistrubutorMediaNames?userId=" + companyId ,
                headers: {
                    Authorization: "Bearer "+localStorage.getItem("listfixToken")
                }
            }).then(function (response) {
                let tempCatalogName = [];
                response.data.Data.map( (image) => {

                        if(image.includes(".pdf")) {
                            tempCatalogName.push(image.substring(0, image.length - 4));
                            
                        }
    
                    }
                )
                setCatalogName(tempCatalogName);
            }).catch(function (error) {
                console.log(error.response);
            });
        }).catch(function (error) {
            console.log(error.response);
        });
    }
    const handleCloseWithMessage = (event) => {
        const form = document.getElementById("suggestForm");
        let mailBody = "<p>Adı Soyadı :"+ document.getElementById("suggestName").value +"</p>";
        mailBody += "<p>Şirket Adı :"+ document.getElementById("suggestCompany").value +"</p>";
        mailBody += "<p>Mail Adresi :"+ document.getElementById("suggestMail").value +"</p>";
        mailBody += "<p>Telefon Numarası :"+ document.getElementById("suggestTel").value +"</p>";

        let mailData = {
            "To" : document.getElementById("suggestMail").value,
            "subject" : "Bayimiz Olun",
            "body" : mailBody
        };
        var data = new FormData();
        data.append( "To", mailData.To);
        data.append("subject", mailData.subject);
        data.append("body", mailData.body);
        if (form.checkValidity() === true) {
            axios({
                method: "post",
                url: "https://www.listefix.com/api/Email/Post",
                data: data
                }).then(function (response) {
                    
                    handleCloseSuggest();
                    setShowAlert(true);
                    
                }).catch(function (error) {
                    console.log(error);
                });
        }
    
        setValidated(true);

        
    }
    useEffect(() => {
        getAllMedia();
    },[]);
    console.log(IMAGES);
    return <div>
        <HeaderStatic />
        <Container>
            <Row>
                <div className="d-flex align-items-center justify-content-between">
                    <h1>{company.companyName}</h1>
                    <Button variant="success" className="btn btn-success" onClick={handleShow}>Bayimiz Olun</Button>                    
                </div>                
                <div className="company-phone d-flex align-items-center mb-2">
                    <p className="m-0 pe-2"><strong>Telefon Numarası :</strong></p>
                    <a href={"tel="+ company.PhoneNumber} className="text-dark">{company.PhoneNumber}</a>
                </div>
                <div className="company-phone d-flex align-items-center mb-2">
                    <p className="m-0 pe-2"><strong>Mail Adresi :</strong></p>
                    <a href={"mailto:   "+ company.Email} className="text-dark">{company.Email}</a>
                </div>                 
                <div className="company-phone d-flex align-items-center mb-2">
                    <p className="m-0 pe-2"><strong>Adres :</strong></p>
                    <p className="m-0">{company.Address}</p>
                </div>
                <div className="company-phone d-flex align-items-center mb-2">
                    <p className="m-0 pe-2"><strong>Vergi Numarası :</strong></p>
                    <p className="m-0">{company.TaxNumber}</p>
                </div>
                {
                    company.Campaing ? 
                    <div className="company-phone d-flex align-items-start mb-4">
                        <p className="m-0 pe-2"><strong>Kampanyalar :</strong></p>
                        <div className="d-flex flex-column">
                            {company.Campaing}
                        </div>
                    </div>
                    :
                    <div></div>
                }

                {/* <div className="company-phone d-flex align-items-center mb-2">
                    <p className="m-0 pe-2"><strong>Ürün kataloğu :</strong></p>
                    <a className="text-dark" href="http://yollabana.com/uploads/_mazakaexcel08-04-2022-09_55_aa9d195e-cfd9-464f-a561-cbc83aaa9f8e.xlsx">
                        Kataloğu İndir
                    </a>
                </div>    */}
                {
                    catalog.length > 0 ? 
                    <p className="m-0 pe-2 mb-2"><strong>Ürün kataloğu :</strong></p>
                    :
                    <div></div>
                }
                {
                    catalog.length > 0 ?                        
                        catalog.map((item,index) => 
                            <div className="company-phone d-flex align-items-center mb-2">
                                <a className="text-dark" href={item} target="_blank">
                                    <img src={logo} width={30} height={30} />
                                    <span className="ms-2">{catalogName[index]}</span>
                                    
                                </a>
                            </div>
                        )

                    :
                    <div></div>
                }
                { IMAGES.length > 0 ?                 
                <div className="col-12 mt-4 mb-4">
                    <p className="col-12 "><strong>Satıcı Galerisi</strong></p>
                    <Gallery images={IMAGES}/>
                </div> 
                :
                <div></div>
                }

                
                <SearchTable loginUser={loginUser} products={filteredProducts} tableActive={true} activateTable={true} filter={false}></SearchTable>

            </Row>
            <Modal  show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Bayimiz Olun</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Bayilik Başvurusu İçin Aşağıdaki Formu Doldurunuz.</p>
                        <Form id="suggestForm" validated={validated}>
                            <FloatingLabel controlId="suggestName" label="Adı Soyadı"  className="mb-2" >
                                <Form.Control required type="text" placeholder="Adı Soyadı" defaultValue={loginUser.FullName}/>
                                <Form.Control.Feedback type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel controlId="suggestCompany" label="Şirket Adı" className="mb-2" >
                                <Form.Control required type="tel" placeholder="Şirket Adı" defaultValue={loginUser.CompanyName}/>
                                <Form.Control.Feedback type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel controlId="suggestMail" label="Mail Adresi" className="mb-2" >
                                <Form.Control required type="email" placeholder="Mail Adresi" defaultValue={loginUser.Email}/>
                                <Form.Control.Feedback type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                            </FloatingLabel> 
                            <FloatingLabel controlId="suggestTel" label="Telefon Numarası" className="mb-2" >
                                <MaskedInput required id="suggestTel" className='form-control' type='tel' name='Phone' mask='1111  111  1111' defaultValue={loginUser.PhoneNumber} />
                                <Form.Control.Feedback type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Group className="mb-3" controlId="suggestKVKK">
                                <Form.Check  required type="checkbox" label="İletişim verilerimin kullanılmasına rıza gösteriyorum." />
                                <Form.Control.Feedback type="invalid">
                                    Bu Alanı İşaretlemeniz Gerekmektedir.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="success" className="float-end" onClick={handleCloseWithMessage}>
                                Gönder
                            </Button>
                        </Form>
                    </Modal.Body>
            </Modal>
            <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
                <Toast.Body>Bilgiler Tarafımıza Ulaşmıştır En Kısa Sürede İletişime Geçeceğiz</Toast.Body>
            </Toast>                                   
        </Container>
        <FooterResult></FooterResult>
    </div>;
}
export  default Detail;