import React,{useState} from "react";
import { Container,Col,Row,FloatingLabel,Form, Button, Toast,Modal } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import ExcelLogo from "../../Content/excel.png";
import FooterResult from "../Shared/FooterResult";
function ExcelResult (props)  {

  const [fileName, setFileName] = useState("Excel File");
  const [alertMessage, setAlertMessage] = useState("Lütfen Bir Dosya Seçiniz");
  const [SuccesAlert, setSuccesAlert] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [excelFiles, setexcelFiles] = useState([]);
  const [show, setShow] = useState(false);
  const loginUser = props.loginUser;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function checkExcel () {
    window.location.href = "/panel/excel";
  }
  return (
    <div>
      <HeaderPanel loginUser={loginUser} />
      <Container fluid={true} className="pt-5 excel-container">
        <Row>
          <Col xs={12} className="excel-header">
            <Toast onClose={() => setSuccesAlert(false)} className="position-fixed report-alert bg-success text-white text-center excel-result-info" show={SuccesAlert} delay={100000} autohide>
              <Toast.Header className="bg-success text-white">
                <small className="ms-auto"></small>
              </Toast.Header>              
              <Toast.Body>Excel Dosyanız Sisteme Gönderilmiştir YÜkleme Tamamlandığında Size Bildireceğiz</Toast.Body>
            </Toast>            
            <h3 className="text-center col-12 mb-5">Excel Dosyanız Sisteme Gönderilmiştir YÜkleme Tamamlandığında Size Bildireceğiz</h3>
              <Col className="excel-result-step col-auto form-control mb-5 mt-5 border-success">
                <p className="d-flex flex-column align-items-center text-center pt-5"><span>Excel Yükleme Sayfasına Geri Dönmek İçin Aşağıdaki Buton ile Devam Edebilirsiniz.</span></p>
                <img  src={ExcelLogo} alt="excel" height={300} />
                <Button variant="success" onClick={checkExcel} className="excel-upload-button btn btn-success mt-4 mb-3 ms-auto me-auto">
                    Excel Yüklemeye Devam Et
                </Button>                
              </Col>                         
          </Col>          
        </Row>
        <Modal  show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Yüklenen Excel Dosyaları</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 d-flex flex-wrap gap-2">
                  {excelFiles.map((file, index) =>
                      <div key={index} className="col-3 d-flex flex-column align-items-center">
                        <img src={ExcelLogo} alt="" height={30}  />
                        <span className="text-dark text-center excel-file-name">{file}</span>
                      </div>
                    )
                  }                  
                    
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Kapat
                </Button>
            </Modal.Footer>
        </Modal>                      
      </Container>
      <FooterResult></FooterResult>
    </div>
  );
}
export default ExcelResult;