import {React,useState,useEffect} from "react";
import { Container, Row,Col } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import CompanyList from "../../components/CompanyList/CompanyList";
import FooterResult from "../Shared/FooterResult";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
function Company (props)  {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cities, setCities] = useState([]);
  const loginUser = props.loginUser;
  // const [loginUser , setLoginUser] = useState({
  //   "UserRoles" : [
  //     {
  //       "Role" : {
  //         "Name" : "Unauth"
  //       }
  //     }
  //   ]
  // });
  // useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: "https://www.listefix.com/api/Authenticate/GetUserInfo",
  //     headers: {
  //       Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //     }
  //   }).then(function (response) {
      
  //     const user = response?.data?.user;
  //     setLoginUser(user);     

  //   }).catch(function (error) { 
  //     setLoginUser({
  //       "UserRoles" : [
  //         {
  //           "Role" : {
  //             "Name" : "Unauth"
  //           }
  //         }
  //       ]
  //     });

  //   });
  // }, []);

  useEffect(() => {
    if(searchParams.toString()){
      axios({
        method: "get",
        url: "https://www.listefix.com/api/User/Filter?" + searchParams.toString(),
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          
          
        setCompany(response.data.Data);
          setLoading(false);
      }).catch(function (error) {
            console.log(error.response);
      });
    }
    else{
      axios({
        method: "get",
        url: "https://www.listefix.com/api/User/GetByRole/Distributor",
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          console.log(response.data);                
          setCompany(response.data.Data);
          setLoading(false);
      }).catch(function (error) {
            console.log(error.response);
      });
    }

    
  },[])

  useEffect(() => {
    axios({
      method: "get",
      url: "https://www.listefix.com/api/Location/GetCities",
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
    }).then(function (response) {
      setCities(response.data);
    }).catch(function (error) {
      console.log(error.response);
    });
  },[]);

  if(loading){
    return (
      <div></div>
    )
  }
  else{
    return (
      <div>
        <HeaderPanel  loginUser={loginUser} />
        <Container>
          <h1>Firmalar</h1>
          <Row>
            <Col className="col-12">
              <CompanyList company={company} cities={cities} loginUser={loginUser}></CompanyList>
            </Col>
          </Row>
        </Container>
        <FooterResult></FooterResult>
      </div>
    );
  } 
}
export default Company;