import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import "./Panel.scss";
import FooterResult from "../Shared/FooterResult";
import banner from "../../Content/find-location.png";

function FindCompany (props)  {
  const loginUser = props.loginUser;
  return (
    <div>
      <HeaderPanel loginUser={loginUser} />
      <Container className="pt-5 find-location-container" fluid={true}>
        <Row className="h-100 justify-content-center">         
          <Col className="col-12 d-flex flex-column justify-content-center find-company">
            <img className="col-12 mb-5" src={banner} alt="Bayi Bul" />
            <h1 className="text-center mt-5">Potansiyel Satıcı Bul Özelliği Çok Yakında...</h1>
          </Col>
        </Row>
      </Container>
      <FooterResult></FooterResult>
    </div>
  );
}
export default FindCompany;