import React ,{useState} from "react";
import { Col,Form,Toast,Button,FloatingLabel } from "react-bootstrap";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";

const animatedComponents = makeAnimated();
function AddBrand (props)  {
  const [companies,setCompanies] = useState([]);  
  // const brands = JSON.parse(localStorage["brands"]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [brands, setBrands] = useState([{value:"",label:""}]);
  

  function handleSaveClick () {
    if(document.getElementById("brandControl").value != ""){
      var saveData = {
        active: true,
        name: document.getElementById("brandControl").value,
      } 
      // if(optionSelected.length >= 1){
      //   var selectedUsers = [];
      //   optionSelected.map(user => {
      //     selectedUsers.push({"userId":user.value})
      //   });
      //   saveData["userBrands"] = selectedUsers;
      // }
      var dataToSend = [saveData];
      addBrand(dataToSend);
    }
    else {
      setShowAlertError(true);
    }

  }
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleChange = (selected) => {
    setOptionSelected(selected)
  };


  const  addBrand = (brandData) => {
    axios({
      method: "post",
      url: "https://www.listefix.com/api/Brand/InsertList",
      data: brandData,
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
    }).then(function (response) {
        setShowAlert(true);
        props.closeModal();
        props.showMessage(true);
        window.location.reload();
    }).catch(function (error) {
          console.log(error.response);
    });
  };
  return (
    <div>
      <Col className="col-12 brand-dist-wrapper">
        <FloatingLabel controlId="brandControl" label="Eklemek İstediğiniz Marka Adını Giriniz" className="mb-4" >
          <Form.Control className="" type="text" placeholder="Eklemek İstediğiniz Marka Adını Giriniz"/>
        </FloatingLabel>
        {/* <Form.Group controlId="companySelect">
          <Form.Label>Satıcı Seçiniz</Form.Label>
          <Select2
          className="mb-4 "
            options={companies}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={handleChange}
            allowSelectAll={true}
            value={optionSelected}
            Placeholder="Satıcı Seçiniz"
          /> 
        
        </Form.Group> */}
        <Button variant="success" onClick={handleSaveClick}>
              Kaydet
        </Button>
      </Col> 

      <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
          <Toast.Body>Marka Başarıyla Eklenmiştir.</Toast.Body>
      </Toast>
      <Toast onClose={() => setShowAlertError(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertError} delay={3000} autohide>
          <Toast.Body>Lütfen Marka Adı Giriniz ve Firma Seçimi Yapınız.</Toast.Body>
      </Toast>
    </div>
  );
}
export default AddBrand;