import React, {useRef,useState,useEffect} from "react";
import { Modal,ModalBody,ModalDialog,ModalDialogProps,ModalHeader,Button,Form,FloatingLabel,Toast} from 'react-bootstrap';
import SwiperSliderProduct from "../SwiperSlider/SwiperSlider-product";
import "./ProductRow.scss";
import noimage from "../../Content/no-image.png";
import axios from "axios";
function ProductRow (props)  {
    const [showAlert, setShowAlert] = useState(false);
    const [show, setShow] = useState(false);
    const[image,setImage] = useState(noimage);
    const[imageSet, setImageSet] = useState([noimage]);
    const [reportMessage, setReportMessage] = useState("Raporunuz tarafımıza incelemek üzere iletilmiştir. En Kısa sürede dönüş yapılacaktır.");
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const index = props.index;
    const handleCloseWithMessage = () => {
        const form = document.getElementById("mailControl").value;
        let mailBody = "<p>Ürün Adı :"+ product.Name +"</p>";
        mailBody += "<p>Ürün Barkodu :"+ product.Barcode +"</p>";
        mailBody += "<p>Şikayet Notu :"+ form +"</p>";

        let mailData = {
            "To" : "brt_ozdt@hotmail.com",
            "subject" : product.Name+"Ürünü Şikayet Raporu",
            "body" : mailBody
        };
        var data = new FormData();
        data.append( "To", mailData.To);
        data.append("subject", mailData.subject);
        data.append("body", mailData.body);
        if (form.length >= 15) {
            axios({
                method: "post",
                url: "https://www.listefix.com/api/Email/Post",
                data: data
                }).then(function (response) {
                    handleClose();
                    setShowAlert(true);
                    
                }).catch(function (error) {
                    console.log(error);
                });
        }
        else {
            setReportMessage("Lütfen en az 15 karakterlik bir mesaj giriniz.")
            setShowAlert(true);

        }
    }
    const product = props.product;
    const selectedAreas = props.selectedAreas;
    let selectedProduct = useRef(product.Id);
    let selectedModal = useRef("modal-" + product.Id);
    function openDetail(Id) {
        document.getElementById(Id).classList.toggle("open");
    }
    const checkImages = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_1.jpeg' ,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            
            setImage("https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_1.jpeg');
            setImageSet(["https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_1.jpeg']);
        }).catch(function (error) {
            setImage(noimage);
            setImageSet([noimage]);
        });
        axios({
            method: "get",
            url: "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_2.jpeg' ,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            
            setImage("https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_2.jpeg');
            setImageSet([...imageSet, "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_2.jpeg']);
        }).catch(function (error) {
            setImage(image);
            setImageSet(imageSet => [...imageSet,noimage]);
        });
        axios({
            method: "get",
            url: "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_3.jpeg' ,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            
            setImage("https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_3.jpeg');
            setImageSet([...imageSet, "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_3.jpeg']);
        }).catch(function (error) {
            setImage(image);
            setImageSet(imageSet => [...imageSet,noimage]);
        });        
    }
    useEffect(() => {
        checkImages();
      }, []); 
    const priceFormatter = new Intl.NumberFormat();
    return[
            <tr key={selectedProduct}  >
              <td onClick={()=>openDetail(product.Id)}>{index}</td>
              <td onClick={()=>openDetail(product.Id)}><img className="uk-preserve-width uk-border-circle" src={imageSet[0]} width={30} height={30} alt="avatar" /></td>
              <td className={selectedAreas[3].active?"d-table-cell":"d-none"} onClick={()=>openDetail(product.Id)}>{product.Barcode}</td>
              <td className="brand-col" onClick={()=>openDetail(product.Id)}>{product.BrandName}</td>
              <td onClick={()=>openDetail(product.Id)}><strong>{product.Name}</strong></td>
              <td onClick={()=>openDetail(product.Id)}>{product.DistributorName}</td>
              
              <td onClick={()=>openDetail(product.Id)}>
                  <div className="d-flex flex-column">
                  {/* <span className="text-secondary">{product.Price} {(product.CurrencyCode ? product.CurrencyCode : "TRY")} </span> */}
                  {/* <strong>{parseFloat(product.Price) * parseFloat(product.Currency)} TL</strong> */}
                  <strong>{priceFormatter.format(product.Price)} {(product.CurrencyCode ? product.CurrencyCode : "TRY")} </strong>
                  </div>
              </td>
              <td className={selectedAreas[0].active?"d-table-cell":"d-none"} onClick={()=>openDetail(product.Id)}>{priceFormatter.format(product.SuggestionPrice)} {(product.CurrencyCode ? product.CurrencyCode : "TRY")} </td>
              <td className= {(selectedAreas[1].active?"d-table-cell":"d-none") + " text-success fw-bold"} onClick={()=>openDetail(product.Id)}><span className={product.UserSpecificPrice != 0 ? "" : " text-danger "} >{product.UserSpecificPrice != 0 ? priceFormatter.format(product.UserSpecificPrice) + (product.CurrencyCode ? product.CurrencyCode : "TRY") : "-"}</span></td>
              <td className="text-secondary" onClick={()=>openDetail(product.Id)}>{product.Currency} {(product.CurrencyCode ? product.CurrencyCode : "TRY")} </td>
              <td className={(selectedAreas[2].active?"d-table-cell":"d-none")+ " text-success"} onClick={()=>openDetail(product.Id)}>{product.Campaign}</td>
              {/* <td className={(selectedAreas[4].active?"d-table-cell":"d-none")+ " text-sec"}><button className="btn btn-link text-sec fw-lighter text-decoration-none search-list-report-btn" onClick={handleShow}>Rapor Et</button></td> */}
              <td className="expand-button" onClick={()=>openDetail(product.Id)}><svg width="10" height="5" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.99985 7.50003C6.73724 7.50049 6.47713 7.44904 6.23446 7.34865C5.9918 7.24825 5.77137 7.10089 5.58585 6.91503L0.292847 1.62103L1.70685 0.207031L6.99985 5.50003L12.2928 0.207031L13.7068 1.62103L8.41385 6.91403C8.2284 7.10007 8.00801 7.24762 7.76535 7.34819C7.52268 7.44875 7.26253 7.50036 6.99985 7.50003Z" fill="#374957"/>
                 </svg>
             </td>
             
            </tr>,
            <tr useRef={product.Id} className={'expandable' + (selectedProduct === product.Id ? ' open': ' ' )}  key="tr-expander" id={product.Id}>
                <td className="product-detail-col" colSpan={12}>
                    <div  className="d-flex">
                        <div className="uk-width-1-4 uk-text-center col-2">
                            <SwiperSliderProduct images={imageSet}/>
                        </div>
                        <div className="uk-width-3-4 ps-5 pe-5 d-flex flex-wrap col-10">
                            <div className="col-12 col-lg-9 d-flex flex-column pe-4">
                                <h5>{product.Name}</h5>
                                <p className="text-main  d-flex flex-column">
                                    <strong className="col-12 mb-1 fs-6">Distrubutor: <strong className="text-dark">{product.DistributorName}</strong></strong>
                                    <a className="text-main" href={"/company-detail/" + (product.DistributorId) } target="_blank">Satıcı Detayını Görüntüle</a>
                                </p>
                                <p className={(product.QuantityBox !== undefined && product.QuantityBox !== "" && product.QuantityBox !== null)?'d-block':'d-none'} ><strong>Koli Adedi:</strong> {product.QuantityBox}</p>
                                <p><strong>Birim(Adet):</strong> {product.QuantityType}</p>
                                
                                
                                <p className="col-12"><strong>Açıklama:</strong><br/><span>{product.Description}</span></p>
                                
                            </div>
                            <div className="col-12 col-lg-3 d-flex flex-column">
                                <p className="d-flex flex-column">
                                    <strong className="h5">{priceFormatter.format(product.Price)} {(product.CurrencyCode ? product.CurrencyCode : "TRY")}</strong>
                                    <strong className="h4 text-success">{priceFormatter.format(product.Price)} {(product.CurrencyCode ? product.CurrencyCode : "TRY")}</strong>
                                </p>
                                <p><strong>Önerilen Satış Fiyatı:</strong> {priceFormatter.format(product.SuggestionPrice)} {(product.CurrencyCode ? product.CurrencyCode : "TRY")} </p>
                                <p className={product.UserSpecificPrice != 0 ? "" : " text-danger "}><strong className="text-dark">Size Özel Satış Fiyatı:</strong> {product.UserSpecificPrice != 0 ? priceFormatter.format(product.UserSpecificPrice) + (product.CurrencyCode ? product.CurrencyCode : "TRY") : "-"}</p>
                                <a className="btn btn-warning text-dark fs-6" href={"/company-detail/" + product.DistributorId} target="_blank">{product.Campaign}</a>
                                <p>{product.PaymentType1}</p>
                                <p>{product.PaymentType2}</p>
                                <p>{product.PaymentType3}</p>
                                <p>{product.PaymentType4}</p>
                                <button className="btn btn-link text-sec fw-lighter text-decoration-none search-list-report-btn" onClick={handleShow}>Rapor Et</button>
                            </div>
                            
                        </div>
                    </div>
                </td>
            </tr>,
                <Modal useRef={selectedModal} show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Ürünü Rapor Et</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>{product.Name}</strong> ürününü rapor sebebinizi yazınız.</p>
                        <FloatingLabel controlId="mailControl" label="Lütfen rapor sebebinizi belirtiniz." className="search-box__input-box" >
                            <Form.Control rows={3} as="textarea" className="report-input" placeholder="Lütfen rapor sebebinizi belirtiniz."/>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleCloseWithMessage}>
                            Rapor Et
                        </Button>
                    </Modal.Footer>
                </Modal>,
                <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlert} delay={3000} autohide>
                    <Toast.Body>{reportMessage}</Toast.Body>
                </Toast>

        ]
}

export default ProductRow;