import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from "swiper/core";
import "./SwiperSlide.scss";
import 'swiper/css';
import mainBanner from'../../Content/ListFixBanner1.png';
import logo from '../../Content/logo.png';
SwiperCore.use([Pagination]);
function SwiperSlider () {
    return (
        <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={{
            clickable: true
          }}
        centeredSlides={true}
        className='swiper-slider'>
        <SwiperSlide className='swiper-slider__slide'>
            <img src={mainBanner} alt="" className='swiper-slider__slide__image'/>
            <div className='swiper-slider__slide__content-box position-absolute h-100 p-5 top-0 d-flex flex-wrap'>
                <div className='swiper-slider__slide__content-box__image-box col-12'>
                    <a href="/">
                        <img src={logo} alt="" />
                    </a>
                </div>
                <div className='swiper-slider__slide__content-box__desc-box text-white col-12 '>
                        <p className='swiper-slider__slide__content-box__desc-box__title col-12 fw-bold h3 pb-4'>Hemen katılın</p>
                    <p className='swiper-slider__slide__content-box__desc-box__text col-12'>
                            ListeFix ile fiyatlarınızı kolayca yayımlayın
                            Bütün bayilerinizle fiyatlarınızı paylaşıp potansiyel müşterileri keşfetmek ister misiniz? Türkiye’nin dört bir yanından binlerce toptancı ve perakendeci ürünlerinizi görüntüleyebilecek.
                    </p>
                </div>
                
            </div>
        </SwiperSlide>
        <SwiperSlide className='swiper-slider__slide'>
            <img src={mainBanner} alt="" className='swiper-slider__slide__image'/>
            <div className='swiper-slider__slide__content-box position-absolute h-100 p-5 top-0 d-flex flex-wrap'>
                <div className='swiper-slider__slide__content-box__image-box col-12'>
                <a href="/">
                    <img src={logo} alt="" />
                </a>
                </div>
                <div className='swiper-slider__slide__content-box__desc-box text-white col-12 '>
                        <p className='swiper-slider__slide__content-box__desc-box__title col-12 fw-bold h3 pb-4'>Marka sahibi misiniz?</p>
                    <p className='swiper-slider__slide__content-box__desc-box__text col-12'>
                            
                            Hemen üye olup siz de ürünlerinizi ListeFix ile paylaşmaya başlayın, bayi ağınızı kolayca yönetin ve yeni bağlantılar kurun. Güncellenen listelerinizi bayilerinize duyurmakla vakit kaybetmeyin, bunu sizin için biz yapalım.
                    </p>
                </div>
                
            </div>
        </SwiperSlide>
        <SwiperSlide className='swiper-slider__slide'>
            <img src={mainBanner} alt="" className='swiper-slider__slide__image'/>
            <div className='swiper-slider__slide__content-box position-absolute h-100 p-5 top-0 d-flex flex-wrap'>
                <div className='swiper-slider__slide__content-box__image-box col-12'>
                    <a href="/">
                        <img src={logo} alt="" />
                    </a>
                </div>
                <div className='swiper-slider__slide__content-box__desc-box text-white col-12 '>
                        <p className='swiper-slider__slide__content-box__desc-box__title col-12 fw-bold h3 pb-4'> Bayi misiniz?</p>
                    <p className='swiper-slider__slide__content-box__desc-box__text col-12'>
                           
                            ListeFix’e kayıt olarak ürününü sattığınız tedarikçilerle eşleşin ve tüm markalarınızın listelerini tek bir platformdan  takip etmenin kolaylığını keşfedin. Aradığınız ürünlerle ilgili alternatifleri keşfetmek için ürün detaylarını kontrol etmeyi unutmayın.
                    </p>
                </div>
                
            </div>
        </SwiperSlide>
      </Swiper>
    )
}
export default SwiperSlider;