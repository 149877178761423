import React, {useRef,useState} from "react";
import "./CompanyRow.scss";
import { Dropdown} from 'react-bootstrap';

function SelectCompany (props)  {
    const company = props.company;
    const dashboard = props.dashboard;
    return(
            <option value={company.Id}>{company.CompanyName}</option>
        )
}

export default SelectCompany;