import React,{useState,useEffect} from "react";
import { Container,Col,Row,FloatingLabel,Form, Button, Toast,Modal } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import ExcelLogo from "../../Content/excel.png";
import FooterResult from "../Shared/FooterResult";
import axios from "axios";
import exampleExcel from '../../Content/listfix.xlsx';
import Select2 from "../../components/SelectDropdown/Select2";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
function Settings (props)  {
  const loginUser = props.loginUser;
  const [loading, setLoading] = useState(true);
  const [allBrands, setAllBrands] = useState([]);
  const [allSettings, setAllSettings] = useState([]);
  const [mailSettings, setMailSettings] = useState([]);
  const [companySettings, setCompanySettings] = useState([]);
  const animatedComponents = makeAnimated();
  const [optionSelected, setOptionSelected] = useState([]);
  const [currency, setCurrency] = useState(false);

  useEffect(()=>{
    axios({
      method: "get",
      url: "https://www.listefix.com/api/GeneralSetting",
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
    }).then(function (response) {

      setAllSettings(response.data.Data);
      setMailSettings(response.data.Data.filter(t=> t.Key.includes("Mail")));
      setCompanySettings(response.data.Data.filter(t=>t.Key.includes("Company")));
      setCurrency(response.data.Data.find(t=>t.Key.includes("Currency").Value));

    }).catch(function (error) {
          console.log(error.response);
    });

    // axios({
    //   method: "get",
    //   url: "https://www.listefix.com/api/Product/GetBrands",
    //   headers: {
    //     Authorization: "Bearer "+localStorage.getItem("listfixToken")
    //   }
    // }).then(function (response) {
    //       var tempData = [];
    //     for(var i=0;i<response.data.Data.length;i++){
    //       tempData.push({value:response.data.Data[i].Id,label:response.data.Data[i].Name})
    //     }
    //     setAllBrands(tempData);


    //   }).catch(function (error) {
    //     console.log(error.response);
    //   });
  },[]);
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleChangeMailSettings = (event) =>{
    var tempArray = mailSettings;
    var tempObj = tempArray.find(t => t.Key === event.target.name);
    tempObj.Value = event.target.value;
    setMailSettings(tempArray);

  }

  const handleChangeCompanySettings = (event) =>{
    var tempArray = companySettings;
    var tempObj = tempArray.find(t => t.Key === event.target.name);
    tempObj.Value = event.target.value;
    setCompanySettings(tempArray);

  }

  const updateEmailSettings = () =>{
    
    axios({
      method: "put",
      url: "https://www.listefix.com/api/GeneralSetting/PutMany",
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      },
      data:mailSettings
    }).then(function (response) {
      
      
    }).catch(function (error) {

      console.log(error.response);  

    });
  };

  const updateCompanySettings = () =>{
    
    axios({
      method: "put",
      url: "https://www.listefix.com/api/GeneralSetting/PutMany",
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      },
      data:companySettings
    }).then(function (response) {
      
      
    }).catch(function (error) {

      console.log(error.response);  

    });
  };
    



    
  const MultiValue = props => (
  <components.MultiValue {...props}>
      <span>{props.data.label}</span>
  </components.MultiValue>
  );
  const handleChange = (selected) => {
      setOptionSelected(selected);
  };
  const handleValueChange = () => {
    setCurrency(!currency);
}

const getSettingsName = (key) => {
  switch (key) {
    case "MailHost":
      return "Mail Server";
    case "MailName":
      return "Mail Adresi";
    case "MailUser":
      return "Mail Kullanıcı Adı";
    case "MailPassword":
       return "Mail Şifresi";
    case "MailBcc":
       return "Mail Bcc";
    case "CompanyName":
      return "Şirket İsmi";
    case "CompanyAddress":
      return "Şirket Adres";
    case "CompanyPhoneNumber":
      return "Şirket Telefon";
    case "CompanyOfficalName":
      return "Şirket Yetkili İsmi";

    default:
      return key;
  }
  
}
  return (
    <div>
      <HeaderPanel loginUser={loginUser} />
      <Container fluid={true} className="pt-5 excel-container">
        <Row>
          <Col xs={12} className="excel-header">
            <h3 className="text-center col-12 mb-5">Ayarlarınızı Aşağıdan Düzenleyiniz</h3>
              <Col className="excel-step col-auto form-control mb-5 mt-5 border-success">
                <p>Mail Ayarları</p>
                {mailSettings.map(item => (
                  <FloatingLabel
                  
                    key={item.Key}
                    controlId="floatingSelect"
                    label={getSettingsName(item.Key.split(":")[1])}
                    className="mb-3 col-12"
                  >
                      <Form.Control className="product-search-input" type="text" name={item.Key} onChange={handleChangeMailSettings} defaultValue={item.Value} />
                  </FloatingLabel>
                ))}
                
                <div className="col-12 d-flex justify-content-end">
                <Button className="ms-auto" variant="success" onClick={updateEmailSettings} >
                  Kaydet
                </Button>
                </div> 
              </Col>
              <Col className="excel-step col-auto form-control mb-5 mt-5 border-success">
                <p>Profil Ayarları</p>
                {companySettings.map(item => (
                  <FloatingLabel
                  
                    key={item.Key}
                    controlId="floatingSelect"
                    label={getSettingsName(item.Key.split(":")[1])}
                    className="mb-3 col-12"
                  >
                      <Form.Control className="product-search-input" type="text" name={item.Key} onChange={handleChangeCompanySettings} defaultValue={item.Value} />
                  </FloatingLabel>
                ))}
                <div className="col-12 d-flex justify-content-end">
                <Button className="ms-auto" variant="success" onClick={updateCompanySettings}>
                  Kaydet
                </Button>
                </div> 
              </Col>
              {/* <Col className="excel-step col-auto form-control mb-5 mt-5 border-success">
                <p>Kur Ayarları</p>
                <Form.Check 
                        className="mb-4"
                        onChangeCapture={(e)=>handleValueChange(e)}
                        defaultChecked={currency}
                        label={"Kuru Sabitle"}
                        type="checkbox"
                        id={"checkboxCur"}
                  />
                <Select2
                    className={"mb-4 " + (currency ? " d-block" : " d-none")}
                    options={allBrands}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleChange}
                    allowSelectAll={true}
                    value={optionSelected}
                    defaultValue = {optionSelected}
                    Placeholder="Markalar"
                />
                <Form.Select name="PriceUnit" className={"mb-4 " + (currency ? " d-block" : " d-none")}>
                    <option disabled selected>Para Birimi</option>
                    <option value="USD">USD</option>
                    <option value="TRY">TRY</option>
                    <option value="EUR">EUR</option>
                </Form.Select>
                <FloatingLabel controlId="productSearch" label="Kur Değeri" className={"product-name-input-box mb-4 " + (currency ? " d-block" : " d-none")} >
                  <Form.Control className="product-search-input" type="number" name="searchKey" />
                </FloatingLabel>  
                <div className="col-12 d-flex justify-content-end">
                <Button variant="success" className={" " + (currency ? " d-block" : " d-none")}>
                  Kaydet
                </Button>
                </div> 
              </Col>                           */}
          </Col>          
        </Row>                     
      </Container>
      <FooterResult></FooterResult>
    </div>
  );
}
export default Settings;