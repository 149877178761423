import React ,{useState} from "react";
import { Container ,Row} from "react-bootstrap";
import FooterResult from "../Shared/FooterResult";
import HeaderAccount from "../Shared/HeaderAccount";
import { Modal,ModalBody,ModalDialog,ModalDialogProps,ModalHeader,Button,Form,FloatingLabel,Toast, Select} from 'react-bootstrap';
import { useEffect } from "react";
import axios from "axios";
import "./Account.scss";
import MaskedInput from 'react-maskedinput';
import MediaContent from "./MediaContent";


function Account(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);    
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertUpload, setShowAlertUpload] = useState(false);
    const handleCloseSuggest = () => setshowSuggest(false);
    const [showSuggest, setshowSuggest] = useState(false);
    const [showAlertFalse, setShowAlertFalse] = useState(false);
    const [userDistricts,setUserDistricts] = useState([]);
    const [cities, setCities] = useState([]);
    const [excelFiles, setexcelFiles] = useState([]);
    const [excelFile, setExcelFile] = useState();
    const [fileName, setFileName] = useState("");
    const [productFile, setProductFile] = useState();
    const [productFileName, setProductFileName] = useState("");    
    const [showAlertFile, setShotAlertFile] = useState(false);
    const [addedMedia, setAddedMedia] = useState(["test"]);
    const [addedProductMedia, setProductAddedMedia] = useState(["test"]);
    const [addedMediaNames, setAddedMediaNames] = useState(["test"]);
    const [addedProductMediaNames, setAddedProductMediaNames] = useState(["test"]);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Lütfen Bir Dosya Seçiniz");
    const [SuccesAlert, setSuccesAlert] = useState(false);
    const user = props.loginUser;
    const handleValueChange = (e) => {
        if(e.target.name == "Active") {
            user[e.target.name] = e.target.checked;
        }
        else {
            user[e.target.name] = e.target.value;
        }
    }
    const handleFileNameChange = (e) => {
        setFileName(e.target.value);
    }
    const handleProductFileNameChange = (e) => {
        setProductFileName(e.target.value);
    }    
      
    const handleCloseWithMessage = () => {
        axios({
            method: "patch",
            url: "https://www.listefix.com/api/User",
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            },
            data: {
                Id: user.Id,
                patch: [
                    {
                        path: "/Email",
                        value: user.Email,
                    },
                    {
                        path: "/FullName",
                        value: user.FullName,
                    },
                    {
                        path: "/PhoneNumber",
                        value: user.PhoneNumber,
                    },
                    {
                        path: "/UserName",
                        value: user.UserName,
                    },
                    {
                        path: "/Address",
                        value: user.Address,
                    },
                    {
                        path: "/TaxNumber",
                        value: user.TaxNumber,
                    },
                    {
                        path: "/CompanyName",
                        value: user.CompanyName,
                    },
                    {
                        path: "/CompanyOfficalName",
                        value: user.CompanyOfficalName,
                    },
                    {
                        path: "/CountyId",
                        value: Number(document.getElementById("userDistrict").value),
                    },
                ],
            }
            }).then(function (response) {
                handleClose();
                setShowAlert(true);
            }
            ).catch(function (error) {
                console.log(error.response);
                setShowAlertFalse(true);
            }
        );
    }
    const handleCityValueChange = (e) => {
        getDistricts(e.target.value);
    }
    const getAllMedia = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Media/GetAll" ,
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            // console.log(response.data.Data[i].substring(response.data.Data[i].indexOf("uploads"),response.data.Data[i].length));
            var tempData = [];
            for(var i=0 ; i<response.data.Data.length; ++i) {
                tempData.push("https://www.listefix.com\\" + response.data.Data[i].substring(response.data.Data[i].indexOf("uploads"),response.data.Data[i].length))
            }
            setAddedMedia(tempData);
            axios({
                method: "get",
                url: "https://www.listefix.com/api/Media/GetAllNames" ,
                headers: {
                    Authorization: "Bearer "+localStorage.getItem("listfixToken")
                }
            }).then(function (response) {
                setAddedMediaNames(response.data.Data);
                setLoading(true);
            }).catch(function (error) {
                console.log(error.response);
            });
        }).catch(function (error) {
            console.log(error.response);
        });
    }
    const getAllProdutMedia = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Media/GetAllProduct" ,
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            // console.log(response.data.Data[i].substring(response.data.Data[i].indexOf("uploads"),response.data.Data[i].length));
            var tempData = [];
            for(var i=0 ; i<response.data.Data.length; ++i) {
                tempData.push("https://www.listefix.com\\" + response.data.Data[i].substring(response.data.Data[i].indexOf("uploads"),response.data.Data[i].length))
            }
            setProductAddedMedia(tempData);
            axios({
                method: "get",
                url: "https://www.listefix.com/api/Media/GetAllNamesProduct" ,
                headers: {
                    Authorization: "Bearer "+localStorage.getItem("listfixToken")
                }
            }).then(function (response) {
                setAddedProductMediaNames(response.data.Data);
                setLoading(true);
            }).catch(function (error) {
                console.log(error.response);
            });
        }).catch(function (error) {
            console.log(error.response);
        });
    }    
    useEffect(() => {
        getAllMedia();
        getAllProdutMedia();
    },[]);
    useEffect(() => {
        axios({
          method: "get",
          url: "https://www.listefix.com/api/Location/GetCities",
          headers: {
            Authorization: "Bearer "+localStorage.getItem("listfixToken")
          }
        }).then(function (response) {
          setCities(response.data);
        }).catch(function (error) {
          console.log(error.response);
        });
      },[]);
    const getDistricts = (cityId) => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Location/GetCounties?cityId=" + cityId,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
              setUserDistricts(response.data);
          }).catch(function (error) {
                console.log(error.response);
          })
    }
    const createDistricts = () => {
        if(userDistricts.length <= 0) {
            axios({
                method: "get",
                url: "https://www.listefix.com/api/Location/GetCounties?cityId=" + user.County?.City?.Id,
                headers: {
                  Authorization: "Bearer "+localStorage.getItem("listfixToken")
                }
              }).then(function (response) {
                  setUserDistricts(response.data);
              }).catch(function (error) {
                    console.log(error.response);
              })
        }
        return(
            userDistricts.map(dist => 
                <option value={dist.Id} selected={ dist.Id == user.County?.Id ? 'selected' : '' }>{dist.Name}</option>
            )
        )
    }

    
    const uploadMedia = () => {
        if(!excelFile.name.toLowerCase().includes(".")){
            setShowAlertUpload(true);
          }
          else if(!(excelFile.name.toLowerCase().includes(".jpg") || excelFile.name.toLowerCase().includes(".jpeg") || excelFile.name.toLowerCase().includes(".png") || excelFile.name.toLowerCase().includes(".pdf") || excelFile.name.toLowerCase().includes(".mp4") )){
            setAlertMessage("Dosyanız pdf, resim veya mp4 formatında olmalıdır.");
            setShowAlertUpload(true);
          }
          else{
            var data = new FormData();
            data.append('file', excelFile, excelFile.name);
            data.append('FileName', document.getElementById("userMediaName").value)
            axios({
              method: "post",
              url: "https://www.listefix.com/api/Media/Insert",
              data: data,
              headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
              }
            }).then(function (response) {
                
                setSuccesAlert(true);
                getAllMedia();
              }).catch(function (error) {
                console.log(error);
              });
      
            
          }
    }

    const uploadProductMedia = () => {
        if(!productFile.name.toLowerCase().includes(".")){
            setShowAlertUpload(true);
          }
          else if(!(productFile.name.toLowerCase().includes(".jpeg") )){
            setAlertMessage("Dosyanız .jpeg resim formatında olmalıdır.");
            setShowAlertUpload(true);
          }
          else{
            var data = new FormData();
            data.append('file', productFile, productFile.name);
            data.append('FileName', document.getElementById("userMediaProductName").value)
            data.append('IsProduct',true);
            axios({
              method: "post",
              url: "https://www.listefix.com/api/Media/Insert",
              data: data,
              headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
              }
            }).then(function (response) {
                setSuccesAlert(true);
                getAllProdutMedia();
                
              }).catch(function (error) {
                console.log(error);
              });
      
            
          }
    }    

    const handleMediaChange = (event) => {
        if(event.target.files[0].size < 15728640) {
            setExcelFile(event.target.files[0]);
        }
        else {
            setShotAlertFile(true)
        }
    }

    const handleProductMediaChange = (event) => {   
        if(event.target.files[0].size < 15728640) {
            setProductFile(event.target.files[0]);
        }
        else {
            setShotAlertFile(true)
        }
    }    
    

    return <div>
        <HeaderAccount />
        <Container>
            <Row className="justify-content-center">                
            <div className="account-form">
                    <p><strong>{user.name}</strong> Üye bilgilerini değiştir.</p>
                    <FloatingLabel controlId="UserName" label="Kullanıcı Adı" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="UserName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.UserName} />
                    </FloatingLabel>
                    <FloatingLabel controlId="fullName" label="Adı Soyadı" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="FullName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.FullName} />
                    </FloatingLabel>
                    <FloatingLabel controlId="companyName" label="Şirket Adı" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="CompanyName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.CompanyName} />
                    </FloatingLabel>
                    <FloatingLabel controlId="companyOfficalName" label="Şirket Yetkilisi Adı" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="CompanyOfficalName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.CompanyOfficalName} />
                    </FloatingLabel>
                    <Form.Select name="County" id="userCity" className="user-name-input-box mb-3" onChange={(e)=>handleCityValueChange(e)} required>
                        {   
                            cities.map(city => 
                                <option value={city.Id} selected={city.Id == user.County?.City.Id }>{city.Name}</option>
                            )
                        }
                    </Form.Select>
                    <Form.Select name="CountyDist" id="userDistrict" className="user-name-input-box mb-3" required>
                        { createDistricts() }
                    </Form.Select>                   
                    <FloatingLabel controlId="userAddress" label="Kullanıcı Adresi" className="search-box__input-box mb-3" >
                            <Form.Control rows={3} as="textarea" className="report-input" name="Address" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.Address}/>
                    </FloatingLabel> 
                    <FloatingLabel controlId="usertaxNumber" label="Vergi Numarası" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="TaxNumber" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.TaxNumber} />
                    </FloatingLabel> 
                    <FloatingLabel controlId="userPhone" label="Telefon Numarası" className="user-name-input-box mb-3" >
                        {/* <Form.Control className="user-name-input" type="text" name="PhoneNumber" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.PhoneNumber} /> */}
                        <MaskedInput id="userPhone" className='form-control' type='text' name='PhoneNumber' value={user.PhoneNumber} onChange={(e) => handleValueChange(e)} mask='1111  111  1111' />                     
                    </FloatingLabel>  
                    <FloatingLabel controlId="userMail" label="Mail Adresi" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="email" name="Email" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.Email} />
                    </FloatingLabel>
                    <Button className="float-end" variant="success" onClick={handleCloseWithMessage}>
                        Kaydet
                    </Button>
                    {
                        user.UserRoles[0].Role.Name === "Dealer" ? <div></div> :                     
                    <div className="account-file-wrapper col-12 d-flex flex-wrap border-top pt-2">
                        <label className="col-12 fw-bold mb-2">Medya Yönetimi</label>
                        <FloatingLabel controlId="userMediaName" label="Medya Adı" className="company-name-input-box mb-3 col-12" >
                            <Form.Control className="company-name-input" type="text" name="Media" defaultValue={fileName} onChangeCapture={(e)=>handleFileNameChange(e)} />
                        </FloatingLabel>                        
                        <Form.Group controlId="formFileLg" className="account-file-upload d-flex justify-content-between mb-3 col-12">
                            <Form.Control type="file"
                                onChange={(e) => handleMediaChange(e)} 
                                placeholder={fileName} 
                                label={fileName} size="md" />   
                            <Button className="float-end" variant="success" onClick={uploadMedia}>
                                Medya Ekle
                            </Button>
                        </Form.Group>
                        <div className="col-12">

                            <MediaContent getAllMedia={getAllMedia} mediaPaths={addedMedia} mediaNames={addedMediaNames} setAddedMedia={setAddedMedia} setAddedMediaNames={setAddedMediaNames}></MediaContent>
                            
                        </div>
                        <div className="col-12 d-flex flex-wrap border-top pt-2 mt-5">
                            <label className="col-12 fw-bold mb-2">Ürün Medyası Yönetimi</label>
                            <p className="col-12 mb-2 text-danger">Ürün Görsellerini Yüklemek için "barkodkodu_1","barkodkodu_2","barkodkodu_3" isimlendirme kuralına göre yükleyiniz.</p>
                            <FloatingLabel controlId="userMediaProductName" label="Medya Adı" className="company-name-input-box mb-3 col-12" >
                                <Form.Control className="company-name-input" type="text" name="Media" defaultValue={productFileName} onChangeCapture={(e)=>handleProductFileNameChange(e)} />
                            </FloatingLabel>                        
                            <Form.Group controlId="formFileLg" className="account-file-upload d-flex justify-content-between mb-3 col-12">
                                <Form.Control type="file"
                                    onChange={(e) => handleProductMediaChange(e)} 
                                    placeholder={productFileName} 
                                    label={productFileName} size="md" />   
                                <Button className="float-end" variant="success" onClick={uploadProductMedia}>
                                    Ürün Resmi Ekle
                                </Button>
                            </Form.Group>
                            <div className="col-12">

                                <MediaContent getAllMedia={getAllProdutMedia} mediaPaths={addedProductMedia} mediaNames={addedProductMediaNames} setAddedMedia={setProductAddedMedia} setAddedMediaNames={setAddedProductMediaNames}></MediaContent>
                                
                            </div>                
                        </div>
                    </div>
                    }


                </div>                
            </Row> 
            <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
                <Toast.Body>Bilgiler Kayıt Edilmiştir.</Toast.Body>
            </Toast>
            <Toast onClose={() => setShowAlertFalse(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertFalse} delay={3000} autohide>
                <Toast.Body>Bilgiler Kayıt Edilemedi.</Toast.Body>
            </Toast>
            <Toast onClose={() => setShotAlertFile(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertFile} delay={3000} autohide>
                <Toast.Body>Lütfen 15MB'dan daha düşük bir dosya yükleyiniz.</Toast.Body>
            </Toast>
            <Toast onClose={() => setShowAlertUpload(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertUpload} delay={3000} autohide>
                <Toast.Body>{alertMessage}</Toast.Body>
            </Toast>
            <Toast onClose={() => setSuccesAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={SuccesAlert} delay={3000} autohide>
                <Toast.Body>Medya Yüklemesi Başarılı</Toast.Body>
            </Toast>
        </Container>
        <FooterResult></FooterResult>
    </div>;
}
export  default Account;