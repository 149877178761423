import React, {useRef,useState,useEffect} from "react";
import { Modal,ModalBody,ModalDialog,ModalDialogProps,ModalHeader,Button,Form,FloatingLabel,Toast, Select} from 'react-bootstrap';
import UsersDistrubutor from "../../pages/Panel/UsersDistrubutor";
import axios from "axios";
import SelectRole from "./SelectRole";
import UserRowDetail from "./UserRowDetail";
import MaskedInput from 'react-maskedinput';
function UserRow (props)  {
    const user = props.user;
    const cities = props.cities;
    const loginUser = props.loginUser;
    const [userDetails,setUserDetails] = useState([]);
    const [userDistricts,setUserDistricts] = useState([]);
    const dashboard = props.dashboard;
    const [showDeal, setShowDeal] = useState(false);
    const handleCloseDeal = () => {setShowDeal(false)};
    const handleShowDeal = () => setShowDeal(true);
    const [SuccesAlertUserDeal, setSuccesAlertUserDeal] = useState(false);
    const [DealMessage, setDealMessage] = useState("Bayi Distributor Ataması Silinmiştir.");
    const [show, setShow] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertFalse, setShowAlertFalse] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClosePrice = () => setShowPrice(false);
    const handleShowPrice = () => {
        setShowPrice(true);
        axios({
            method: "get",
            url: "https://www.listefix.com/api/User/"+user.Id,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
              setUserDetails(response.data);
          }).catch(function (error) {
            console.log(error.response);
        })
    };
    let selectedModal = useRef("modal-" + user.Id);
    const handleCloseWithMessage = () => {
        axios({
            method: "patch",
            url: "https://www.listefix.com/api/User",
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            },
            data: {
                Id: user.Id,
                patch: [
                    {
                        path: "/Active",
                        value: user.Active,
                    },
                    {
                        path: "/Email",
                        value: user.Email,
                    },
                    {
                        path: "/FullName",
                        value: user.FullName,
                    },
                    {
                        path: "/PhoneNumber",
                        value: user.PhoneNumber,
                    },
                    {
                        path: "/UserName",
                        value: user.UserName,
                    },
                    {
                        path: "/Address",
                        value: user.Address,
                    },
                    {
                        path: "/TaxNumber",
                        value: user.TaxNumber,
                    },
                    {
                        path: "/CompanyName",
                        value: user.CompanyName,
                    },
                    {
                        path: "/CompanyOfficalName",
                        value: user.CompanyOfficalName,
                    },
                    {
                        path: "/CountyId",
                        value: Number(document.getElementById("userDistrict").value),
                    },
                ],
            }
            }).then(function (response) {
                handleClose();
                setShowAlert(true);
            }
            ).catch(function (error) {
                console.log(error.response);
                setShowAlertFalse(true);
            }
        );
        axios({
            method: "put",
            url: "https://www.listefix.com/api/User/UpdateUserRole/"+user.Id,
            data: [Number(document.getElementById("roleId").value)],
          }).then(function (response) {
            }).catch(function (error) {
                console.log(error.response);
            });
    }
    const roles =[
        {id:3,name:"Distrübütör"},
        {id:4,name:"Bayi"}
    ];

    const handleValueChange = (e) => {
        if(e.target.name == "Active") {
            user[e.target.name] = e.target.checked;
        }
        else if(e.target.name == "UserRoles") {
            user[e.target.name][0].RoleId = Number(e.target.value);
            user[e.target.name][0].Role.Id = Number(e.target.value);
            if(e.target.value == "4") {
                user[e.target.name][0].Role.Name = "Dealer";
                user[e.target.name][0].Role.NormalizedName = "DEALER";
            }
            if(e.target.value == "3") {
                user[e.target.name][0].Role.Name = "Distributor";
                user[e.target.name][0].Role.NormalizedName = "DISTRIBUTOR";
            }
        }
        else if(e.target.name == "PhoneNumber") {
            user[e.target.name] = e.target.value.replace(/\D/g, "");
        }
        else{
            user[e.target.name] = e.target.value;
        }
    }
    const handleCityValueChange = (e) => {
        getDistricts(e.target.value);
    }
    let selecteduser = useRef(user.Id);

    function openDetail(UserName) {
        document.getElementById(selecteduser.current).classList.toggle("open");
        
        if(userDetails.length <= 0){
            axios({
                method: "get",
                url: "https://www.listefix.com/api/User/"+user.Id,
                headers: {
                  Authorization: "Bearer "+localStorage.getItem("listfixToken")
                }
              }).then(function (response) {
                  setUserDetails(response.data);
              }).catch(function (error) {
                    console.log(error.response);
              })
        };
    }
    function showUserDetails (userDet) {
        return(
            <div>{user}</div>
        )
    }
    const getDistricts = (cityId) => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Location/GetCounties?cityId=" + cityId,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
              setUserDistricts(response.data);
          }).catch(function (error) {
                console.log(error.response);
          })
    }
    const createDistricts = () => {
        if(userDistricts.length <= 0) {
            axios({
                method: "get",
                url: "https://www.listefix.com/api/Location/GetCounties?cityId=" + user.County?.City.Id,
                headers: {
                  Authorization: "Bearer "+localStorage.getItem("listfixToken")
                }
              }).then(function (response) {
                  setUserDistricts(response.data);
              }).catch(function (error) {
                    console.log(error.response);
              })
        }
        return(
            userDistricts.map(dist => 
                <option value={dist.Id} selected={ dist.Id == user.County?.Id ? 'selected' : '' }>{dist.Name}</option>
            )
        )
    }

    const handleDeleteDealer = () => {
        axios({
            method: "post",
            url: "https://www.listefix.com/api/UserRelation/FireDealer?dealerId=" + user.Id,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
            }).then(function (response) {
               if(!response.data.IsSuccessful) {
                 setDealMessage(response.data.Errors[0]);
                 setSuccesAlertUserDeal(true);
               }
               else {
                 handleCloseDeal();
                 setDealMessage("Bayi Distribütör İlişkisi Silinmiştir.");
                 setSuccesAlertUserDeal(true);
                 window.location.reload();
               }
   
            }).catch(function (error) {
                console.log(error);
                setDealMessage(error.response.data?.Errors[0]);
                setSuccesAlertUserDeal(true);
            });
    }
    return[
            <tr key={user.Id}  >
              <td onClick={()=>openDetail(user.Id)}><strong>{user.FullName}</strong></td>
              {/* <td className="user-address-col" onClick={()=>openDetail(user.Id)}>{user.address}</td> */}
              <td onClick={()=>openDetail(user.Id)}>{user.PhoneNumber}</td>
              <td onClick={()=>openDetail(user.Id)}>{user.CompanyName}</td>
              {/* <td onClick={()=>openDetail(user.Id)}>{user.AuthPerson}</td> */}
              {/* <td onClick={()=>openDetail(user.Id)}>{memberShipTypeNames[user.memberShipType]}</td> */}
              <td className={(dashboard ? "d-none" : "")}><button variant="info" className="btn btn-link text-info edit-button" onClick={handleShow}>Düzenle</button></td>
              <td className={(dashboard ? "d-none" : "")}><button variant="success" className="btn btn-link text-success edit-price-button" onClick={handleShowPrice}>iskonto Düzenle</button></td>
              <td className={(dashboard ? "d-none" : "")}><button variant="danger" className="btn btn-link text-danger edit-price-button" onClick={handleDeleteDealer}>Bayi Atamasını Sil</button></td>
            </tr>,
            <tr  className={'expandable' + (selecteduser === user.UserName ? ' open': ' ' )}  key="tr-expander" id={user.Id}>
                <td className="user-detail-col" colSpan={12}>
                    <div  className="d-flex">
                        <div className="uk-width-3-4 ps-5 pe-5 col-12">
                            <p className="d-flex">
                                <strong className="pe-2">Adres:</strong><br/>
                                <span>{user.Address}</span>
                            </p>
                            <p className="d-flex">
                                <strong className="pe-2">Şehir:</strong><br/>
                                <span>{user.County?.City.Name}</span>
                            </p>
                            <p className="d-flex">
                                <strong className="pe-2">İlçe:</strong><br/>
                                <span>{user.County?.Name}</span>
                            </p>
                            <p className="d-flex">
                                <strong className="pe-2">Vergi No:</strong><br/>
                                <span>{user.TaxNumber}</span>
                            </p>
                            <p className="d-flex">
                                <strong className="pe-2">Mail:</strong><br/>
                                <span>{user.Email}</span>
                            </p>
                            <p className="d-flex">
                                <strong className="pe-2">Şirket Adı:</strong><br/>
                                <span>{user.CompanyName}</span>
                            </p>
                            <p className="d-flex">
                                <strong className="pe-2">Telefon No:</strong><br/>
                                <span>{user.PhoneNumber}</span>
                            </p>
                            <br/>
                            <div className="user-prices">
                                <p className="d-flex"><strong>Atanan Roller</strong></p>
                                {user.DealerBrandPrices.map((price, index) =>
                                    <UserRowDetail price={price.DiscountRoleId} brands={price.DistributorAllowedBrands?.Brand?.Name} dist={price.DistributorAllowedBrands?.Distributor?.CompanyName} index={index}></UserRowDetail>
                                )
                                }                                
                            </div>
                        </div>
                    </div>
                </td>
            </tr>,
            <Modal useRef={selectedModal} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Üye Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>{user.name}</strong> Üye bilgilerini değiştir.</p>
                    <FloatingLabel controlId="UserName" label="Kullanıcı Adı" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="UserName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.UserName} />
                    </FloatingLabel>
                    <FloatingLabel controlId="fullName" label="Adı Soyadı" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="FullName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.FullName} />
                    </FloatingLabel>
                    <FloatingLabel controlId="companyName" label="Şirket Adı" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="CompanyName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.CompanyName} />
                    </FloatingLabel>
                    <label htmlFor="roleId" className={loginUser.UserRoles[0].Role.Name === "Distributor" ? "d-none" : ""}>Kullanıcı Rolü</label>
                    <Form.Select name="UserRoles" id="roleId" className={ loginUser.UserRoles[0].Role.Name === "Distributor" ? "user-name-input-box mb-3 d-none " : "user-name-input-box mb-3"} onChangeCapture={(e)=>handleValueChange(e)}>
                        {   
                            roles.map(role => 
                                <SelectRole role={role} active={role.id === user.RoleId}/>
                            )
                        }
                    </Form.Select>
                    <FloatingLabel controlId="companyOfficalName" label="Şirket Yetkilisi Adı" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="CompanyOfficalName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.CompanyOfficalName} />
                    </FloatingLabel>
                    <Form.Select name="County" id="userCity" className="user-name-input-box mb-3" onChange={(e)=>handleCityValueChange(e)} required>
                        {   
                            cities.map(city => 
                                <option value={city.Id} selected={city.Id == user.County?.City.Id }>{city.Name}</option>
                            )
                        }
                    </Form.Select>
                    <Form.Select name="CountyDist" id="userDistrict" className="user-name-input-box mb-3" required>
                        { createDistricts() }
                    </Form.Select>
                    <FloatingLabel controlId="userAddress" label="Kullanıcı Adresi" className="search-box__input-box mb-3" >
                            <Form.Control rows={3} as="textarea" className="report-input" name="Address" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.Address}/>
                    </FloatingLabel> 
                    <FloatingLabel controlId="usertaxNumber" label="Vergi Numarası" className="user-name-input-box mb-3" >
                        <Form.Control className="user-name-input" type="text" name="TaxNumber" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.TaxNumber} />
                    </FloatingLabel> 
                    <FloatingLabel controlId="userPhone" label="Telefon Numarası" className="user-name-input-box mb-3" >
                        {/* <Form.Control className="user-name-input" type="text" name="PhoneNumber" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.PhoneNumber} /> */}
                        <MaskedInput id="userPhone" className='form-control' type='text' name='PhoneNumber' value={user.PhoneNumber} onChange={(e) => handleValueChange(e)} mask='1111  111  1111' />                     
                    </FloatingLabel>                     
                    <FloatingLabel controlId="userMail" label="Mail Adresi" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="email" name="Email" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={user.Email} />
                    </FloatingLabel> 
                    {/* <Form.Check 
                        disabled
                        defaultChecked={user.EmailConfirmed}
                        label={user.EmailConfirmed ? "Mail Doğrulandı" : "Mail Doğrulanmadı"}
                        type="switch"
                        id={"switch" + user.Id}
                        name="EmailConfirmed"
                    />  */}
                    <Form.Check 
                        onChangeCapture={(e)=>handleValueChange(e)}
                        defaultChecked={user.Active}
                        label={user.Active ? "Aktif" : "Aktif Değil"}
                        type="switch"
                        name="Active"
                        id={"switch" + user.Id}
                    />                                                                           
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseWithMessage}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>,
            <Modal useRef={selectedModal} show={showPrice} onHide={handleClosePrice}>
                <Modal.Header closeButton>
                    <Modal.Title>Üye İskonto Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UsersDistrubutor user={user} loginUser={loginUser}></UsersDistrubutor>                                                                         
                </Modal.Body>
            </Modal>,
            <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
                <Toast.Body>Bilgiler Kayıt Edilmiştir.</Toast.Body>
            </Toast>,
            <Toast onClose={() => setShowAlertFalse(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertFalse} delay={3000} autohide>
                <Toast.Body>Bilgiler Kayıt Edilemedi.</Toast.Body>
            </Toast>,
            <Toast onClose={() => setSuccesAlertUserDeal(false)} className="position-fixed report-alert bg-success text-white text-center" show={SuccesAlertUserDeal} delay={3000} autohide>
                <Toast.Body>{DealMessage}</Toast.Body>
            </Toast>

        ]
}

export default UserRow;