import './App.scss';
import Login from './pages/Login/Login.jsx';
import Signup from './pages/Login/Signup.jsx';
import ResetPassword from './pages/Login/ResetPassword.jsx';

import CompanySignUp from './pages/Login/CompanySignUp.jsx';
import Panel from './pages/Panel/Panel.jsx';
import Admin from './pages/Panel/Admin.jsx';
import Excel from './pages/Panel/Excel.jsx';
import Permission from './pages/Panel/Permission.jsx';
import Company from './pages/Panel/Company.jsx';
import Users from './pages/Panel/Users.jsx';
import UsersDistrubutor from './pages/Panel/UsersDistrubutor.jsx';
import FindCompany from './pages/Panel/FindCompany';
import Search from './pages/Search/Search';
import AuthProvider from './context/Auth/AuthProvider/AuthProvider.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Route, Router,Routes,BrowserRouter ,useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Detail from './pages/Detail/Detail';
import SearchResult from './pages/Search/SearchResult';
import DistrubutorBrand from './pages/Panel/DistrubutorBrand';
import ExcelResult from './pages/Panel/ExcelResult';
import Brands from './pages/Panel/Brands';
import Settings from './pages/Panel/Settings';
import axios from 'axios';
import Account from './pages/Account/Account';




function App() {
  const [loginUser , setLoginUser] = useState({
    "UserRoles" : [
      {
        "Role" : {
          "Name" : "Unauth"
        }
      }
    ]
  });
  function createRoutes () {
    if(loginUser.UserRoles[0].Role.Name === 'Admin') {
      return[
        <Route index element={<Search />} />,
        <Route path="/signup" element={<Signup />} />,
        <Route path="/reset-password" element={<ResetPassword />} />,
        <Route path="/signup-company" element={<CompanySignUp />} />,
        <Route path="/search" element={<Search />} />,
        <Route path="/account" element={<Account loginUser={loginUser} />} />,
        <Route path="/search-result" element={<SearchResult loginUser={loginUser} />} />,
        <Route path="/company-detail/:companyId" element={<Detail loginUser={loginUser} />} />,
        <Route path="/panel" element={<Panel loginUser={loginUser} />} />,
        <Route path="/panel/excel" element={<Excel loginUser={loginUser} />} />,
        <Route path="/panel/excel-result" element={<ExcelResult loginUser={loginUser} />} />,
        <Route path="/panel/permission" element={<Permission loginUser={loginUser} />} />,
        <Route path="/panel/company" element={<Company loginUser={loginUser} />} />,
        <Route path="/panel/distrubutor" element={<DistrubutorBrand />} />,
        <Route path="/panel/users" element={<Users loginUser={loginUser} />} />,
        <Route path="/panel/user-role" element={<UsersDistrubutor />} />,
        <Route path="/panel/bayi-bul" element={<FindCompany  loginUser={loginUser} />} />,
        <Route path="/panel/markalar" element={<Brands loginUser={loginUser} />} />,
        <Route path="/panel/ayarlar" element={<Settings loginUser={loginUser} />} />,
      ]
    }
    if(loginUser.UserRoles[0].Role.Name === 'Distributor') {
      return[
        <Route index element={<Search />} />,
        <Route path="/signup" element={<Signup />} />,
        <Route path="/reset-password" element={<ResetPassword />} />,
        <Route path="/signup-company" element={<CompanySignUp />} />,
        <Route path="/search" element={<Search />} />,
        <Route path="/account" element={<Account loginUser={loginUser} />} />,
        <Route path="/search-result" element={<SearchResult loginUser={loginUser} />} />,
        <Route path="/company-detail/:companyId" element={<Detail loginUser={loginUser} />} />,
        <Route path="/panel" element={<Panel loginUser={loginUser} />} />,
        <Route path="/panel/excel" element={<Excel loginUser={loginUser} />} />,
        <Route path="/panel/excel-result" element={<ExcelResult loginUser={loginUser} />} />,
        <Route path="/panel/permission" element={<Permission loginUser={loginUser} />} />,
        <Route path="/panel/company" element={<Company loginUser={loginUser} />} />,
        <Route path="/panel/distrubutor" element={<DistrubutorBrand />} />,
        <Route path="/panel/users" element={<Users loginUser={loginUser} />} />,
        <Route path="/panel/user-role" element={<UsersDistrubutor />} />,
        <Route path="/panel/bayi-bul" element={<FindCompany  loginUser={loginUser} />} />,
        <Route path="/panel/ayarlar" element={<Settings loginUser={loginUser} />} />
      ]

    }
    if(loginUser.UserRoles[0].Role.Name === 'Dealer') {
      return[
        <Route index element={<Search />} />,
        <Route path="/signup" element={<Signup />} />,
        <Route path="/reset-password" element={<ResetPassword />} />,
        <Route path="/signup-company" element={<CompanySignUp />} />,
        <Route path="/search" element={<Search />} />,
        <Route path="/account" element={<Account loginUser={loginUser} />} />,
        <Route path="/search-result" element={<SearchResult loginUser={loginUser} />} />,
        <Route path="/company-detail/:companyId" element={<Detail loginUser={loginUser} />} />
      ]
    }  
    if (loginUser.UserRoles[0].Role.Name === "Unauth") {
      return[
        <Route index element={<Login />} />,
        <Route path="/signup" element={<Signup />} />,
        <Route path="/reset-password" element={<ResetPassword />} />,
        <Route path="/signup-company" element={<CompanySignUp />} />
      ]
    }    
  }
  useEffect(() => {
    axios({
      method: "get",
      url: "https://www.listefix.com/api/Authenticate/GetUserInfo",
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
    }).then(function (response) {
      const user = response?.data?.user;
      setLoginUser(user);

    }).catch(function (error) {
      setLoginUser({
        "UserRoles" : [
          {
            "Role" : {
              "Name" : "Unauth"
            }
          }
        ]
      });

    });
  }, []);
  return(
    <BrowserRouter>
        <Routes>
          {createRoutes()}
        </Routes>
    </BrowserRouter>       
  )
}

export default App;