import React, {useRef,useState,useEffect} from "react";
import { Modal,ModalBody,ModalDialog,ModalDialogProps,ModalHeader,Button,Form,FloatingLabel,Toast, Select} from 'react-bootstrap';
import axios from "axios";
function BrandRow (props)  {
    const brand = props.brand;
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => 
    {
        setShow(true);
         ;
    };
    let selectedModal = useRef("modal-" + brand.id);
    const handleCloseWithMessage = () => {
        var brandData = {
            "active":true,
            "id":brand.Id,
            "name": document.getElementById("brandName").value
        };
        axios({
            method: "put",
            url: "https://www.listefix.com/api/Brand",
            data: brandData,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
                handleClose();
                brand.Name = document.getElementById("brandName").value;
          }).catch(function (error) {
                console.log(error.response);
          });        
        
    }
    const [activeControl, setActiveControl] = useState(brand.active);
    const handleValueChange = (e) => {
        setActiveControl(!activeControl);
    };
    const deleteBrand = () => {
        var brandData = {
            "active":false,
            "id":brand.Id,
            "name": brand.Name
        };
        axios({
            method: "put",
            url: "https://www.listefix.com/api/Brand",
            data: brandData,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
                window.location.reload();
          }).catch(function (error) {
                console.log(error.response);
          }); 
    }

    return[
            <tr key={brand.Name}  >
              <td ><strong>{brand.Name}</strong></td>
              <td><button variant="info" className="btn btn-link text-info edit-button" onClick={handleShow}>Düzenle</button></td>
              <td><button variant="danger" className="btn btn-link text-danger edit-button" onClick={deleteBrand}>Sil</button></td>
            </tr>,
            <Modal useRef={selectedModal} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Marka Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>{brand.Name}</strong> Marka bilgilerini değiştir.</p>
                    {/* <Form.Check 
                        onChangeCapture={(e)=>handleValueChange(e)}
                        defaultChecked={activeControl}
                        checked={activeControl}
                        label={activeControl ? "Aktif" : "Aktif Değil"}
                        type="switch"
                        id={"switch" + brand.id}
                    /> */}
                    <FloatingLabel controlId="brandName" label="Marka Adı" className="mb-4" >
                        <Form.Control className="" type="text" placeholder="Eklemek İstediğiniz Marka Adını Giriniz" defaultValue={brand.Name}/>
                    </FloatingLabel>  
                                                                                               
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseWithMessage}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>,
            <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
                <Toast.Body>Bilgiler Kayıt Edilmiştir.</Toast.Body>
            </Toast>

        ]
}

export default BrandRow;