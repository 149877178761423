import React from "react";
import { Container,Col ,Row} from "react-bootstrap";
import logo from "../../Content/logo-black.png";
import "./Header.scss";
function HeaderStatic (props) {
    return (
        <Container className="header-container" fluid={true}>
            {/* <Row className="pt-3 justify-content-between pb-3 top-menu">
                <Col className='header-container__account-box col-md-auto d-flex align-items-center justify-content-start gap-4'>
                        <div  className='header-container__account-box__account d-flex align-items-center text-decoration-none'>
                            <span className="header-container__account-box__account__text text-dark text-decoration-none ps-1">Bize Ulaşın</span>
                        </div> 
                        <a href="tel:+908500000000" className='header-container__account-box__account d-flex align-items-center text-decoration-none'>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.0752 16.7619C23.6547 17.3431 23.9801 18.1303 23.9801 18.9509C23.9801 19.7716 23.6547 20.5588 23.0752 21.1399L22.1632 22.1899C13.9722 30.0279 -5.95582 10.1059 1.76318 1.88994L2.91318 0.889938C3.49482 0.326484 4.27491 0.0146773 5.08468 0.0219759C5.89445 0.0292745 6.66879 0.355092 7.24018 0.928938C7.27118 0.958938 9.12318 3.36694 9.12318 3.36694C9.6711 3.94548 9.97597 4.71231 9.97485 5.50914C9.97374 6.30596 9.66672 7.07193 9.11718 7.64894L7.95918 9.10494C8.60017 10.662 9.54244 12.077 10.7319 13.2689C11.9213 14.4607 13.3345 15.4058 14.8902 16.0499L16.3552 14.8849C16.931 14.3339 17.697 14.0259 18.494 14.0248C19.291 14.0237 20.0578 14.3296 20.6352 14.8789C20.6352 14.8789 23.0442 16.7319 23.0752 16.7619ZM21.7002 18.2159C21.7002 18.2159 19.3072 16.3739 19.2762 16.3439C19.0702 16.1397 18.7918 16.0251 18.5017 16.0251C18.2116 16.0251 17.9332 16.1397 17.7272 16.3439C17.7002 16.3699 15.6832 17.9779 15.6832 17.9779C15.5473 18.0861 15.3855 18.157 15.2138 18.1837C15.0422 18.2104 14.8665 18.1918 14.7042 18.1299C12.6876 17.3797 10.8559 16.2046 9.33327 14.6844C7.81064 13.1642 6.63265 11.3343 5.87918 9.31894C5.81238 9.15439 5.7906 8.97503 5.81609 8.79928C5.84157 8.62353 5.9134 8.45773 6.02418 8.31894C6.02418 8.31894 7.63218 6.30494 7.65918 6.27894C7.86345 6.07292 7.97806 5.79455 7.97806 5.50444C7.97806 5.21432 7.86345 4.93595 7.65918 4.72994C7.62918 4.69994 5.78718 2.30494 5.78718 2.30494C5.57808 2.11745 5.30519 2.01703 5.02443 2.02429C4.74367 2.03154 4.47633 2.1459 4.27718 2.34394L3.12718 3.34394C-2.51482 10.1269 14.7572 26.4409 20.7002 20.8269L21.6122 19.7759C21.8272 19.5786 21.9567 19.3052 21.9731 19.0138C21.9896 18.7225 21.8916 18.4362 21.7002 18.2159Z" fill="#374957"/>
                            </svg>
                        </a>                                          
                        <a href="mailto:info@listfix.com" className='header-container__logout-box__logout d-flex align-items-center text-decoration-none'>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 1.00024H3C2.20435 1.00024 1.44129 1.31631 0.87868 1.87892C0.31607 2.44153 0 3.20459 0 4.00024L0 23.0002H24V4.00024C24 3.20459 23.6839 2.44153 23.1213 1.87892C22.5587 1.31631 21.7956 1.00024 21 1.00024ZM3 3.00024H21C21.2652 3.00024 21.5196 3.1056 21.7071 3.29314C21.8946 3.48067 22 3.73503 22 4.00024V4.66724L14.122 12.5462C13.5584 13.1076 12.7954 13.4227 12 13.4227C11.2046 13.4227 10.4416 13.1076 9.878 12.5462L2 4.66724V4.00024C2 3.73503 2.10536 3.48067 2.29289 3.29314C2.48043 3.1056 2.73478 3.00024 3 3.00024ZM2 21.0002V7.50024L8.464 13.9602C9.40263 14.8965 10.6743 15.4223 12 15.4223C13.3257 15.4223 14.5974 14.8965 15.536 13.9602L22 7.50024V21.0002H2Z" fill="#374957"/>
                            </svg>
                        </a>
                </Col>                
                <Col className='header-container__account-box col-md-auto d-flex align-items-center justify-content-end gap-4'>
                        <a href="/panel" className='header-container__account-box__account d-flex align-items-center text-decoration-none'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z" fill="#686868"/>
                                <path d="M8 9.33276C4.68781 9.33645 2.00369 12.0206 2 15.3328C2 15.701 2.29847 15.9994 2.66666 15.9994H13.3333C13.7015 15.9994 14 15.701 14 15.3328C13.9963 12.0206 11.3122 9.33642 8 9.33276Z" fill="#686868"/>
                            </svg>
                            <span className="header-container__account-box__account__text text-dark text-decoration-none ps-1">Yönetim Paneli</span>
                        </a>                    
                        <a href="/" className='header-container__account-box__account d-flex align-items-center text-decoration-none'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z" fill="#686868"/>
                                <path d="M8 9.33276C4.68781 9.33645 2.00369 12.0206 2 15.3328C2 15.701 2.29847 15.9994 2.66666 15.9994H13.3333C13.7015 15.9994 14 15.701 14 15.3328C13.9963 12.0206 11.3122 9.33642 8 9.33276Z" fill="#686868"/>
                            </svg>

                            <span className="header-container__account-box__account__text text-dark text-decoration-none ps-1">Hesabım</span>
                        </a>                  
                        <a href="/" className='header-container__logout-box__logout d-flex align-items-center text-decoration-none'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2205 6.11408L12.6345 3.52808C12.3696 3.27229 11.9476 3.27964 11.6918 3.54448C11.4423 3.80283 11.4423 4.21242 11.6918 4.47076L14.2778 7.05676C14.3546 7.13511 14.4218 7.22242 14.4778 7.31676C14.4678 7.31676 14.4598 7.31142 14.4498 7.31142L3.9938 7.33276C3.62562 7.33276 3.32715 7.63123 3.32715 7.99942C3.32715 8.36761 3.62562 8.66608 3.9938 8.66608L14.4458 8.64473C14.4645 8.64473 14.4798 8.63539 14.4978 8.63407C14.4387 8.74686 14.3637 8.85058 14.2751 8.94208L11.6891 11.5281C11.4243 11.7839 11.417 12.2059 11.6727 12.4707C11.9285 12.7356 12.3506 12.7429 12.6154 12.4871C12.621 12.4818 12.6264 12.4763 12.6318 12.4707L15.2178 9.88473C16.2588 8.84339 16.2588 7.15539 15.2178 6.11408H15.2205Z" fill="#646464"/>
                                <path d="M4.66788 14.6667H3.33453C2.22997 14.6667 1.33453 13.7712 1.33453 12.6667V3.33334C1.33453 2.22878 2.22997 1.33334 3.33453 1.33334H4.66788C5.03606 1.33334 5.33453 1.03487 5.33453 0.666687C5.33453 0.2985 5.0361 0 4.66788 0H3.33453C1.49453 0.00221875 0.00343945 1.49331 0.0012207 3.33334V12.6667C0.00343945 14.5067 1.49453 15.9978 3.33456 16H4.66791C5.0361 16 5.33456 15.7015 5.33456 15.3333C5.33456 14.9652 5.0361 14.6667 4.66788 14.6667Z" fill="#646464"/>
                            </svg>
                            <span className="header-container__logout-box__logout__text text-dark text-decoration-none ps-1">Çıkış Yap</span>
                        </a>
                </Col>
            </Row> */}
            <Row className="pt-3 pb-5">
                <Col className={"header-container__logo-box col-12 justify-content-center d-flex align-items-center" }>
                    <a className="header-container__logo-box__link" href="/search">
                        <img className="header-container__logo-box__link__image col-12" src={logo} height={50} alt="" />
                    </a>
                </Col>
            </Row>

        </Container>
    );
}

export default HeaderStatic;