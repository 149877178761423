import React,{useEffect, useState} from "react";
import { Container,Col,Table } from "react-bootstrap";
import Header from "../Shared/Header";
import SearchTable from "../../components/SearchTable/SearchTable";
import "./Search.scss";
import Footer from "../Shared/Footer";

function Search  ()  {
    const products = "";
    const [tableActive, setTableActive] = useState(false);
    const [productFilter, setProductFilter] = useState("");
    const [filteredProducts, setfilteredProducts] = useState(products);
    
    function activateTable(stateParam) {
      setTableActive(stateParam);
    }

    function searchProduct(listFilter) {
      setProductFilter(listFilter);
      // filterProducts(productFilter);
    }
    function filterProducts(filt){
      filt = filt.toLowerCase();
      if(filt === ""){
        setfilteredProducts(products);
      }
      else{
        var filtered = products.filter(item => (item.Name.toLowerCase().includes(filt) || item.Barkod.toString().toLowerCase().includes(filt)) || item.Distrubutor.toString().toLowerCase().includes(filt) || item.Brand.toLowerCase().includes(filt));
        setfilteredProducts(filtered)
      }
    }
    useEffect(() => {
      searchProduct(productFilter);
    }, [productFilter]);
  return (
    <div className="search-list">
      <Header tableActive={tableActive} activateTable={activateTable} searchProduct={searchProduct}/>
      <Footer/>
    </div>
  );
}
export default Search;