import React, {useEffect, useRef,useState} from "react";
import { Modal,ModalBody,ModalDialog,ModalDialogProps,ModalHeader,Button,Form,FloatingLabel,Toast} from 'react-bootstrap';
import "./ProductRow.scss";
import makeAnimated from "react-select/animated";
import SwiperSliderProduct from "../SwiperSlider/SwiperSlider-product";
import noimage from "../../Content/no-image.png";
import axios from "axios";
function ProductRowAdmin (props)  {
    const [showAlert, setShowAlert] = useState(false);
    const [show, setShow] = useState(false);
    const [product,setProduct] = useState(props.product);
    const animatedComponents = makeAnimated();
    const [optionSelected, setOptionSelected] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const[companies, setCompanies] = useState(props.companies);
    const[companySelect,setCompanySelect] = useState([]);
    const[image,setImage] = useState(noimage);
    const index = props.index;
    
    const[imageSet, setImageSet] = useState([noimage]);

    const handleCloseWithMessage = () => {
        handleClose();
    }

    const getCompanies = () => {
        var tempData = [];
        for(var i=0;i<companies.length;i++){
            tempData.push({value:companies[i].id,label:companies[i].companyName})
        }
        setCompanySelect(tempData);
    }
    const selectedAreas = props.selectedAreas;
    let selectedProduct = useRef(product.Id);
    let selectedModal = useRef("modal-" + product.Id);
    function openDetail(Id) {
        document.getElementById(Id).classList.toggle("open");
    }
    const handleActiveProduct = () => {
        product.Active = !(product.Active)
        axios({
            method: "patch",
            url: "https://www.listefix.com/api/Product",
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            },
            data: {
                Id: product.Id,
                patch: [
                    {
                        path: "/Active",
                        value: product.Active,
                    }
                ],
            }
            }).then(function (response) {
            }
            ).catch(function (error) {
                console.log(error.response);
            }
        );
    }
    const handleValueChange = (e) => {
        product[e.target.name] = e.target.value;
    }

    const checkImages = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_1.jpeg' ,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            setImage("https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_1.jpeg');
            setImageSet(["https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_1.jpeg']);
        }).catch(function (error) {
            setImage(image);
            setImageSet([noimage]);
        });
        axios({
            method: "get",
            url: "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_2.jpeg' ,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            
            setImage("https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_2.jpeg');
            setImageSet([...imageSet, "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_2.jpeg']);
        }).catch(function (error) {
            setImage(image);
            setImageSet(imageSet => [...imageSet,noimage]);
        });
        axios({
            method: "get",
            url: "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_3.jpeg' ,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            
            setImage("https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_3.jpeg');
            setImageSet([...imageSet, "https://www.listefix.com/uploads/" + product.DistributorId+'/images/'+product.Barcode+'_3.jpeg']);
        }).catch(function (error) {
            setImage(image);
            setImageSet(imageSet => [...imageSet,noimage]);
        });        
    }

    const updateProduct = () => {
        axios({
            method: "put",
            url: "https://www.listefix.com/api/Product/Update",
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listefixToken")
            },
            data: {
                Id: product.Id,
                Name: product.Name,
                Barcode: product.Barcode,
                Description: product.Description,
                Price: product.Price,
                SuggestionPrice: product.SuggestionPrice,
                Campaign:product.Campaign,
                CurrencyId: product.CurrencyId,
                RolePrice1:product.RolePrice1,
                RolePrice2:product.RolePrice2,
                RolePrice3:product.RolePrice3,
                RolePrice4:product.RolePrice4,
                RolePrice5:product.RolePrice5,
                paymentType1:product.PaymentType1,
                paymentType2:product.PaymentType2,
                paymentType3:product.PaymentType3,
                paymentType4:product.PaymentType4
            }
        }).then(function (response) {
            
        }).catch(function (error) {
            console.log(error);
        });
        handleCloseWithMessage();
    }

    const handleEditButtonClick = () => {
        getProduct();
    }


    const getProduct = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Product/"+product.Id,
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            setProduct(response.data.Data);
            handleShow();
        }).catch(function (error) {
            console.log(error);
        });
        handleCloseWithMessage();
    }

    useEffect(() => {
        getCompanies();
        checkImages();
      }, [product]);
    const priceFormatter = new Intl.NumberFormat();

    return[
            <tr key={selectedProduct}  >
              <td onClick={()=>openDetail(product.Id)}> {index} </td>
              <td onClick={()=>openDetail(product.Id)}><img className="uk-preserve-width uk-border-circle" src={imageSet[0]} width={30} height={30} alt="avatar" /></td>
              <td className={selectedAreas[3].active?"d-table-cell":"d-none"} onClick={()=>openDetail(product.Id)}>{product.Barcode}</td>
              <td className="brand-col" onClick={()=>openDetail(product.Id)}>{product.BrandName}</td>
              <td onClick={()=>openDetail(product.Id)}><strong>{product.Name}</strong></td>
              <td onClick={()=>openDetail(product.Id)}>{product.DistributorName}</td>
              
              <td onClick={()=>openDetail(product.Id)}>
                  <div className="d-flex flex-column">
                  {/* <strong>{parseFloat(product.Price) * parseFloat(product.Currency)} {product.CurrencyCode ? product.CurrencyCode : "TRY"}</strong> */}
                  <strong>{priceFormatter.format(product.Price)} {product.CurrencyCode ? product.CurrencyCode : "TRY"} </strong>
                  </div>
              </td>
              <td className={selectedAreas[0].active?"d-table-cell":"d-none"} onClick={()=>openDetail(product.Id)}>{priceFormatter.format(product.SuggestionPrice)} {product.CurrencyCode ? product.CurrencyCode : "TRY"} </td>
              <td className= {(selectedAreas[1].active?"d-table-cell":"d-none") + " text-success fw-bold"} onClick={()=>openDetail(product.Id)}><span className={product.UserSpecificPrice != 0 ? "" : " text-danger "} >{product.UserSpecificPrice != 0 ? priceFormatter.format(product.UserSpecificPrice) + (product.CurrencyCode ? product.CurrencyCode : "TRY") : "-"}</span></td>
              <td className="text-secondary" onClick={()=>openDetail(product.Id)}> {product.CurrencyCode ? product.CurrencyCode : "TRY"}</td>
              <td className={(selectedAreas[2].active?"d-table-cell":"d-none")+ " text-success"} onClick={()=>openDetail(product.Id)}>{product.Campaign}</td>
              
              <td className="text-success">
                
                <Form.Check 
                    
                    onChange={()=>handleActiveProduct()}
                    defaultChecked={product.Active ? 1 : 0}
                    
                    type="switch"
                    id={"switch" + product.Id}
                />
              </td>
              <td>
                 <button  onClick={handleEditButtonClick} className="text-success btn btn-link">Düzenle</button>
              </td>
              <td className="expand-button" onClick={()=>openDetail(product.Id)}><svg width="10" height="5" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.99985 7.50003C6.73724 7.50049 6.47713 7.44904 6.23446 7.34865C5.9918 7.24825 5.77137 7.10089 5.58585 6.91503L0.292847 1.62103L1.70685 0.207031L6.99985 5.50003L12.2928 0.207031L13.7068 1.62103L8.41385 6.91403C8.2284 7.10007 8.00801 7.24762 7.76535 7.34819C7.52268 7.44875 7.26253 7.50036 6.99985 7.50003Z" fill="#374957"/>
                 </svg>
             </td>
             
            </tr>,
            <tr useRef={product.Id} className={'expandable' + (selectedProduct === product.Id ? ' open': ' ' )}  key="tr-expander" id={product.Id}>
                <td className="product-detail-col" colSpan={12}>
                    <div  className="d-flex">
                        <div className="uk-width-1-4 uk-text-center col-2">
                            <SwiperSliderProduct images={imageSet}/>
                        </div>
                        <div className="uk-width-3-4 ps-5 pe-5 d-flex flex-wrap col-10">
                            <div className="col-12 col-lg-9 d-flex flex-column pe-4">
                                <h5>{product.Name}</h5>
                                <p className="text-main  d-flex flex-column">
                                    <strong className="col-12 mb-1 fs-6">Distrubutor: {product.DistributorName}</strong>
                                    <a className="text-main" href={"/company-detail/" + (product.DistributorId) } target="_blank">Satıcı Detayını Görüntüle</a>
                                </p>
                                <p className={(product.QuantityBox !== undefined && product.QuantityBox !== "" && product.QuantityBox !== null)?'d-block':'d-none'} ><strong>Koli Adedi:</strong> {product.QuantityBox}</p>
                                <p><strong>Birim(Adet):</strong> {product.QuantityType}</p>
                                
                                
                                <p className="col-12"><strong>Açıklama:</strong><br/><span>{product.Description}</span></p>
                                
                            </div>
                            <div className="col-12 col-lg-3 d-flex flex-column">
                                <p className="d-flex flex-column">
                                    <strong className="h5">{priceFormatter.format(product.Price)} {product.CurrencyCode ? product.CurrencyCode : "TRY"}</strong>
                                    <strong className="h4 text-success">{priceFormatter.format(product.Price)} {product.CurrencyCode ? product.CurrencyCode : "TRY"}</strong>
                                </p>
                                <p><strong>Önerilen Satış Fiyatı:</strong> {priceFormatter.format(product.SuggestionPrice)} {product.CurrencyCode ? product.CurrencyCode : "TRY"}</p>
                                <p className={product.UserSpecificPrice != 0 ? "" : " text-danger "}><strong className="text-dark">Size Özel Satış Fiyatı:</strong> {product.UserSpecificPrice != 0 ? priceFormatter.format(product.UserSpecificPrice) + (product.CurrencyCode ? product.CurrencyCode : "TRY") : "-"}</p>
                                <a className="text-success fs-6" href={"/company-detail/" + product.DistributorId} target="_blank">{product.Campaing}</a>
                                <p>{product.PaymentType1}</p>
                                <p>{product.PaymentType2}</p>
                                <p>{product.PaymentType3}</p>
                                <p>{product.PaymentType4}</p>
                                <a className="btn btn-warning text-dark fs-6" href={"/company-detail/" + product.DistributorId} target="_blank">{product.Campaign}</a>
                            </div>
                            
                        </div>
                    </div>
                </td>
            </tr>,
            <Modal useRef={selectedModal} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Ürünü Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="productName" label="Şirket Adı" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input"  type="text" name="Name" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.Name} />
                    </FloatingLabel>
                    <FloatingLabel controlId="productDescription" label="Ürün Açıklaması" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="Description" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.Description} />
                    </FloatingLabel>   
                    <FloatingLabel controlId="productPrice" label="Satış Fiyatı" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="Price" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.Price} />
                    </FloatingLabel>
  
                    <FloatingLabel controlId="productSuggestionPrice" label="Önerilen Satış Fiyatı" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="SuggestionPrice" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.SuggestionPrice} />
                    </FloatingLabel>
                    <FloatingLabel controlId="productRolePrice1" label="İskonto 1 Fiyatı" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="RolePrice1" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.RolePrice1} />
                    </FloatingLabel>  
                    <FloatingLabel controlId="productRolePrice2" label="İskonto 2 Fiyatı" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="RolePrice2" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.RolePrice2} />
                    </FloatingLabel>
                    <FloatingLabel controlId="productRolePrice3" label="İskonto 3 Fiyatı" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="RolePrice3" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.RolePrice3} />
                    </FloatingLabel>
                    <FloatingLabel controlId="productRolePrice4" label="İskonto 4 Fiyatı" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="RolePrice4" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.RolePrice4} />
                    </FloatingLabel>
                    <FloatingLabel controlId="productRolePrice5" label="İskonto 5 Fiyatı" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="RolePrice5" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.RolePrice5} />
                    </FloatingLabel>                                                              
                    <Form.Select aria-label={product.CurrencyId} name="CurrencyId" onChangeCapture={handleValueChange} className="mb-3" defaultValue={product.CurrencyId}>
                        <option disabled>Mevcut Birim : {product.CurrencyId}</option>
                        <option value="1">USD</option>
                        <option value="3">TRY</option>
                        <option value="2">EUR</option>
                    </Form.Select> 
                    <FloatingLabel controlId="productCampaign" label="Ürün Kampanyası" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="Campaign" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.Campaign} />
                    </FloatingLabel>
                    <FloatingLabel controlId="paymentType1" label="Ödeme Seçeneği 1" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="PaymentType1" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.PaymentType1} />
                    </FloatingLabel>
                    <FloatingLabel controlId="paymentType2" label="Ödeme Seçeneği 2" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="PaymentType2" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.PaymentType2} />
                    </FloatingLabel>
                    <FloatingLabel controlId="paymentType3" label="Ödeme Seçeneği 3" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="PaymentType3" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.PaymentType3} />
                    </FloatingLabel>
                    <FloatingLabel controlId="paymentType4" label="Ödeme Seçeneği 4" className="product-name-input-box mb-3" >
                        <Form.Control className="product-name-input" type="text" name="PaymentType4" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={product.PaymentType4} />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={updateProduct}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>,
                <Toast onClose={() =>setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
                    <Toast.Body>Değişiklikler Kaydedilmiştir.</Toast.Body>
                </Toast>

        ]
}

export default ProductRowAdmin;