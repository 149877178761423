import {React, useEffect, useState } from 'react';
import { Col, Container,Row,Form ,FloatingLabel,Button,Modal,ModalBody,ModalDialog,ModalDialogProps,ModalHeader} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SwiperSlider from '../../components/SwiperSlider/SwiperSlider';
import './Login.scss';
import axios from 'axios';
import MaskedFormControl from 'react-bootstrap-maskedinput'
import MaskedInput from 'react-maskedinput'


function Signup() {
    const [fullNameError, setFullNameError] = useState("Bu Alan Boş Bırakılamaz");
    const [mailError, setMailError] = useState("Bu Alan Boş Bırakılamaz");
    const [phoneError, setPhoneError] = useState("Bu Alan Boş Bırakılamaz");
    const [usernameError, setUsernameError] = useState("Bu Alan Boş Bırakılamaz");
    const [passwordError, setPasswordError] = useState("Bu Alan Boş Bırakılamaz");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [validated, setValidated] = useState(false);
    const [loaderButton, setloaderButton] = useState(false);

    const [formData, setformData] = useState({
        username: "",
        email: "",
        password: "",
        phoneNumber:"",
        fullName : ""
    });
    const SignupRequest = () => {       
        setloaderButton(true); 
        if(formData.username != "" &&
         formData.email != "" &&
          formData.password != "" &&
           formData.phoneNumber != "" &&
           formData.fullName != "" && 
           formData.password.length >= 6 ){
            document.getElementById("loginErrorFill").style.display = "none";
            if(!formData.email.includes("@")){
                document.getElementById("mailError").style.display = "block"
                setMailError("Geçerli Bir Mail Adresi giriniz.");
            }
            else{
                axios({
                    method: "post",
                    url: "https://www.listefix.com/api/Authenticate/register",
                    data: {
                        "username": formData.username,
                        "email": formData.email,
                        "password": formData.password,
                        "fullName": formData.fullName,
                        "phoneNumber": formData.phoneNumber
                    },
                    }).then(function (response) {
                        
                        document.getElementById("loginError").style.display = "none";
                        document.getElementById("loginSuccess").style.display = "block";
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 5000);
                        
                        
                    }).catch(function (error) {
                        if (error.response.status) {
                        console.log(error.response);
                        document.getElementById("loginError").style.display = "block";
                        document.getElementById("loginSuccess").style.display = "none";
                        }
                    });
            }

        }
        else{
            // "username": formData.username,
            // "email": formData.email,
            // "password": formData.password,
            // "phoneNumber": formData.phoneNumber
            document.getElementById("loginErrorFill").style.display = "block";
            if(formData.fullName == ""){
                document.getElementById("fullnameError").style.display = "block"
                document.getElementById("fullname").classList.add("invalid");
            }
            else{
                document.getElementById("fullnameError").style.display = "none";
                document.getElementById("fullname").classList.remove("invalid");
            }
            if(formData.email == ""){
                setMailError("Bu Alan Boş Bırakılamaz");
                document.getElementById("mailError").style.display = "block"
                document.getElementById("mail").classList.add("invalid");
                if(!formData.email.includes("@") && formData.email != ""){
                    setMailError("Geçerli Bir Mail Adresi giriniz.");
                }
            }
            else{
                document.getElementById("mailError").style.display = "none";
                document.getElementById("mail").classList.remove("invalid");
            }
            if(formData.phoneNumber == ""){
                document.getElementById("phoneError").style.display = "block"
                document.getElementById("phone").classList.add("invalid");
            }
            else{
                document.getElementById("phoneError").style.display = "none";
                document.getElementById("phone").classList.remove("invalid");
            }
            if(formData.username == ""){
                document.getElementById("usernameError").style.display = "block"
                document.getElementById("username").classList.add("invalid");
            }
            else{
                document.getElementById("usernameError").style.display = "none";
                document.getElementById("username").classList.remove("invalid");
            }
            if(formData.password == ""){
                document.getElementById("passwordError").style.display = "block"
                document.getElementById("password").classList.add("invalid");
            }
            else{
                if(formData.password.length < 6) {
                    setPasswordError("Şifre Minimum 6 Haneli olmalıdır.");
                }
                else {
                    setPasswordError("Bu Alan Boş Bırakılamaz");
                }
                document.getElementById("passwordError").style.display = "none";
                document.getElementById("password").classList.remove("invalid");
            }
        }
        setloaderButton(false);
        

    }
    const changeHandleMail = (event) => {
        setformData({  ...formData, email: event.target.value });
        if(event.target.value != ""){
            document.getElementById("mailError").style.display = "none";
            document.getElementById("mail").classList.remove("invalid");
        }
        else{
            document.getElementById("mailError").style.display = "block";
            document.getElementById("mail").classList.add("invalid");
        }

    }
    const changeHandlePassword = (event) => {
        setformData({  ...formData, password: event.target.value });
        if(event.target.value != ""){
            document.getElementById("passwordError").style.display = "none";
            document.getElementById("password").classList.remove("invalid");
        }
        else{
            document.getElementById("passwordError").style.display = "block";
            document.getElementById("password").classList.add("invalid");
        }

    }
    const changeHandleUsername = (event) => {
        setformData({  ...formData, username: event.target.value });
        if(event.target.value != ""){
            document.getElementById("usernameError").style.display = "none";
            document.getElementById("username").classList.remove("invalid");
        }
        else{
            document.getElementById("usernameError").style.display = "block";
            document.getElementById("username").classList.add("invalid");
        }

    }
    const changeHandleFullname = (event) => {
        setformData({  ...formData, fullName: event.target.value });
        if(event.target.value != ""){
            document.getElementById("fullnameError").style.display = "none";
            document.getElementById("fullname").classList.remove("invalid");
        }
        else{
            document.getElementById("fullnameError").style.display = "block";
            document.getElementById("fullname").classList.add("invalid");
        }

    }
    const changeHandlePhone = (event) => {
        
        setformData({  ...formData, phoneNumber: event.target.value.replace(/\D/g, "") });
        if(event.target.value != ""){
            document.getElementById("phoneError").style.display = "none";
            document.getElementById("phone").classList.remove("invalid");
        }
        else{
            document.getElementById("phoneError").style.display = "block";
            document.getElementById("phone").classList.add("invalid");
        }
        console.log(event.target.value.replace(/\D/g, ""));
    }  
    return (
        <Container className='login-container'>
            <Row>
                <Col  className='login-container__slider-box col-12 col-md-6 pe-0 pe-md-2 mb-5 mb-md-0'>
                    <SwiperSlider />
                </Col>
                <Col className='login-container__login-box  col-12 col-md-6 mb-5 mb-md-0 flex-wrap position-relative'>
                    <Col className='login-container__login-box__wrapper col-12'>
                        <h1 className='login-container__login-box__wrapper__title'>Üye Ol</h1>
                        <Col xs={12} className='login-container__login-box__wrapper__form-box'>
                            <Form className='login-container__login-box__wrapper__form-box__form' noValidate validated={validated}>
                                <Form.Control.Feedback id="fullnameError" type="invalid">
                                    {fullNameError}    
                                </Form.Control.Feedback>
                                <FloatingLabel controlId="fullname" label="Adınız Soyadınız" className="login-container__login-box__wrapper__form-box__form__name mb-3">
                                    <Form.Control type="text" placeholder="Adınız Soyadınız" onChange={changeHandleFullname}  required/>
                                </FloatingLabel>  
                                <Form.Control.Feedback id="mailError" type="invalid">
                                    {mailError}    
                                </Form.Control.Feedback>                              
                                <FloatingLabel controlId="mail" label="Mail Adresi" className="login-container__login-box__wrapper__form-box__form__mail mb-3">
                                    <Form.Control type="email" onChange={changeHandleMail} placeholder="name@example.com" required/>
                                </FloatingLabel>
                                <Form.Control.Feedback id="phoneError" type="invalid">
                                    {phoneError}    
                                </Form.Control.Feedback> 
                                <FloatingLabel controlId="phone" label="Telefon Numarası" className="login-container__login-box__wrapper__form-box__form__mail mb-3">
                                    {/* <Form.Control type="text" pattern="[0-9]*" onKeyPress={(e) => changeHandlePhone(e)} placeholder="053* *** ** **" required/> */}
                                    <MaskedInput id="phone" className='form-control' type='text' name='phoneNumber' onChange={(e) => changeHandlePhone(e)} mask='(1111) - 111 - 1111' />
                                </FloatingLabel> 
                                <Form.Control.Feedback id="usernameError" type="invalid">
                                    {usernameError}    
                                </Form.Control.Feedback>
                                <FloatingLabel controlId="username" label="Kullanıcı Adınız" className="login-container__login-box__wrapper__form-box__form__name mb-3">
                                    <Form.Control type="text" placeholder="Kullanıcı Adınız" onChange={changeHandleUsername}  required/>
                                </FloatingLabel>  
                                <Form.Control.Feedback id="passwordError" type="invalid">
                                        {passwordError}
                                </Form.Control.Feedback> 
                                <FloatingLabel controlId="password" label="Şifre" className="login-container__login-box__wrapper__form-box__form__password">
                                    <Form.Control type="password" onChange={changeHandlePassword} placeholder="name@example.com" required/>
                                    <Form.Control.Feedback id="loginError" type="invalid">
                                        Bu Kullanıcı Adı ve Mail İle Daha Önce Kayıt Olunmuş.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback id="loginSuccess" type="valid">
                                        Üyeliğiniz Tamamlanmıştır Giriş İçin Yönlendiriliyor...
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback id="loginErrorFill" type="invalid">
                                        Yukarıdaki Zorunlu Alanları Doldurunuz.
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                <Button className='login-container__login-box__wrapper__form-box__form__submit-button col-12 mb-3 d-flex justify-content-center align-items-center' variant="primary" onClick={SignupRequest}>
                                    <span className={"pe-2" + (loaderButton ? " d-none" : " d-block")}>Üye Ol</span>
                                    <span className={"spinner-border spinner-border-sm ps-2 ms-2" + (loaderButton ? " d-block" : " d-none") } role="status" aria-hidden="true"></span>
                                </Button>                                                        
                            </Form>
                        </Col>
                    </Col>
                    <Col className='login-container__login-box__bottom col-12 bottom-0 end-0 mb-5 d-flex align-items-center justify-content-center'>
                        <div className='login-container__login-box__bottom__wrapper d-flex flex-column'>
                            <div className='login-container__login-box__bottom__wrapper__listfix-info-box d-flex justify-content-center flex-column'>
                                <a onClick={handleShow} className='login-container__login-box__bottom__wrapper__listfix-info-box__info-button btn btn-link'>
                                    List Fix Nedir ?
                                </a>
                                <div className='login-container__login-box__bottom__wrapper__listfix-info-box__signup-box d-flex align-items-center'>
                                    <p className='login-container__login-box__bottom__wrapper__listfix-info-box__signup-box__text m-0 pe-2'>Hesabın var mı ? /</p>
                                    <a href='/' className='login-container__login-box__bottom__wrapper__listfix-info-box__signup-box__link btn-link'>
                                        Giriş Yap
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>List Fix Nedir ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere urna pulvinar, hendrerit metus sit amet, tempus nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc nulla eros, ornare ut mi vehicula, placerat pellentesque purus. Vivamus vehicula porta nisl sit amet luctus. Nunc sollicitudin non tortor vitae volutpat. Vestibulum sit amet egestas velit, tristique pretium ipsum. Proin quis erat sodales, viverra mauris blandit, scelerisque dui. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam a risus ut ipsum laoreet rhoncus vel ac arcu. Morbi vel ornare erat. Nullam tincidunt massa ac felis efficitur imperdiet. Cras placerat dapibus pellentesque. Morbi luctus lacinia egestas. Vivamus finibus diam eget cursus volutpat. Nunc interdum tellus non tellus consectetur, id posuere metus convallis. Donec eu neque nibh.
                </Modal.Body>
            </Modal>  
        </Container>
    );
}

export default Signup;