import React , {useState} from "react";
import { useEffect } from "react";
import ProductRow from "../ProductRow/ProductRow";
import Filter from "./Filter";
import "./SearchTable.scss";
import { Pagination } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
function SearchTable(props)  {
  // const [products,setProducts] = useState(props.products);
  const filters = props.filter;
  const [filteredProducts,setFilteredProducts] = useState(props.products);
  const tableActive = props.tableActive;
  const [selectedBrand, setSelectedBrand] = useState([]);
  const loginUser = props.loginUser;
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(searchParams.get("PageIndex"));
  const [selectedAreas , setselectedAreas] = useState([
    {
        name: "filter-1",
        active: false,
    },
    {
        name: "filter-2",
        active: true,
    },
    {
        name: "filter-3",
        active: true,
    },
    {
        name: "filter-4",
        active: true,
    },
    {
        name: "filter-5",
        active: true,
    }
]);
  useEffect(() => {
    if((localStorage['selectedAreas'] === undefined || localStorage['selectedAreas'] === null)){
        localStorage['selectedAreas'] = JSON.stringify(selectedAreas);
    }
    if(localStorage['selectedAreas'] !== undefined || localStorage['selectedAreas'] !== null){
        setselectedAreas(JSON.parse(localStorage['selectedAreas']));
    }
    filterBrand();
  }, [props.products,selectedBrand]);
  const filterBrand = () => {
    setFilteredProducts(props.products)
  }
  
  
    return(
      <div className={'table-responsive product-list ' +  (tableActive ? " d-block" : ' d-none')}>
        <p><strong>{filteredProducts.length} Ürün Listelenmekte</strong></p>
        <div className={ filters ? "col-12 d-flex pb-4" : "d-none col-12 d-flex pb-4"}>
          <Filter loginUser={loginUser}  products={filteredProducts} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand}  selectedAreas={selectedAreas} setselectedAreas={setselectedAreas}/>
        </div>
        <table className="table table-hover table-middle table-divider align-middle">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className={selectedAreas[3].active?"d-table-cell":"d-none"}>Barkod/Kod</th>              
              <th>Marka</th>
              <th className="">Ürün Adı</th>
              <th>Satıcı</th>              
              <th>Liste Fiyatı</th>
              <th className={selectedAreas[0].active?"d-table-cell":"d-none"}>Önerilen Satış Fiyatı</th>
              <th className={selectedAreas[1].active?"d-table-cell":"d-none"}>Size Özel İndirim Oranı</th>
              <th>Döviz Kuru</th>
              <th className={selectedAreas[2].active?"d-table-cell":"d-none"}>Kampanya </th>
              {/* <th className={selectedAreas[4].active?"d-table-cell":"d-none"}></th> */}
              {/* <th ></th> */}
              <th ></th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) =>
              <ProductRow key={index} index={index + 1} product={product} selectedAreas={selectedAreas} />
              )
            }
          </tbody>
        </table>
        <div className="col-12 d-flex justify-content-center">
          <Pagination>
            { pageIndex == 1 ? <></> : <Pagination.Prev href={window.location.href.replace("PageIndex="+pageIndex,"") + "PageIndex=" + (Number(pageIndex) - 1)} /> }
            <Pagination.Item active>{pageIndex}</Pagination.Item>
            {filteredProducts.length == 20 ? <Pagination.Next href={window.location.href.replace("PageIndex="+pageIndex,"") + "PageIndex=" + (Number(pageIndex) + 1)} /> : <></>}
          </Pagination>
        </div>
      </div>
          )
}

export default SearchTable;