import React ,{useEffect, useState} from "react";
import { Container, Row,Col,Dropdown, Form,Toast,Button } from "react-bootstrap";
import SelectCompany from "../../components/CompanyList/SelectCompany";
import SelectBrand from "../../components/Brands/SelectBrand";
import FooterResult from "../Shared/FooterResult";
import Select2 from "../../components/SelectDropdown/Select2";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
function UsersDistrubutor (props)  {
  const [companies,setCompanies] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlertErrorUnauth, setShowAlertErrorUnauth] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [priceSelected, setPriceSelected] = useState(null);
  const animatedComponents = makeAnimated();
  const [brands, setBrands] = useState([{value:"",label:"Distrubutor Seçiniz"}]);
  const [companySelected, setCompanySelected] = useState(null);
  const user = props.user;
  const loginUser = props.loginUser;

  // const brands = [
  //   { value: "MAKITA", label: "MAKITA" },
  //   { value: "FACTOR", label: "FACTOR" },
  //   { value: "KNIPEX", label: "KNIPEX" },
  //   { value: "CLIPPER", label: "CLIPPER" },
  //   { value: "WELLER", label: "WELLER" },
  //   { value: "EDN", label: "EDN" }
  // ];
  // useEffect(()=>{
  //   axios({
  //     method: "get",
  //     url: "https://www.listefix.com/api/Product/GetBrands",
  //     headers: {
  //       Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //     }
  //   }).then(function (response) {
  //       var tempData = [];
  //       for(var i=0;i<response.data.Data.length;i++){
  //         tempData.push({value:response.data.Data[i].Id,label:response.data.Data[i].Name})
  //       }
  //       setBrands(tempData);
  //   }).catch(function (error) {
  //         console.log(error.response);
  //   });
  // },[]);

  function handleSaveClick () {
    if(optionSelected != "" && optionSelected.length > 0 && priceSelected != ""){
      optionSelected.map((brand) => {
        axios({
          method: "post",
          url: "https://www.listefix.com/api/UserRelation/AssignDealerBrandPrice?dealerId="+user.Id+"&brandId="+brand.value+"&discountRoleId="+priceSelected.value,
          headers: {
            Authorization: "Bearer "+localStorage.getItem("listfixToken")
          }
        }).then(function (response) {
            setShowAlert(true);
        }).catch(function (error) {
              console.log(error.response);
              setShowAlertErrorUnauth(true);
        });
      })
      
    }
    else {
      setShowAlertError(true);
    }

  }
  function handleCompanyChange (e) {
    setCompanySelected(e.target.value)
    if(loginUser.UserRoles[0].Role.Name === "Admin") {
      axios({
        method: "get",
        url: "https://www.listefix.com/api/User/" + e.target.value,
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
        var tempData = [];
          response.data.DistributorAllowedBrands.map(obj => {
            tempData.push({value:obj.Brand.Id,label:obj.Brand.Name})
          });
          setBrands(tempData);
      }).catch(function (error) {
            console.log(error.response);
      });
    }
    else if(loginUser.UserRoles[0].Role.Name === "Distributor") {
      var tempDataStatic = [];
      loginUser.DistributorAllowedBrands.map(obj => {
        tempDataStatic.push({value:obj.Brand.Id,label:obj.Brand.Name})
      });
      setBrands(tempDataStatic);
    }
  }
  

  //
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleChange = (selected) => {
    setOptionSelected(selected)
  };
  const handleChangePrice = (selected) => {
    setPriceSelected(selected)
  };
  

  const priceRoles = [
    { value: "1", label: "İskonto-1" },
    { value: "2", label: "İskonto-2" },
    { value: "3", label: "İskonto-3" },
    { value: "4", label: "İskonto-4" },
    { value: "5", label: "İskonto-5" },
    { value: "0", label: "İskonto Kaldır"}
  ];

  useEffect(() => {
    const getData = async () => {
      if(loginUser.UserRoles[0].Role.Name === "Admin") {
        axios({
          method: "get",
          url: "https://www.listefix.com/api/User/GetByRole/Distributor",
          headers: {
            Authorization: "Bearer "+localStorage.getItem("listfixToken")
          }
        }).then(function (response) {
          setCompanies(response.data.Data);
        }).catch(function (error) {
              console.log(error.response);
        });
      }
      else if(loginUser.UserRoles[0].Role.Name === "Distributor"){
        setCompanies([loginUser]);
      }      
    }
    getData();
  },[]);
  const createCompanies = () => {
    return (
      companies.map((company, index) =>
        <SelectCompany key={index} index={index + 1} company={company} />
      )
    )
  }
  return (
    <div>
      {/* <HeaderPanel /> */}
      <Container>
        <h1 className="text-center mb-4 mt-4">İskonto Tanımla</h1>
        <Row className="justify-content-center">
          <Col className="col-12 brand-dist-wrapper">
          <Form.Group controlId="companySelect" >
            <Form.Label>Distrubutor Seçiniz</Form.Label>
            <Form.Select className="mb-4" onChangeCapture={handleCompanyChange}>
              <option value="" selected disabled>Distrubutor Seçiniz</option>
              {createCompanies()}
            </Form.Select>
          </Form.Group>
          <Form.Label>Marka Seçiniz</Form.Label>
          <Select2
            className="mb-4"
              options={brands}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChange}
              allowSelectAll={true}
              value={optionSelected}
            /> 
          <Form.Label>İskonto Seçiniz</Form.Label>
          <Select2
            className="mb-4"
              options={priceRoles}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChangePrice}
              allowSelectAll={false}
              value={priceSelected}
            />
          <Button variant="success" onClick={handleSaveClick}>
                Kaydet
          </Button>

          </Col>
        </Row>
      </Container>
      <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
          <Toast.Body>Üye Rol Atamanız Kaydedilmiştir.</Toast.Body>
      </Toast>
      <Toast onClose={() => setShowAlertError(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertError} delay={3000} autohide>
          <Toast.Body>Lütfen Üye, Distrubutor, Marka ve İskonto Seçtiğinizden Emin Olunuz.</Toast.Body>
      </Toast> 
      <Toast onClose={() => setShowAlertErrorUnauth(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertErrorUnauth} delay={3000} autohide>
          <Toast.Body>Bu Markayı Atama Yetkiniz Bulunmamaktadır.</Toast.Body>
      </Toast>  
      <FooterResult></FooterResult>    
    </div>
  );
}
export default UsersDistrubutor;