import React, {useRef,useState} from "react";

function CompanyRowDetail (props)  {
    const dealer = props.dealer;
    const phone = props.phone;
    const mail = props.mail;
    const index = props.index;
    return(
            <div className="user-role-details col-12 d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
                <div className="user-role-details__item d-flex align-items-center pe-5 col-4">
                    <label className="user-role-details__label pe-3">{index+1}</label>
                    <span className="user-role-details__label">{dealer}</span>
                </div>
                <div className="user-role-details__item d-flex align-items-center pe-5 col-4">
                    <label className="user-role-details__label pe-3">Telefon: </label>
                    <span className="user-role-details__label">{phone}</span>
                </div>
                <div className="user-role-details__item d-flex align-items-center pe-5 col-4">
                    <label className="user-role-details__label pe-3">Email: </label>
                    <span className="user-role-details__label">{mail}</span>
                </div>
                
            </div>
        )
}

export default CompanyRowDetail;