import {React, useEffect, useState } from 'react';
import { Col, Container,Row,Form ,FloatingLabel,Button,Modal,ModalBody,ModalDialog,ModalDialogProps,ModalHeader, Toast} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SwiperSlider from '../../components/SwiperSlider/SwiperSlider';
import './Login.scss';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import MaskedInput from 'react-maskedinput';


function CompanySignUp() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [validated, setValidated] = useState(false);
    const [loaderButton, setloaderButton] = useState(false);
    const [SuccesAlert, setSuccesAlert] = useState(false);
    const [formPostValidate, setformPostValidate] = useState(true);
    const [loginUser , setLoginUser] = useState({
        "UserRoles" : [
          {
            "Role" : {
              "Name" : "Unauth"
            }
          }
        ]
      });
    useEffect(() => {
        axios({
          method: "get",
          url: "https://www.listefix.com/api/Authenticate/GetUserInfo",
          headers: {
            Authorization: "Bearer "+localStorage.getItem("listfixToken")
          }
        }).then(function (response) {
          
          const user = response?.data?.user;
          setLoginUser(user);
    
        }).catch(function (error) {
          setLoginUser({
            "UserRoles" : [
              {
                "Role" : {
                  "Name" : "Unauth"
                }
              }
            ]
          });
    
        });
      }, []); 
    const [formData, setformData] = useState({
        address: loginUser.Address,
        fullName:loginUser.FullName,
        title: "",
        brand: "",
        mail: loginUser.Email,
        phone:loginUser.PhoneNumber,
        files: [],
        password:"",
        username:""
    });
        const SignupRequest = () => {
            setloaderButton(true);
            if(formData.address != "" &&
            formData.mail != "" &&
            formData.fullName != "" &&
            formData.phone != "" &&
            formData.title != "" &&
            formData.brand != "" &&
            formData.files.length > 0 ){
                       
                var data = new FormData();
                for(var i=0; i<formData.files.length;i++){
                    data.append('files', formData.files[i], formData.files[i].name);
                }
                data.append( "address", formData.address)
                data.append("mail", formData.mail)
                data.append("fullName", formData.fullName)
                data.append("phone", formData.phone)
                data.append( "title", formData.title)
                data.append("brand", formData.brand)

                let mailBody = "<p>Adres :"+ document.getElementById("address").value +"</p>";
                mailBody += "<p>Adı Soyadı :"+ document.getElementById("fullname").value +"</p>";
                mailBody += "<p>Mail Adresi :"+ document.getElementById("mail").value +"</p>";
                mailBody += "<p>Telefon Numarası :"+ document.getElementById("phone").value +"</p>";
                mailBody += "<p>Ünvan :"+ document.getElementById("title").value +"</p>";
                mailBody += "<p>Markalar :"+ document.getElementById("brands").value +"</p>";
        
                let mailData = {
                    "To" : document.getElementById("mail").value,
                    "subject" : "Satıcı Olmak İstiyorum",
                    "body" : mailBody
                };
                var dataMail = new FormData();
                dataMail.append( "To", mailData.To);
                dataMail.append("subject", mailData.subject);
                dataMail.append("body", mailData.body);
                for(var i=0; i<formData.files.length;i++){
                    dataMail.append('files', formData.files[i], formData.files[i].name);
                }                
                axios({
                    method: "post",
                    url: "https://www.listefix.com/api/Email/Post",
                    data: dataMail
                  }).then(function (response) {
                    //   document.getElementById("loginError").style.display = "none";
                      document.getElementById("companyLoginForm").classList.add("d-none");
                      document.getElementById("loginSuccess").style.display = "block";
                      document.getElementById("companyLoginForm").style.display = "none";
                      
                      setloaderButton(false);
                      setSuccesAlert(true);
                    //   setformPostValidate(false);
                    //   setTimeout(() => {
                    //       window.location.href = "/";
                    //   }, 5000);
                    }).catch(function (error) {
                        console.log(error)
                      if (error.response.status) {
                        console.log(error.response);
                        // document.getElementById("loginError").style.display = "block";
                        document.getElementById("loginSuccess").style.display = "none";
                        setloaderButton(false);
                      }
                    });
            }
            else{
                // document.getElementById("loginError").style.display="block";
                if(formData.brand == ""){
                    document.getElementById("brandsError").style.display = "block";
                    document.getElementById("brands").classList.add("invalid");
                }
                else{
                    document.getElementById("brandsError").style.display = "none";
                    document.getElementById("brands").classList.remove("invalid");
                }
                if(formData.fullName == ""){
                    document.getElementById("fullnameError").style.display = "block";
                    document.getElementById("fullname").classList.add("invalid");
                }
                else{
                    document.getElementById("fullnameError").style.display = "none";
                    document.getElementById("fullname").classList.remove("invalid");
                }
                if(formData.title == ""){
                    document.getElementById("titleError").style.display = "block";
                    document.getElementById("title").classList.add("invalid");
                }
                else{
                    document.getElementById("titleError").style.display = "none";
                    document.getElementById("title").classList.remove("invalid");
                }
                if(formData.mail == ""){
                    document.getElementById("mailError").style.display = "block";
                    document.getElementById("mail").classList.add("invalid");
                }
                else{
                    document.getElementById("mailError").style.display = "none";
                    document.getElementById("mail").classList.remove("invalid");
                }
                if(formData.phone == ""){
                    document.getElementById("phoneError").style.display = "block";
                    document.getElementById("phone").classList.add("invalid");
                }
                else{
                    document.getElementById("phoneError").style.display = "none";
                    document.getElementById("phone").classList.remove("invalid");
                }
                if(formData.address == ""){
                    document.getElementById("addressError").style.display = "block";
                    document.getElementById("address").classList.add("invalid");
                }
                else{
                    document.getElementById("addressError").style.display = "none";
                    document.getElementById("address").classList.remove("invalid");
                }
                if(formData.files.length == 0){
                    document.getElementById("brandFileError").style.display = "block";
                    document.getElementById("brandFile").classList.add("invalid");
                }
                else{
                    document.getElementById("brandFileError").style.display = "none";
                    document.getElementById("brandFile").classList.remove("invalid");
                }
                // if(formData.username == ""){
                //     document.getElementById("nameError").style.display = "block";
                //     document.getElementById("name").classList.add("invalid");
                // }
                // else{
                //     document.getElementById("nameError").style.display = "none";
                //     document.getElementById("name").classList.remove("invalid");
                // }
                // if(formData.password == ""){
                //     document.getElementById("passwordError").style.display = "block";
                //     document.getElementById("password").classList.add("invalid");
                // }
                // else{
                //     document.getElementById("passwordError").style.display = "none";
                //     document.getElementById("password").classList.remove("invalid");
                // }
            }
            setloaderButton(false);

    }
    const changeHandleMail = (event) => {
        setformData({  ...formData, mail: event.target.value });
        if(event.target.value == ""){
            document.getElementById("mailError").style.display = "block";
            document.getElementById("mail").classList.add("invalid");
        }
        else{
            document.getElementById("mailError").style.display = "none";
            document.getElementById("mail").classList.remove("invalid");
        }
    }
    const changeHandlePassword = (event) => {
        setformData({  ...formData, password: event.target.value });
        if(event.target.value == ""){
            document.getElementById("passwordError").style.display = "block";
            document.getElementById("password").classList.add("invalid");
        }
        else{
            document.getElementById("passwordError").style.display = "none";
            document.getElementById("password").classList.remove("invalid");
        }
    }
    const changeHandleUsername = (event) => {
        setformData({  ...formData, username: event.target.value });
        if(event.target.value == ""){
            document.getElementById("nameError").style.display = "block";
            document.getElementById("name").classList.add("invalid");
        }
        else{
            document.getElementById("nameError").style.display = "none";
            document.getElementById("name").classList.remove("invalid");
        }
    }
    const changeHandleBrands = (event) => {
        setformData({  ...formData, brand: event.target.value });
        if(event.target.value == ""){
            document.getElementById("brandsError").style.display = "block";
            document.getElementById("brands").classList.add("invalid");
        }
        else{
            document.getElementById("brandsError").style.display = "none";
            document.getElementById("brands").classList.remove("invalid");
        }
    }    
    const changeHandlePhone = (event) => {
        setformData({  ...formData, phoneNumber: event.target.value.replace(/\D/g, "") });
        if(event.target.value != ""){
            document.getElementById("phoneError").style.display = "none";
            document.getElementById("phone").classList.remove("invalid");
        }
        else{
            document.getElementById("phoneError").style.display = "block";
            document.getElementById("phone").classList.add("invalid");
        }
        console.log(event.target.value.replace(/\D/g, ""));
    }
    const changeHandleTitle = (event) => {
        setformData({  ...formData, title: event.target.value });
        if(event.target.value == ""){
            document.getElementById("titleError").style.display = "block";
            document.getElementById("title").classList.add("invalid");
        }
        else{
            document.getElementById("titleError").style.display = "none";
            document.getElementById("title").classList.remove("invalid");
        }
    } 
    const changeHandleFullname = (event) => {
        setformData({  ...formData, fullName: event.target.value });
        if(event.target.value == ""){
            document.getElementById("fullnameError").style.display = "block";
            document.getElementById("fullname").classList.add("invalid");
        }
        else{
            document.getElementById("fullnameError").style.display = "none";
            document.getElementById("fullname").classList.remove("invalid");
        }
    } 
    const changeHandleAddress = (event) => {
        setformData({  ...formData, address: event.target.value });
        if(event.target.value == ""){
            document.getElementById("addressError").style.display = "block";
            document.getElementById("address").classList.add("invalid");
        }
        else{
            document.getElementById("addressError").style.display = "none";
            document.getElementById("address").classList.remove("invalid");
        }
        
    }  
    const changeHandleFiles = (event) => {
        var filesData = [];
        var fileSize= 0;
        for(var i= 0 ; i<event.target.files.length; i++  ){
            filesData.push(event.target.files[i]);
            fileSize += event.target.files[i].size;
        }
        if(fileSize < 26214400){
            setformData({...formData, files: filesData});
            document.getElementById("fileSize").style.display = "none";
        }
        else{
            document.getElementById("fileSize").style.display = "block";
        }
        if(filesData.length == 0){
            document.getElementById("brandFileError").style.display = "block";
            document.getElementById("brandFile").classList.add("invalid");
        }
        else{
            document.getElementById("brandFileError").style.display = "none";
            document.getElementById("brandFile").classList.remove("invalid");
        }
    }

    const HideToolTip = () => {
        document.getElementById("registerTip").classList.remove("show");
    }
    const ShowToolTip = () => {
        document.getElementById("registerTip").classList.add("show");
    }
    
    return (
        <Container className='login-container'>
            <Row>
                <Col  className='login-container__slider-box col-12 col-md-6 pe-0 pe-md-2 mb-5 mb-md-0'>
                    <SwiperSlider />
                </Col>
                <Col className='login-container__login-box  col-12 col-md-6 mb-5 mb-md-0 flex-wrap position-relative'>
                    <Col className='login-container__login-box__wrapper col-12'>
                        <h1 className='login-container__login-box__wrapper__title'>Fiyat Yayınla</h1>
                        <Col xs={12} className='login-container__login-box__wrapper__form-box'>
                            <Form id="companyLoginForm" className={(formPostValidate? 'd-block' : 'd-none') +' login-container__login-box__wrapper__form-box__form'}  noValidate validated={validated}>
                                <Form.Control.Feedback id="brandsError" type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                                <FloatingLabel controlId="brands" label="Markalar" className="login-container__login-box__wrapper__form-box__form__name mb-3">
                                    <Form.Control type="text" placeholder="Markalar" onChange={changeHandleBrands}  required/>
                                </FloatingLabel>
                                <Form.Control.Feedback id="fullnameError" type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                                <FloatingLabel controlId="fullname" label="Adınız Soyadınız" className="login-container__login-box__wrapper__form-box__form__name mb-3">
                                    <Form.Control type="text" placeholder="Adınız Soyadınız" onChange={changeHandleFullname}  required/>
                                </FloatingLabel> 
                                <Form.Control.Feedback id="titleError" type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>                               
                                <FloatingLabel controlId="title" label="Ünvanınız" className="login-container__login-box__wrapper__form-box__form__name mb-3">
                                    <Form.Control type="text" placeholder="Ünvanınız" onChange={changeHandleTitle}  required/>
                                </FloatingLabel>    
                                <Form.Control.Feedback id="mailError" type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>                             
                                <FloatingLabel controlId="mail" label="Mail Adresi" className="login-container__login-box__wrapper__form-box__form__mail mb-3">
                                    <Form.Control type="email" onChange={changeHandleMail} placeholder="name@example.com" required/>
                                </FloatingLabel>
                                <Form.Control.Feedback id="phoneError" type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>                                 
                                <FloatingLabel controlId="phone" label="Telefon Numarası" className="login-container__login-box__wrapper__form-box__form__mail mb-3">
                                    {/* <Form.Control type="tel" onChange={changeHandlePhone} placeholder="053* *** ** **" required/> */}
                                    <MaskedInput id="phone" className='form-control' type='text' name='phoneNumber' onChange={(e) => changeHandlePhone(e)} mask='0(111)-111-1111' />
                                </FloatingLabel>
                                <Form.Control.Feedback id="addressError" type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback> 
                                <FloatingLabel controlId="address" label="İletişim Adresi" className="login-container__login-box__wrapper__form-box__form__name mb-3">
                                    <Form.Control as="textarea" placeholder="İletişim Adresi" onChange={changeHandleAddress}  required/>
                                </FloatingLabel>
                                <Form.Control.Feedback id="brandFileError" type="invalid">
                                    Lütfen Dosyalarınızı Yükleyiniz.
                                </Form.Control.Feedback> 
                                <Form.Group controlId="brandFile" className="login-container__login-box__wrapper__form-box__form__name mb-3">
                                    <Form.Label  className='d-flex align-items-center'>
                                        <span className='pe-2'>Marka Belgelerinizi Yükleyiniz.</span>
                                        
                                            <svg data-tip data-for="registerTip" onMouseOut={HideToolTip} onMouseOver={ShowToolTip} xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="12" height="12">
                                                <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"/>
                                                <path d="M12,10H11a1,1,0,0,0,0,2h1v6a1,1,0,0,0,2,0V12A2,2,0,0,0,12,10Z"/>
                                                <circle cx="12" cy="6.5" r="1.5"/>
                                            </svg>
                                    </Form.Label>
                                    <Form.Control type="file" multiple
                                        onChange={changeHandleFiles} size="md" />
                                    <Form.Control.Feedback id="fileSize" type="invalid">
                                        Lütfen Yüklediğiniz Dosyaların 25 MB 'dan daha küçük olduğundan emin olunuz.
                                    </Form.Control.Feedback>
                                    <p className="brand-add-desc">Lütfen platformumuzda fiyat sağlayıcısı olmak istediğiniz
                                    markalar hakkında marka üreticilerinden sağlayacağınız 
                                    Marka Satış yetki belgeniz, distribütör vb. gibi fiyat 
                                    vermeye yetkili olduğunuzu kanıtlayan bir belge yükleyiniz.</p>
                                </Form.Group>  
                                {/* <Form.Control.Feedback id="nameError" type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                                <FloatingLabel controlId="name" label="Kullanıcı Adınız" className="login-container__login-box__wrapper__form-box__form__name mb-3">
                                    <Form.Control type="text" placeholder="Kullanıcı Adınız" onChange={changeHandleUsername}  required/>
                                </FloatingLabel>  
                                <Form.Control.Feedback id="passwordError" type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>                                                                                                                          
                                <FloatingLabel controlId="password" label="Şifre" className="login-container__login-box__wrapper__form-box__form__password">
                                    <Form.Control type="password" onChange={changeHandlePassword} placeholder="name@example.com" required/>
                                    <Form.Control.Feedback id="loginError" type="invalid">
                                        Lütfen Yukarıdaki Tüm Alanları Doldurunuz.
                                    </Form.Control.Feedback>
                                </FloatingLabel> */}
                                <Button className='login-container__login-box__wrapper__form-box__form__submit-button col-12 mb-3' variant="primary" onClick={SignupRequest}>
                                    <span className='pe-2'>Talep Gönder</span>
                                    <span className={"spinner-border spinner-border-sm ps-2 ms-2" + (loaderButton ? " d-block" : " d-none") } role="status" aria-hidden="true"></span>
                                </Button>                                                        
                            </Form>
                            <Form.Control.Feedback id="loginSuccess" type="valid">
                                Üyelik Başvurunuz Onaylanmak Üzere İletilmiştir. Devam Etmek İçin <a href='/' className='text-success'><strong>Buraya Tıklayınız</strong></a>
                            </Form.Control.Feedback>                            
                        </Col>
                    </Col>
                    <Col className='login-container__login-box__bottom col-12 bottom-0 end-0 mb-5 d-flex align-items-center justify-content-center'>
                        <div className='login-container__login-box__bottom__wrapper d-flex flex-column'>
                            <div className='login-container__login-box__bottom__wrapper__listfix-info-box d-flex justify-content-center flex-column'>
                                <a onClick={handleShow} className='login-container__login-box__bottom__wrapper__listfix-info-box__info-button btn btn-link'>
                                    List Fix Nedir ?
                                </a>
                                <div className='login-container__login-box__bottom__wrapper__listfix-info-box__signup-box d-flex align-items-center'>
                                    <p className='login-container__login-box__bottom__wrapper__listfix-info-box__signup-box__text m-0 pe-2'>Hesabın var mı ? /</p>
                                    <a href='/' className='login-container__login-box__bottom__wrapper__listfix-info-box__signup-box__link btn-link'>
                                        Giriş Yap
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>List Fix Nedir ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere urna pulvinar, hendrerit metus sit amet, tempus nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc nulla eros, ornare ut mi vehicula, placerat pellentesque purus. Vivamus vehicula porta nisl sit amet luctus. Nunc sollicitudin non tortor vitae volutpat. Vestibulum sit amet egestas velit, tristique pretium ipsum. Proin quis erat sodales, viverra mauris blandit, scelerisque dui. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam a risus ut ipsum laoreet rhoncus vel ac arcu. Morbi vel ornare erat. Nullam tincidunt massa ac felis efficitur imperdiet. Cras placerat dapibus pellentesque. Morbi luctus lacinia egestas. Vivamus finibus diam eget cursus volutpat. Nunc interdum tellus non tellus consectetur, id posuere metus convallis. Donec eu neque nibh.
                </Modal.Body>
            </Modal>  
            <Toast onClose={() => setSuccesAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={SuccesAlert} delay={6000} autohide>
            <Toast.Body>Üyelik Başvurunuz Onaylanmak Üzere İletilmiştir. Devam Etmek İçin <a href='/' className='text-white'><strong>Buraya Tıklayınız</strong></a></Toast.Body>
        </Toast>
        <ReactTooltip className='tooltip' id="registerTip" type='success' place="right" effect="solid" autohide>
                                    Lütfen platformumuzda fiyat sağlayıcısı olmak istediğiniz
                                    markalar hakkında marka üreticilerinden sağlayacağınız 
                                    Marka Satış yetki belgeniz, distribütör vb. gibi fiyat 
                                    vermeye yetkili olduğunuzu kanıtlayan bir belge yükleyiniz.</ReactTooltip>
        </Container>
    );
}

export default CompanySignUp;