import React, {useRef,useState} from "react";
import "./CompanyRow.scss";

function SelectRole (props)  {
    const role = props.role;
    const active = props.active;
    return(
            <option value={role.id} selected={active}>{role.name}</option>
        )
}

export default SelectRole;