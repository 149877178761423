import React from "react";
import { Container, Row,Col } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import UserList from "../../components/CompanyList/UserList";
import FooterResult from "../Shared/FooterResult";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
function Users (props)  {
  // const user = JSON.parse(localStorage["users"]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [cities, setCities] = useState([]);
  const loginUser = props.loginUser;
  // const [loginUser , setLoginUser] = useState({
  //   "UserRoles" : [
  //     {
  //       "Role" : {
  //         "Name" : "Unauth"
  //       }
  //     }
  //   ]
  // });
  // useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: "https://www.listefix.com/api/Authenticate/GetUserInfo",
  //     headers: {
  //       Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //     }
  //   }).then(function (response) {
      
  //     const user = response?.data?.user;
  //     setLoginUser(user);
  //     if(searchParams.toString()){
  //       axios({
  //         method: "get",
  //         url: "https://www.listefix.com/api/User/Filter?" + searchParams.toString(),
  //         headers: {
  //           Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //         }
  //       }).then(function (response) {
            
            
  //           setUser(response.data.Data);
  //           setLoading(false);
  //       }).catch(function (error) {
  //             console.log(error.response);
  //       });
  //     }
  //     else{
  //       if(loginUser.UserRoles[0].Role.Name === "Admin"){
  //         axios({
  //           method: "get",
  //           url: "https://www.listefix.com/api/User/Filter?RoleId=4",
  //           headers: {
  //             Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //           }
  //         }).then(function (response) {
              
              
  //             setUser(response.data.Data);
  //             setLoading(false);
  //         }).catch(function (error) {
  //               console.log(error.response);
  //         });
  //       }
  //       else if(loginUser.UserRoles[0].Role.Name === "Distributor") {
  //         axios({
  //           method: "get",
  //           url: "https://www.listefix.com/api/User/MySlaves?pageIndex=1&pageSize=20",
  //           headers: {
  //             Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //           }
  //         }).then(function (response) {
              
              
  //             setUser(response.data.Data);
  //             setLoading(false);
  //         }).catch(function (error) {
  //               console.log(error.response);
  //         });
  //       }
  //     }      

  //   }).catch(function (error) {
  //     setLoginUser({
  //       "UserRoles" : [
  //         {
  //           "Role" : {
  //             "Name" : "Unauth"
  //           }
  //         }
  //       ]
  //     });

  //   });
  // }, []);


  // useEffect(() => {
  //   if(searchParams.toString()){
  //     axios({
  //       method: "get",
  //       url: "https://www.listefix.com/api/User/Filter?" + searchParams.toString(),
  //       headers: {
  //         Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //       }
  //     }).then(function (response) {
          
          
  //         setUser(response.data.Data);
  //         setLoading(false);
  //     }).catch(function (error) {
  //           console.log(error.response);
  //     });
  //   }
  //   else{
  //     if(loginUser.UserRoles[0].Role.Name === "Admin"){
  //       axios({
  //         method: "get",
  //         url: "https://www.listefix.com/api/User/Filter?RoleId=4",
  //         headers: {
  //           Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //         }
  //       }).then(function (response) {
            
            
  //           setUser(response.data.Data);
  //           setLoading(false);
  //       }).catch(function (error) {
  //             console.log(error.response);
  //       });
  //     }
  //     else if(loginUser.UserRoles[0].Role.Name === "Distributor") {
  //       axios({
  //         method: "get",
  //         url: "https://www.listefix.com/api/User/MySlaves?pageIndex=1&pageSize=20",
  //         headers: {
  //           Authorization: "Bearer "+localStorage.getItem("listfixToken")
  //         }
  //       }).then(function (response) {
            
            
  //           setUser(response.data.Data);
  //           setLoading(false);
  //       }).catch(function (error) {
  //             console.log(error.response);
  //       });
  //     }
  //   }
  // },[]);



  useEffect(() => {
    if(searchParams.toString()){
      axios({
        method: "get",
        url: "https://www.listefix.com/api/User/Filter?" + searchParams.toString(),
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          
          
          setUser(response.data.Data);
          setLoading(false);
      }).catch(function (error) {
            console.log(error.response);
      });
    }
    else{
      if(loginUser.UserRoles[0].Role.Name === "Admin"){
        axios({
          method: "get",
          url: "https://www.listefix.com/api/User/Filter?RoleId=4",
          headers: {
            Authorization: "Bearer "+localStorage.getItem("listfixToken")
          }
        }).then(function (response) {
            setUser(response.data.Data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error.response);
        });
      }
      else if(loginUser.UserRoles[0].Role.Name === "Distributor") {
        axios({
          method: "get",
          url: "https://www.listefix.com/api/User/MySlaves?pageIndex=1&pageSize=20",
          headers: {
            Authorization: "Bearer "+localStorage.getItem("listfixToken")
          }
        }).then(function (response) {
            setUser(response.data.Data);
            setLoading(false);
        }).catch(function (error) {
              console.log(error.response);
        });
      }
    }

},[])
useEffect(() => {
  axios({
    method: "get",
    url: "https://www.listefix.com/api/Location/GetCities",
    headers: {
      Authorization: "Bearer "+localStorage.getItem("listfixToken")
    }
  }).then(function (response) {
    setCities(response.data);
  }).catch(function (error) {
    console.log(error.response);
  });
},[]);

  if(loading){
    return (
      <div></div>
    )
  }
  else{
    return (
      <div>
        <HeaderPanel loginUser={loginUser} />
        <Container>
          <h1>Üyeler</h1>
          <Row>
            <Col className="col-12">
              <UserList loginUser={loginUser} user={user} cities={cities}></UserList>
            </Col>
          </Row>
        </Container>
        <FooterResult></FooterResult>
      </div>
    );
  }

}
export default Users;