import React,{useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import SearchTableAdmin from "../../components/SearchTable/SearchTableAdmin";
import FooterResult from "../Shared/FooterResult";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
function Permission (props)  {
  const [tableActive, setTableActive] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderParams, setOrderParams] = useSearchParams();
  const [campaingParams, setcampaingParams] = useSearchParams();
  searchParams.get("SearchKey");
  orderParams.get("Order");
  campaingParams.get("Campaing");

  const [productFilter, setProductFilter] = useState(searchParams.toString().split("&")[0].replace("SearchKey=",""));
  const [filteredProducts, setfilteredProducts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const loginUser = props.loginUser;

  
  function activateTable(stateParam) {
    setTableActive(stateParam);
  }

  function searchProduct() {
    axios({
      method: "get",
      url: "https://www.listefix.com/api/Product/Search?" + searchParams.toString(),
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
    }).then(function (response) {
      console.log(response)
        setfilteredProducts(response.data.Data.Data.filter(t=> t.Id !== 0));
      }).catch(function (error) {
        console.log(error.response);
      });
  }
  useEffect(() => {
    searchProduct(productFilter);
    axios({
      method: "get",
      url: "https://www.listefix.com/api/User/Distributors",
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
      }).then(function (response) {
          setCompanies(response.data.data.Data);
        }).catch(function (error) {
          console.log(error.response);
      });
  }, []);
  // console.log(filteredProducts);
  return (
    <div className="panel-product-list">
      <HeaderPanel loginUser={loginUser} />
      <Container className="search-container-panel">
        <SearchTableAdmin loginUser={loginUser} products={filteredProducts} tableActive={true} companies={companies}></SearchTableAdmin>
      </Container>
      <FooterResult></FooterResult>
    </div>
  );
}
export default Permission;