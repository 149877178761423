import React , {useState} from "react";
import { Container ,Modal,Row,FloatingLabel,Form,Toast,Button,Col } from "react-bootstrap";
import MaskedInput from 'react-maskedinput';
import axios from "axios";
import CompanySignUpFooter from "../Login/CompanySignUpFooter";

function FooterResult () {
    const [showAlert, setShowAlert] = useState(false);
    const [show, setShow] = useState(false);
    const [showKVKK, setShowKVKK] = useState(false);
    const [showAbout, setAbout] = useState(false);
    const [showSuggest, setshowSuggest] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showPublish, setshowPublish] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseKVKK = () => setShowKVKK(false);
    const handleShowKVKK = () => setShowKVKK(true);
    const handleCloseAbout = () => setAbout(false);
    const handleShowAbout = () => setAbout(true);
    const handleCloseSuggest = () => setshowSuggest(false);
    const handleShowSuggest = () => setshowSuggest(true);
    const handleClosePublish = () => setshowPublish(false);
    const handleShowPublish = () => setshowPublish(true);
    const handleCloseWithMessage = (event) => {
        const form = document.getElementById("suggestForm");
        let mailBody = "<p>Adı Soyadı :"+ document.getElementById("suggestName").value +"</p>";
        mailBody += "<p>Şirket Adı :"+ document.getElementById("suggestCompany").value +"</p>";
        mailBody += "<p>Mail Adresi :"+ document.getElementById("suggestMail").value +"</p>";
        mailBody += "<p>Telefon Numarası :"+ document.getElementById("suggestTel").value +"</p>";

        let mailData = {
            "To" : document.getElementById("suggestMail").value,
            "subject" : "Arkadaşına Öner",
            "body" : mailBody
        };
        var data = new FormData();
        data.append( "To", mailData.To);
        data.append("subject", mailData.subject);
        data.append("body", mailData.body);
        if (form.checkValidity() === true) {
            axios({
                method: "post",
                url: "https://www.listefix.com/api/Email/Post",
                data: data
                }).then(function (response) {
                    
                    handleCloseSuggest();
                    setShowAlert(true);
                    
                }).catch(function (error) {
                    console.log(error);
                });
        }
    
        setValidated(true);

        
    }
    
    return(
        <Container className=" bottom-0 start-0 p-2 position-fixed footer" fluid={true}>
            <Row>
            <div className="d-flex col-12 justify-content-between">
                <div className="left-footer-menu col-4">
                    <div className="footer-menu-list d-flex gap-3">
                        <a className="header-container__account-box__account__text text-dark text-decoration-none ps-1" role="button" onClick={handleShow}>&#8226; Gizlilik Sözleşmesi</a>
                        <a className="header-container__account-box__account__text text-dark text-decoration-none ps-1" role="button" onClick={handleShowKVKK}>&#8226; Kişisel Verilerin Korunması</a>
                        <a className="header-container__account-box__account__text text-dark text-decoration-none ps-1" role="button" onClick={handleShowAbout}>&#8226; Hakkımızda</a>
                    </div>
                </div>
                <div className="center-footer-menu col-4">
                    <div className="footer-menu-list d-flex gap-2 justify-content-center">
                        <span className="footer-corporate">ListFix Tüm Hakları Saklıdır 2022</span>
                    </div>
                </div>
                <div className="right-footer-menu col-4">
                    <div className="footer-menu-list d-flex gap-4 justify-content-end">
                    
                        <Col className='header-container__account-box col-md-auto d-flex align-items-center justify-content-start gap-1'>
                            <div  className='header-container__account-box__account d-flex align-items-center text-decoration-none'>
                                <strong className="header-container__account-box__account__text text-decoration-none ps-1">Bize Ulaşın</strong>
                            </div> 
                            <a href="tel:+908500000000" className='header-container__account-box__account d-flex align-items-center text-decoration-none'>
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.0752 16.7619C23.6547 17.3431 23.9801 18.1303 23.9801 18.9509C23.9801 19.7716 23.6547 20.5588 23.0752 21.1399L22.1632 22.1899C13.9722 30.0279 -5.95582 10.1059 1.76318 1.88994L2.91318 0.889938C3.49482 0.326484 4.27491 0.0146773 5.08468 0.0219759C5.89445 0.0292745 6.66879 0.355092 7.24018 0.928938C7.27118 0.958938 9.12318 3.36694 9.12318 3.36694C9.6711 3.94548 9.97597 4.71231 9.97485 5.50914C9.97374 6.30596 9.66672 7.07193 9.11718 7.64894L7.95918 9.10494C8.60017 10.662 9.54244 12.077 10.7319 13.2689C11.9213 14.4607 13.3345 15.4058 14.8902 16.0499L16.3552 14.8849C16.931 14.3339 17.697 14.0259 18.494 14.0248C19.291 14.0237 20.0578 14.3296 20.6352 14.8789C20.6352 14.8789 23.0442 16.7319 23.0752 16.7619ZM21.7002 18.2159C21.7002 18.2159 19.3072 16.3739 19.2762 16.3439C19.0702 16.1397 18.7918 16.0251 18.5017 16.0251C18.2116 16.0251 17.9332 16.1397 17.7272 16.3439C17.7002 16.3699 15.6832 17.9779 15.6832 17.9779C15.5473 18.0861 15.3855 18.157 15.2138 18.1837C15.0422 18.2104 14.8665 18.1918 14.7042 18.1299C12.6876 17.3797 10.8559 16.2046 9.33327 14.6844C7.81064 13.1642 6.63265 11.3343 5.87918 9.31894C5.81238 9.15439 5.7906 8.97503 5.81609 8.79928C5.84157 8.62353 5.9134 8.45773 6.02418 8.31894C6.02418 8.31894 7.63218 6.30494 7.65918 6.27894C7.86345 6.07292 7.97806 5.79455 7.97806 5.50444C7.97806 5.21432 7.86345 4.93595 7.65918 4.72994C7.62918 4.69994 5.78718 2.30494 5.78718 2.30494C5.57808 2.11745 5.30519 2.01703 5.02443 2.02429C4.74367 2.03154 4.47633 2.1459 4.27718 2.34394L3.12718 3.34394C-2.51482 10.1269 14.7572 26.4409 20.7002 20.8269L21.6122 19.7759C21.8272 19.5786 21.9567 19.3052 21.9731 19.0138C21.9896 18.7225 21.8916 18.4362 21.7002 18.2159Z" fill="#374957"/>
                                </svg>
                            </a>                                          
                            <a href="mailto:info@listfix.com" className='header-container__logout-box__logout d-flex align-items-center text-decoration-none'>
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 1.00024H3C2.20435 1.00024 1.44129 1.31631 0.87868 1.87892C0.31607 2.44153 0 3.20459 0 4.00024L0 23.0002H24V4.00024C24 3.20459 23.6839 2.44153 23.1213 1.87892C22.5587 1.31631 21.7956 1.00024 21 1.00024ZM3 3.00024H21C21.2652 3.00024 21.5196 3.1056 21.7071 3.29314C21.8946 3.48067 22 3.73503 22 4.00024V4.66724L14.122 12.5462C13.5584 13.1076 12.7954 13.4227 12 13.4227C11.2046 13.4227 10.4416 13.1076 9.878 12.5462L2 4.66724V4.00024C2 3.73503 2.10536 3.48067 2.29289 3.29314C2.48043 3.1056 2.73478 3.00024 3 3.00024ZM2 21.0002V7.50024L8.464 13.9602C9.40263 14.8965 10.6743 15.4223 12 15.4223C13.3257 15.4223 14.5974 14.8965 15.536 13.9602L22 7.50024V21.0002H2Z" fill="#374957"/>
                                </svg>
                            </a>
                        </Col>               
                        <strong role="button" className="text-success" onClick={handleShowPublish}>Fiyat Yayınla</strong>         
                        <strong role="button" className="text-main" onClick={handleShowSuggest}>Arkadaşına Tavsiye Et</strong>
                    </div>
                </div>
            </div>
            </Row>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Gizlilik</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere urna pulvinar, hendrerit metus sit amet, tempus nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc nulla eros, ornare ut mi vehicula, placerat pellentesque purus. Vivamus vehicula porta nisl sit amet luctus. Nunc sollicitudin non tortor vitae volutpat. Vestibulum sit amet egestas velit, tristique pretium ipsum. Proin quis erat sodales, viverra mauris blandit, scelerisque dui. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam a risus ut ipsum laoreet rhoncus vel ac arcu. Morbi vel ornare erat. Nullam tincidunt massa ac felis efficitur imperdiet. Cras placerat dapibus pellentesque. Morbi luctus lacinia egestas. Vivamus finibus diam eget cursus volutpat. Nunc interdum tellus non tellus consectetur, id posuere metus convallis. Donec eu neque nibh.
                </Modal.Body>
            </Modal>
            <Modal show={showKVKK} onHide={handleCloseKVKK}>
                <Modal.Header closeButton>
                    <Modal.Title>Kullanım Koşulları</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere urna pulvinar, hendrerit metus sit amet, tempus nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc nulla eros, ornare ut mi vehicula, placerat pellentesque purus. Vivamus vehicula porta nisl sit amet luctus. Nunc sollicitudin non tortor vitae volutpat. Vestibulum sit amet egestas velit, tristique pretium ipsum. Proin quis erat sodales, viverra mauris blandit, scelerisque dui. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam a risus ut ipsum laoreet rhoncus vel ac arcu. Morbi vel ornare erat. Nullam tincidunt massa ac felis efficitur imperdiet. Cras placerat dapibus pellentesque. Morbi luctus lacinia egestas. Vivamus finibus diam eget cursus volutpat. Nunc interdum tellus non tellus consectetur, id posuere metus convallis. Donec eu neque nibh.
                </Modal.Body>
            </Modal>
            <Modal show={showAbout} onHide={handleCloseAbout}>
                <Modal.Header closeButton>
                    <Modal.Title>Hakkımızda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere urna pulvinar, hendrerit metus sit amet, tempus nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc nulla eros, ornare ut mi vehicula, placerat pellentesque purus. Vivamus vehicula porta nisl sit amet luctus. Nunc sollicitudin non tortor vitae volutpat. Vestibulum sit amet egestas velit, tristique pretium ipsum. Proin quis erat sodales, viverra mauris blandit, scelerisque dui. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam a risus ut ipsum laoreet rhoncus vel ac arcu. Morbi vel ornare erat. Nullam tincidunt massa ac felis efficitur imperdiet. Cras placerat dapibus pellentesque. Morbi luctus lacinia egestas. Vivamus finibus diam eget cursus volutpat. Nunc interdum tellus non tellus consectetur, id posuere metus convallis. Donec eu neque nibh.
                </Modal.Body>
            </Modal>                         
            <Modal  show={showSuggest} onHide={handleCloseSuggest}>
                    <Modal.Header closeButton>
                    <Modal.Title>Arkadaşına Tavsiye Et</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>ListFixi Önermek İstediğiniz Arkadaşınızın İletişim Bilgilerini Girin Size Ulaşalım</p>
                        <Form id="suggestForm" validated={validated}>
                            <FloatingLabel controlId="suggestName" label="Adı Soyadı"  className="mb-2" >
                                <Form.Control required type="text" placeholder="Adı Soyadı"/>
                                <Form.Control.Feedback type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel controlId="suggestCompany" label="Şirket Adı" className="mb-2" >
                                <Form.Control required type="tel" placeholder="Şirket Adı"/>
                                <Form.Control.Feedback type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel controlId="suggestMail" label="Mail Adresi" className="mb-2" >
                                <Form.Control required type="email" placeholder="Mail Adresi"/>
                                <Form.Control.Feedback type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                            </FloatingLabel> 
                            <FloatingLabel controlId="suggestTel" label="Telefon Numarası" className="mb-2" >
                                <MaskedInput required id="suggestTel" className='form-control' type='tel' name='Phone' mask='1111  111  1111' />
                                <Form.Control.Feedback type="invalid">
                                    Bu Alan Boş Bırakılamaz
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Group className="mb-3" controlId="suggestKVKK">
                                <Form.Check  required type="checkbox" label="İletişim verilerimin kullanılmasına rıza gösteriyorum." />
                                <Form.Control.Feedback type="invalid">
                                    Bu Alanı İşaretlemeniz Gerekmektedir.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="success" className="float-end" onClick={handleCloseWithMessage}>
                                Gönder
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
                    <Toast.Body>Bilgiler Tarafımıza Ulaşmıştır En Kısa Sürede İletişime Geçeceğiz</Toast.Body>
                </Toast>
                <Modal  show={showPublish} onHide={handleClosePublish}>
                    <Modal.Header closeButton>
                    <Modal.Title>Sen De Fiyat Yayınla</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CompanySignUpFooter />
                    </Modal.Body>
            </Modal>
        </Container>
    )
}

export default FooterResult