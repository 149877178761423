import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import SearchTable from "../../components/SearchTable/SearchTable";
import CompanyList from "../../components/CompanyList/CompanyList";
import "./Panel.scss";
import FooterResult from "../Shared/FooterResult";
import UserList from "../../components/CompanyList/UserList";
import { useState, useEffect } from "react";
import axios from "axios";

function Panel (props)  {
  // const company = JSON.parse(localStorage["companies"]);
  const loginUser = props.loginUser;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCom, setLoadingCom] = useState(true);
  const [company, setCompany] = useState([]);
  const generateMenu = () => {
    if(loginUser.UserRoles[0].Role.Name === "Admin" ){
        return[
            <a className="panel-home-page__links text-decoration-none text-center" href="/">Siteye Dön</a>,
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel">Panel</a>,
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/excel">Excel Yükle</a>,
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/permission?SearchKey=&PageIndex=1">Listelenecek Ürünler İzinleri</a>,
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/company?PageIndex=1">Firmalar</a>,      
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/users?PageIndex=1">Üyeler</a>, 
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/markalar">Marka Ekle</a>, 
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/bayi-bul">Bayi Bul</a>,
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/ayarlar">Ayarlar</a>
        ]
    }
    else if( loginUser.UserRoles[0].Role.Name === "Distributor") {
        return[
            <a className="panel-home-page__links text-decoration-none text-center" href="/">Siteye Dön</a>,
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel">Panel</a>,
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/excel">Excel Yükle</a>,   
            <a className="panel-home-page__links text-decoration-none text-center" href= {"/panel/permission?SearchKey=&PageIndex=1&DistributorId="+loginUser.Id}>Listelenecek Ürünler İzinleri</a>,   
            <a className="panel-home-page__links text-decoration-none text-center" href="/panel/users?PageIndex=1">Üyeler</a>
        ]
    }

  }
  return (
    <div>
      <HeaderPanel loginUser={loginUser} />
      <Container className="pt-5" fluid={true}>
        <Row className="gap-5  dashboard ">
          {/* <Col xs={12} className="panel-widget-list dashboard-users">
            <h3 className="widget-title">Son Üye Olan Kullanıcılar Listesi</h3>            
            {putUserList()}
          </Col>
          <Col xs={12} className="panel-widget-list dashboard-company">
            <h3 className="widget-title">Firma Listesi</h3>
            {putCompanyList()}
          </Col>           */}
          <Col  xs={12}>
            <div className="col-12 panel-home-page">
              {generateMenu()}
            </div>
          </Col>
        </Row>
      </Container>
      <FooterResult></FooterResult>
    </div>
  );
}
export default Panel;