import React,{useEffect, useState} from "react";
import { useSearchParams } from "react-router-dom";
import { Container,Col,Table } from "react-bootstrap";
import HeaderResult from "../Shared/HeaderResult";
import SearchTable from "../../components/SearchTable/SearchTable";
import "./Search.scss";
import FooterResult from "../Shared/FooterResult";
import axios from "axios";

function SearchResult  (props)  {
    const [tableActive, setTableActive] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [orderParams, setOrderParams] = useSearchParams();
    const [campaingParams, setcampaingParams] = useSearchParams();
    const loginUser = props.loginUser;
    searchParams.get("SearchKey");
    orderParams.get("Order");
    campaingParams.get("Campaing");
  
    const [productFilter, setProductFilter] = useState(searchParams.toString().split("&")[0].replace("SearchKey=",""));
    const [filteredProducts, setfilteredProducts] = useState([]);

    
    function activateTable(stateParam) {
      setTableActive(stateParam);
    }

    function searchProduct(listFilter) {
      axios({
        method: "get",
        url: "https://www.listefix.com/api/Product/Search/?" + searchParams.toString(),
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          setfilteredProducts(response.data.Data.Data);
        }).catch(function (error) {
          console.log(error.response);
        });
      setProductFilter(listFilter);
    }
    useEffect(() => {
      searchProduct(productFilter);
      
    }, []);
  return (
    <div className="search-list">
      <HeaderResult tableActive={tableActive} activateTable={activateTable} searchProduct={searchProduct}/>
      <Container className="search-container">
        <SearchTable loginUser={loginUser} products={filteredProducts} tableActive={tableActive} activateTable={activateTable} filter={true}></SearchTable>
      </Container>
      <FooterResult/>
    </div>
  );
}
export default SearchResult;