import React ,{useState,useEffect} from "react";
import BrandRow from "./BrandRow";
import { Toast ,Form,FloatingLabel,Modal,Button} from "react-bootstrap";
import AddBrand from "./AddBrand";
import { useSearchParams } from "react-router-dom";
function UserList(props)  {
  const [SuccesAlertUser, setSuccesAlertUser] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false)};
  const handleShow = () => setShow(true);
  const brands = props.brands;
  const handleValueChange = (e) => {
  };
  const applyFilters = () => {
        
    var filterQuery = window.location.origin + window.location.pathname + "?" ;
    
    //brands
    
    var SearchKey = document.getElementById("SearchKey").value;
    if(SearchKey != "" && SearchKey != undefined && SearchKey != null){
        filterQuery += "&SearchKey=" + SearchKey;
    }
    
    window.location.href = filterQuery;
}
    return(
        <div className="table-responsive">
        
        <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
          <p><strong>{brands.length} Marka Listelenmekte</strong></p>          
          <button className="btn btn-success add-user" onClick={()=>handleShow()} variant="success">
              Yeni Marka Ekle
          </button>
        </div> 
        <div className="d-flex align-items-center pt-3 pb-3">
            <FloatingLabel controlId="SearchKey" label="Ara:" className="product-name-input-box me-4 filter-user-search" >
              <Form.Control className="product-search-input" type="tel" name="SearchKey" defaultValue={searchParams.get("SearchKey")} />
            </FloatingLabel>
            <Button className="me-4" variant="success" onClick={()=>applyFilters()}>
                Ara
            </Button>
        </div>     
        <table className="table table-hover table-middle table-divider align-middle">
          <thead>
            <tr>
              <th>Marka Adı</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand, index) =>
              <BrandRow key={index} index={index + 1} brand={brand} />
              )
            }
          </tbody>
        </table>
        <Toast onClose={() => setSuccesAlertUser(false)} className="position-fixed report-alert bg-success text-white text-center" show={SuccesAlertUser} delay={3000} autohide>
            <Toast.Body>Marka Eklenmiştir.</Toast.Body>
        </Toast>
        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Marka Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Yeni Marka Ekle</p>
                    <AddBrand closeModal = {handleClose} showMessage={setSuccesAlertUser}></AddBrand>                                                        
                </Modal.Body>
        </Modal>       
      </div>
          )
}

export default UserList;