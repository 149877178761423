import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from "swiper/core";
import { Virtual } from 'swiper';
import "./SwiperSlide.scss";
import 'swiper/css';
import 'swiper/css/virtual';
import { useEffect } from 'react';
SwiperCore.use([Pagination]);
function SwiperSliderProduct (props) {
    const images = props.images;
    return (
        <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={{
            clickable: true
          }}
        centeredSlides={true}
        className='swiper-slider swiper-slider-product'>
            <SwiperSlide>
                <img src={images[0]} alt="" className='image col-12'/>
            </SwiperSlide>
            {images.length >= 2 ?
                <SwiperSlide>
                    <img src={images[1]} alt="" className='image col-12'/>
                </SwiperSlide> :
                <div></div>
            }
            {images.length >= 3 ?
                <SwiperSlide>
                    <img src={images[2]} alt="" className='image col-12'/>
                </SwiperSlide> :
                <div></div>
            }
            

      </Swiper>
    )
}
export default SwiperSliderProduct;