import React, {useEffect, useState} from "react";
import { Col, Container,Toast,Form ,FloatingLabel,Button} from 'react-bootstrap';
import "./SearchBar.scss";


function SearchBar (props)  {

    const tableActive = props.tableActive;
    const activateTable=props.activateTable;
    const searchProduct = props.searchProduct;
    const [searchWord,setsearchWord] = useState("");   
    const [showAlert, setShowAlert] = useState(false);
    function searchSubmit (){
        window.location.href='/search-result?SearchKey='+searchWord+'&PageIndex=1';
        
    }
    useEffect(() => {
        setsearchWord(document.getElementById("mailControl").value)
        
    },tableActive)
    function searchKey  (event) {
        searchProduct(event.target.value);
        setsearchWord(event.target.value);
    };
    return (
            <Col xs={12} className='search-box position-relative on-center'>
                    <FloatingLabel controlId="mailControl" label="Barkod,Marka veya Ürün Adı Giriniz" className="search-box__input-box" >
                        <Form.Control className="search-box__input-box__input" type="search" placeholder="Barkod,Marka veya Ürün Adı Giriniz" name="search-bar" onChangeCapture={(e)=>searchKey(e)}/>
                    </FloatingLabel>
                    <Button className='search-box__search-button btn-primary p-3 position-absolute end-0 top-0' onClick={searchSubmit}>
                        Ürünleri Bulmak İçin Arayın
                    </Button> 
                <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlert} delay={3000} autohide>
                    <Toast.Body>Lütfen Arama İçin Minimum 3 Karekterden Oluşan Bir Arama Kelimesi Giriniz.</Toast.Body>
                </Toast>                                                   
            </Col>

    )
}

export default SearchBar;