import React , {useState,useEffect} from "react";
import { Button, Dropdown, FloatingLabel, Form } from "react-bootstrap";
import axios from "axios";
import Select2 from "../SelectDropdown/Select2";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { useSearchParams } from "react-router-dom";
function FilterUser(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const animatedComponents = makeAnimated();
    const [optionSelected, setOptionSelected] = useState([]);
    const [sortSelected, setSortSelected] = useState(null);
    const [campaingSelected, setCampaingSelected] = useState([]);
    const [companySelected, setCompanySelected] = useState([]);
    const [roleSelected, setRoleSelected] = useState(null);
    const loginUser = props.loginUser;
    
    const sortOptions =[
        {value:"asc",label:"Artan Fiyata Göre Sırala"},
        {value:"desc",label:"Azalan Fiyata Göre Sırala"}
    ];
    const campaingOptions =[
        {value:"1",label:"İsonto-1"},
        {value:"2",label:"İsonto-2"},
        {value:"3",label:"İsonto-3"},
        {value:"4",label:"İsonto-4"},
        {value:"5",label:"İsonto-5"}
    ];
    const roles =[
        {value:"",label:"Tümünü Göster"},
        {value:"1",label:"Admin"},
        {value:"3",label:"Distrübütör"},
        {value:"4",label:"Bayi"}
    ];
    
    var selectedAreas = props.selectedAreas;
    const [allBrands, setAllBrands] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);

    const applyFilters = () => {
        
        var filterQuery = window.location.origin + window.location.pathname + "?PageIndex=1" ;
        
        //brands
        var brandQuery = "";
        optionSelected.map(
            (item) => {
               brandQuery += item.value + ",";
            }
        )
        brandQuery = brandQuery.slice(0,-1);
        if(brandQuery != "" && brandQuery != undefined && brandQuery != null){
            filterQuery += "&BrandId=" +brandQuery;
        }
        //Ara
        
        var SearchKey = document.getElementById("SearchKey").value;
        if(SearchKey != "" && SearchKey != undefined && SearchKey != null){
            filterQuery += "&SearchKey=" + SearchKey;
        }
        
        //Company
        var companyQuery = "";
        companySelected.map(
            item => {
                companyQuery += item.value + ",";
            }
        )
        companyQuery = companyQuery.slice(0,-1);
        if(companyQuery != ""){
            filterQuery += "&MasterId=" + companySelected[0].value;
        }
        //Campaing
        var campaingQuery = "";
        campaingSelected.map(
            item => {
                campaingQuery += item.value + ",";
            }
        )
        campaingQuery = campaingQuery.slice(0,-1);
        if(campaingQuery != "" && campaingQuery != null && campaingQuery != undefined){
            filterQuery += "&RolePrice=" + campaingQuery;
        }
        if(roleSelected != null && roleSelected != undefined && roleSelected != "" && roleSelected.value != ""){
            filterQuery += "&RoleId=" + roleSelected.value;
        }
        if(window.location.href.includes("/panel/company") && !filterQuery.includes("&RoleId=3")) {
          filterQuery += "&RoleId=3"
        }
        if(loginUser.UserRoles[0].Role.Name === "Distributor") {
          filterQuery += "&MasterId="+loginUser.Id;
        }

        window.location.href = filterQuery;
    }

    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
    };
    const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
    );
    const handleChange = (selected) => {
        setOptionSelected(selected);
    };
    const handleChangeSort = (sort) => {
        setSortSelected(sort);
    };
    const handleChangeCampaing = (Campaing) => {
        setCampaingSelected(Campaing);
    };
    const handleChangeCompany = (company) => {
        setCompanySelected(company);
    };
    const handleChangeRole = (role) => {
        setRoleSelected(role);
    };
    //getBrands
    useEffect(() => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Product/GetBrands",
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
                var tempData = [];
              for(var i=0;i<response.data.Data.length;i++){
                tempData.push({value:response.data.Data[i].Id,label:response.data.Data[i].Name})
              }
              setAllBrands(tempData);


            }).catch(function (error) {
              console.log(error.response);
        });
        // if(searchParams.toString().includes("Brand") && searchParams.toString().split("&")[1].replaceAll("Brand=","") != undefined && searchParams.toString().split("&")[1].replaceAll("Brand=","") != "" && allBrands!=undefined && allBrands.length>0){
        if( searchParams.get("BrandId") != "" && searchParams.get("BrandId") != null && allBrands!=undefined && allBrands.length>0){
            var prevBrandObj = [];
            var prevBrands=searchParams.get("BrandId").split(",");
            for(var j=0; j < prevBrands.length ; ++j){
                prevBrandObj.push(allBrands[allBrands.findIndex(item => item.value == prevBrands[j])]);
            }
            setOptionSelected(prevBrandObj);
        }
        
        for(var x=0;x<roles.length;++x){
            if(searchParams.get("RoleId") == roles[x].value){
                setRoleSelected(roles[x])
            }
        }
        if( searchParams.get("MasterId") != "" && searchParams.get("MasterId") != null && allCompanies!=undefined && allCompanies.length>0){
          var prevMasterObj = [];
          var prevMaster=searchParams.get("MasterId").split(",");
          for(var k=0; k < prevMaster.length ; ++k){
            prevMasterObj.push(allCompanies[allCompanies.findIndex(item => item.value == prevMaster[k])]);
          }
          setCompanySelected(prevMasterObj)
        }        
        if( searchParams.get("RolePrice") != "" && searchParams.get("RolePrice") != null){
            var prevRoleObj = [];
            var prevRoles=searchParams.get("RolePrice").split(",");
            for(var z=0; z < prevRoles.length ; ++z){
                prevRoleObj.push(campaingOptions[campaingOptions.findIndex(item => item.value == prevRoles[z])]);
            }
            setCampaingSelected(prevRoleObj);
        }        
    }, [allBrands.length,allCompanies.length,campaingOptions.length]);

    //getCompanies
    useEffect(() => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/User/GetByRole/Distributor",
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
                var tempData = [];
              for(var i=0;i<response.data.Data.length;i++){
                tempData.push({value:response.data.Data[i].Id,label:response.data.Data[i].CompanyName})
              }
              setAllCompanies(tempData);
            }).catch(function (error) {
              console.log(error.response);
        });
        
        if(searchParams.get("SupplierId") != "" && searchParams.get("SupplierId") != null && allCompanies!=undefined && allCompanies.length>0){
            var prevCompanyObj = [];
            var prevCompanies=searchParams.get("SupplierId").split(",");
            for(var z=0; z < prevCompanies.length ; ++z){
                prevCompanyObj.push(allCompanies[allCompanies.findIndex(item => item.value == prevCompanies[z])]);
            }
            setCompanySelected(prevCompanyObj);
        }
        
        for(var x=0;x<sortOptions.length;++x){
            if(searchParams.get("SortType") == sortOptions[x].value){
                setSortSelected(sortOptions[x])
            }
        }
    }, [allCompanies.length]);

    const [user , setUser] = useState({
        "UserRoles" : [
          {
            "Role" : {
              "Name" : "Unauth"
            }
          }
        ]
      });    
    useEffect(() => {
        if(loginUser.UserRoles[0].Role.Name === "Distributor") {
          var tempDataBrand = [];
          for(var i=0; i< loginUser.DistributorAllowedBrands.length ;i++){
              tempDataBrand.push({value:loginUser.DistributorAllowedBrands[i].Brand.Id,label:loginUser.DistributorAllowedBrands[i].Brand.Name})
          }
          setAllBrands(tempDataBrand);
          if( searchParams.get("BrandId") != "" && searchParams.get("BrandId") != null && tempDataBrand!=undefined && tempDataBrand.length>0){
              var prevBrandObj = [];
              var prevBrands=searchParams.get("BrandId").split(",");
              for(var j=0; j < prevBrands.length ; ++j){
                  prevBrandObj.push(tempDataBrand[tempDataBrand.findIndex(item => item.value == prevBrands[j])]);
              }
              setOptionSelected(prevBrandObj);
          }
        }        
      }, []);

      const createFilters = () => {
        if(loginUser.UserRoles[0].Role.Name === "Admin" && window.location.href.includes("/panel/users") ){
            return[
                <Select2
                key="Markalar1"
                className="me-4 filter-product-brand"
                options={allBrands}
                isMulti              
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChange}
                allowSelectAll={false}
                value={optionSelected}
                defaultValue = {optionSelected}
                Placeholder="Markalar"
            />,   
            // <Select2
            //     className="me-4 filter-product-brand"
            //     options={roles}
            //     closeMenuOnSelect={true}
            //     hideSelectedOptions={false}
            //     components={{ Option, MultiValue, animatedComponents }}
            //     onChange={handleChangeRole}
            //     allowSelectAll={false}
            //     value={roleSelected}
            //     defaultValue = {roleSelected}
            //     Placeholder="Roller"
            // />,
            <Select2
                key="İskontolar1"
                className="me-4 filter-product-brand"
                options={campaingOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChangeCampaing}
                allowSelectAll={false}
                value={campaingSelected}
                defaultValue={campaingSelected}
                Placeholder="İskontolar"
                isMulti
            />,
            <Select2
                key="Distrubutorler1"
                className="me-4 filter-product-brand"
                options={allCompanies}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChangeCompany}
                allowSelectAll={true}
                value={companySelected}
                defaultValue = {companySelected}
                Placeholder="Distrubutorler"
            />, 
            <FloatingLabel controlId="SearchKey" label="Ara:" className="product-name-input-box me-4 filter-user-search" >
                <Form.Control className="product-search-input" type="tel" name="SearchKey" defaultValue={searchParams.get("SearchKey")} />
            </FloatingLabel> 
            ]
        }
        else  if(loginUser.UserRoles[0].Role.Name === "Admin" && window.location.href.includes("/panel/company") ){
          return[
              <Select2
              key="Markalar2"
              className="me-4 filter-product-brand"
              options={allBrands}
              isMulti              
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChange}
              allowSelectAll={false}
              value={optionSelected}
              defaultValue = {optionSelected}
              Placeholder="Markalar"
          />,             
          <FloatingLabel controlId="SearchKey" label="Ara:" className="product-name-input-box me-4 filter-user-search" >
              <Form.Control className="product-search-input" type="tel" name="SearchKey" defaultValue={searchParams.get("SearchKey")} />
          </FloatingLabel> 
          ]
        }
        else  if(loginUser.UserRoles[0].Role.Name === "Distributor") {
            return[
                <Select2
                key= "Markalar3"
                className="me-4 filter-product-brand"
                options={allBrands}
                isMulti      
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChange}
                allowSelectAll={false}
                value={optionSelected}
                defaultValue = {optionSelected}
                Placeholder="Markalar"
            />,           
            <Select2
                key="İskontolar2"
                className="me-4 filter-product-brand"
                options={campaingOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChangeCampaing}
                allowSelectAll={false}
                value={campaingSelected}
                defaultValue={campaingSelected}
                Placeholder="İskontolar"
                isMulti
            />,
            <FloatingLabel controlId="SearchKey" label="Ara:" className="product-name-input-box me-4 filter-user-search" >
                <Form.Control className="product-search-input" type="tel" name="SearchKey" defaultValue={searchParams.get("SearchKey")} />
            </FloatingLabel>
            ]
        }
      }
    return(
        <div className="col-12 d-flex">
            {createFilters()}
            <Button className="ms-4" variant="success" onClick={()=>applyFilters()}>
                Filtrele
            </Button>
        </div>
    )
}

export default FilterUser;