import React , {useState,useEffect} from "react";
import { Button, Dropdown, FloatingLabel, Form } from "react-bootstrap";
import axios from "axios";
import Select2 from "../../components/SelectDropdown/Select2";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { useSearchParams } from "react-router-dom";
import Login from "../../pages/Login/Login";
function Filter(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const animatedComponents = makeAnimated();
    const [optionSelected, setOptionSelected] = useState([]);
    const [sortSelected, setSortSelected] = useState(null);
    const [campaingSelected, setCampaingSelected] = useState(null);
    const [companySelected, setCompanySelected] = useState([]);
    const loginUser = props.loginUser;
    
    const sortOptions =[
        {value:"asc",label:"Artan Fiyata Göre Sırala"},
        {value:"desc",label:"Azalan Fiyata Göre Sırala"}
    ];
    const campaingOptions =[
        {value:"true",label:"Kampanyalı Ürünler"},
        {value:"false",label:"Tüm Ürünler"}
    ];
    
    var selectedAreas = props.selectedAreas;
    const setselectedAreas = props.setselectedAreas;
    const changeFilterStatus =  (event) => {
        setselectedAreas([...selectedAreas, selectedAreas[event.target.id.slice(-1)-1].active = !selectedAreas[event.target.id.slice(-1)-1].active]);
        localStorage['selectedAreas'] = JSON.stringify(selectedAreas);
    }
    const [allBrands, setAllBrands] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);

    const applyFilters = () => {
        
        var filterQuery = "";
        if(document.getElementsByTagName("div")[1].classList.contains('panel-product-list')){
            filterQuery = window.location.origin + window.location.pathname + "?SearchKey=" + document.getElementById("productSearch").value;
        }
        else{
            filterQuery =window.location.origin + window.location.pathname + "?" + searchParams.toString().split("&")[0];
        }
        
        //brands
        var brandQuery = "";
        optionSelected.map(
            item => {
               brandQuery += item.value + ",";
            }
        )
        brandQuery = brandQuery.slice(0,-1);
        if(brandQuery != ""){
            filterQuery += "&Brands=" +brandQuery;
        }
        else{
            filterQuery += "&Brands=";
        }
        //sort
        if(sortSelected != "" && sortSelected != null){
            filterQuery += "&SortType=" + sortSelected.value;
        }
        //campaing
        if( campaingSelected != "" && campaingSelected != null && campaingSelected != undefined && campaingSelected != "undefined" )
        {
            filterQuery += "&Campaing=" + campaingSelected.value;
        }
        //Company
        var companyQuery = "";
        companySelected.map (
            item => {
                companyQuery += item.value + ",";
            }
        )
        companyQuery = companyQuery.slice(0,-1);
        if(companyQuery != ""){
            filterQuery += "&DistributorId=" + companySelected[0].value;
        }
        else{
            filterQuery += "&DistributorId=";
        }
        filterQuery += "&PageIndex=1";
        window.location.href = filterQuery;
    }


    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
      };
    const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
    );
    const handleChange = (selected) => {
        setOptionSelected(selected);
    };
    const handleChangeSort = (sort) => {
        setSortSelected(sort);
    };
    const handleChangeCampaing = (Campaing) => {
        setCampaingSelected(Campaing);
    };
    const handleChangeCompany = (company) => {
        setCompanySelected(company);
    };
    //getBrands
    useEffect(() => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Product/GetBrands",
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
                var tempData = [];
              for(var i=0;i<response.data.Data.length;i++){
                tempData.push({value:response.data.Data[i].Name,label:response.data.Data[i].Name})
              }
              setAllBrands(tempData);


            }).catch(function (error) {
              console.log(error.response);
        });
        // if(searchParams.toString().includes("Brand") && searchParams.toString().split("&")[1].replaceAll("Brand=","") != undefined && searchParams.toString().split("&")[1].replaceAll("Brand=","") != "" && allBrands!=undefined && allBrands.length>0){
        if( searchParams.get("Brands") != "" && searchParams.get("Brands") != null && allBrands!=undefined && allBrands.length>0){
            var prevBrandObj = [];
            var prevBrands=searchParams.get("Brands").split(",");
            for(var j=0; j < prevBrands.length ; ++j){
                prevBrandObj.push(allBrands[allBrands.findIndex(item => item.value == prevBrands[j])]);
            }
            setOptionSelected(prevBrandObj);
        }
        
        for(var x=0;x<sortOptions.length;++x){
            if(searchParams.get("SortType") == sortOptions[x].value){
                setSortSelected(sortOptions[x])
            }
        }
    }, [allBrands.length]);

    //getCompanies
    useEffect(() => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/User/Distributors",
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
                var tempData = [];
              for(var i=0;i<response.data.length;i++){
                tempData.push({value:response.data[i].Id,label:response.data[i].CompanyName})
              }
              setAllCompanies(tempData);
            }).catch(function (error) {
              console.log(error.response);
        });
        
        if(searchParams.get("DistributorId") != "" && searchParams.get("DistributorId") != null && allCompanies!=undefined && allCompanies.length>0){
            var prevCompanyObj = [];
            var prevCompanies=searchParams.get("DistributorId").split(",");
            for(var z=0; z < prevCompanies.length ; ++z){
                prevCompanyObj.push(allCompanies[allCompanies.findIndex(item => item.value == prevCompanies[z])]);
            }
            setCompanySelected(prevCompanyObj);
        }
        if(searchParams.get("Campaing") != "" && searchParams.get("Campaing") != null && campaingOptions != undefined && campaingOptions.length>0){
            var prevCampaingObj = [];
            var prevCampaing=searchParams.get("Campaing").split(",");
            for(var w=0; w < prevCampaing.length ; ++w){
                prevCampaingObj.push(campaingOptions[campaingOptions.findIndex(item => item.value == prevCampaing[w])]);
            }
            setCampaingSelected(prevCampaingObj[0]);
        }
        
        for(var x=0;x<sortOptions.length;++x){
            if(searchParams.get("SortType") == sortOptions[x].value){
                setSortSelected(sortOptions[x])
            }
        }
    }, [allBrands.length,allCompanies.length,sortOptions.length]);

    return(
        <div className="col-12 d-flex">
            <Button className="me-4" variant="success" onClick={()=>applyFilters()}>
                Filtrele
            </Button>
            <FloatingLabel controlId="productSearch" label="Ürün Adı" className="product-name-input-box me-4" >
                <Form.Control className="product-search-input" type="text" name="searchKey" defaultValue={searchParams.toString().split("&")[0].replaceAll("SearchKey=","")} />
            </FloatingLabel>
            <Select2
                className="me-4 filter-product-brand"
                options={allBrands}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChange}
                allowSelectAll={true}
                value={optionSelected}
                defaultValue = {optionSelected}
                Placeholder="Markalar"
            />           
            <Select2
                className="me-4 filter-product-campaign"
                options={campaingOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChangeCampaing}
                allowSelectAll={false}
                value={campaingSelected}
                defaultValue={campaingSelected}
                Placeholder="Kampanyalar"
            />
            {
                (loginUser.UserRoles[0].Role.Name === "Distributor" && window.location.href.includes("/panel/permission"))  ? 
                    <div></div>     
                    :
                    <Select2
                    className="me-4 filter-product-distributor"
                    options={allCompanies}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleChangeCompany}
                    allowSelectAll={true}
                    value={companySelected}
                    defaultValue = {companySelected}
                    Placeholder="Distrubutorler"
                    />
                
            }
 
            <Select2
                className="ms-auto me-4 filter-product-brand"
                options={sortOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChangeSort}
                allowSelectAll={false}
                value={sortSelected}
                defaultValue={sortSelected}
                Placeholder="Sıralama"
            /> 
            <Dropdown className="ms-4">
                <Dropdown.Toggle variant="" className="btn-outline-secondary" id="dropdown-list">
                    Listeyi Özelleştir
                </Dropdown.Toggle>
                <Dropdown.Menu >
                    <Dropdown.Item className={selectedAreas[0].active? "active": ""} onClick={changeFilterStatus} id="filter-1">Önerilen Satış Fiyatı</Dropdown.Item>
                    <Dropdown.Item className={selectedAreas[1].active? "active": ""} onClick={changeFilterStatus} id="filter-2">Size Özel İndirim Oranı</Dropdown.Item>
                    <Dropdown.Item className={selectedAreas[2].active? "active": ""} onClick={changeFilterStatus} id="filter-3">Kampanya</Dropdown.Item>
                    <Dropdown.Item className={selectedAreas[3].active? "active": ""} onClick={changeFilterStatus} id="filter-4">Barkod Kodu</Dropdown.Item>
                    {/* <Dropdown.Item className={selectedAreas[4].active? "active": ""} onClick={changeFilterStatus} id="filter-5">Rapor Et</Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>            
        </div>
    )
}

export default Filter;