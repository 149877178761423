import React, {useRef,useState} from "react";
import { Button } from "react-bootstrap";
import SearchedUserDetail from "./SearchedUserDetail";

function SearchedUsers (props)  {
    const userList = props.userList;
    const addDealer = props.addDealer;
    return(
            <div className="d-flex flex-column">
                {userList.map( (user) => {
                        return <SearchedUserDetail addDealer={addDealer} selectedUser={user}/>
                    })
                }
            </div>
        )
}

export default SearchedUsers;