import React from "react";
import { Container, Row,Col } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import BrandList from "../../components/Brands/BrandList";
import FooterResult from "../Shared/FooterResult";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function Brands (props)  {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const loginUser = props.loginUser;

  useEffect(() => {
    if(searchParams.get("SearchKey") === null){
      axios({
        method: "get",
        url: "https://www.listefix.com/api/Product/GetBrands",
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          console.log(response.data);
          
          
          setBrands(response.data.Data);
          setLoading(false);
      }).catch(function (error) {
            console.log(error.response);
      }); 
    }
    else {
      axios({
        method: "get",
        url: "https://www.listefix.com/api/Brand?SearchKey="+searchParams.get("SearchKey"),
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          console.log(response.data);
          
          
          setBrands(response.data.Data);
          setLoading(false);
      }).catch(function (error) {
            console.log(error.response);
      });
    }

    
},[])

  if(loading){
    return (
      <div></div>
    )
  }
  else{
    return (
      <div>
        <HeaderPanel loginUser={loginUser}/>
        <Container>
          <h1>Markalar</h1>
          <Row>
            <Col className="col-12">
              <BrandList brands={brands}></BrandList>
            </Col>
          </Row>
        </Container>
        <FooterResult></FooterResult>
      </div>
    );
  }

}
export default Brands;