import React from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import MediaDetail from "./MediaDetail";

function MediaContent (props) {
    const mediaPaths = props.mediaPaths;
    const mediaNames = props.mediaNames;
    const setAddedMedia = props.setAddedMedia;
    const setAddedMediaNames = props.setAddedMediaNames;
    const getAllMedia = props.getAllMedia;

    return(
        <div className="col-12">
            {
                mediaPaths.map((media,index) => 
                    <MediaDetail getAllMedia={getAllMedia} key={index} media={media} index={index} mediaName={mediaNames[index]} setAddedMedia={setAddedMedia} setAddedMediaNames={setAddedMediaNames} ></MediaDetail>
                )
            }
        </div>
    );
}

export default MediaContent;