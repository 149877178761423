import React, {useRef,useState} from "react";

function UserRowDetail (props)  {
    const prices = props.price;
    const brands = props.brands;
    const dist = props.dist;
    const index = props.index;
    return(
            <div className="user-role-details col-12 d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
                <div className="user-role-details__item d-flex align-items-center pe-5 col-4">
                    <label className="user-role-details__label pe-3">{index + 1}-</label>
                    <span className="user-role-details__label">{dist}</span>
                </div>
                <div className="user-role-details__item d-flex align-items-center pe-5 col-4">
                    <label className="user-role-details__label pe-3">Atanan Rol:</label>
                    <span className="user-role-details__label">İskonto-{prices}</span>
                </div>
                <div className="user-role-details__item d-flex align-items-center pe-5 col-4">
                    <label className="user-role-details__label pe-3">Marka:</label>
                    <span className="user-role-details__label">{brands}</span>
                </div>
                
            </div>
        )
}

export default UserRowDetail;