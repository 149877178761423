import React, {useEffect, useState} from "react";
import { Col, Container,Row,Form ,FloatingLabel,Button} from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import "./SearchBar.scss";


function SearchBarResult (props)  {

    const tableActive = props.tableActive;
    const activateTable=props.activateTable;
    const searchProduct = props.searchProduct;
    const [searchParams, setSearchParams] = useSearchParams();
    
        
    useEffect(() => {
        activateTable(true);
        document.getElementsByClassName("search-box")[0].classList.remove("on-center");
    },tableActive)
    function searchKey  (event) {
        if(event.target.value.length >= 0){
            activateTable(true);
            document.getElementsByClassName("search-box")[0].classList.remove("on-center");
        }
        
    };
    function searchSubmit (){
        window.location.href='/search-result?SearchKey='+document.getElementById("searchControl").value+'&PageIndex=1';
    }
    return (
            <Col xs={12} className='search-box position-relative on-center'>
                    <FloatingLabel controlId="searchControl" label="Barkod,Marka veya Ürün Adı Giriniz" className="search-box__input-box" >
                        <Form.Control className="search-box__input-box__input" type="search" placeholder="Barkod,Marka veya Ürün Adı Giriniz" defaultValue={searchParams.get("SearchKey").replace("SearchKey=","")} onChangeCapture={(e)=>searchKey(e)}/>
                    </FloatingLabel>
                    <Button className='search-box__search-button btn-primary p-3 position-absolute end-0 top-0' onClick={searchSubmit}>
                        Ürünleri Bulmak İçin Arayın
                    </Button>                                
            </Col>
    )
}

export default SearchBarResult;