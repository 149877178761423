import React, {useRef,useState} from "react";
import { Button } from "react-bootstrap";

function SearchedUserDetail (props)  {
    const user = props.selectedUser;
    const addDealer = props.addDealer;
    return(
        <div className="d-flex justify-content-between mb-2">
            <span>{user.FullName}</span>
            <Button variant="success" onClick={() => addDealer(user.UserName)}>+</Button>
        </div>
        )
}

export default SearchedUserDetail;