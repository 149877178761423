import React ,{useState,useEffect} from "react";
import CompanyRow from "./CompanyRow";
import FilterUser from "./FilterUser";
import { Toast ,Form,FloatingLabel,Modal,Button,Pagination} from "react-bootstrap";
import axios from "axios";
import MaskedInput from 'react-maskedinput';
import { useSearchParams } from "react-router-dom";
function CompanyList(props)  {
  const cities = props.cities;
  const loginUser = props.loginUser;
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(searchParams.get("PageIndex"));
  const [SuccesAlertUser, setSuccesAlertUser] = useState(false);
  const [SuccesAlert, setSuccesAlert] = useState(false);
  const [companyFilter, setcompanyFilter] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false)};
  const handleShow = () => setShow(true);
  const [showPassword, setShowPassword] = useState(false);
  const [companyToAdd, setcompanyToAdd] = useState({
    Id: "C5",
    Name: "Satıcı5",
    TaxNumber: 123456789,
    Email: "destek@mazakayazilim.com",
    Address: "Yıldırım Beyazıt Mahallesi, Aşık Veysel Bulvarı Erciyes Teknopark Tekno 5 Binası 1. Kat No 57 38039 Melikgazi, Kayseri, Türkiye",        
    City: "Kayseri",
    District: "Melizgazi",
    showBrands: [1,3,5],
    Phone: "0555 525 15 41",
    priceType:"1",
    ProductPermission: [11,12,13,14,15,16],
    ProductSpecialPrice: [11,12,13,14,15,16],
    ProductSpecialDiscount: [11,12,13,14,15,16],
    Campaing : ["Koli alımında %40 indirim", "10 üzeri siparişlerde %30 yıl sonu iskonto"],
  });
  var companies = props.company;
  const [userDistricts,setUserDistricts] = useState([]);
  // const [filteredCompanies, setfilteredcompanies] = useState(companies);
  const saveCompany = () => {
    localStorage.setItem("companies", JSON.stringify(companies));
    setSuccesAlert(true)
  }
  function addCompanyPop () {
    handleShow();
    
  }
  const handleValueChange = (e) => {
    companyToAdd[e.target.name] = e.target.value;
  }
  const handleCityValueChange = (e) => {
    getDistricts(e.target.value);
  }
  const addUser = () => {
    let formData = {
        "username": document.getElementById("newuserName").value,
        "email": document.getElementById("newuserMail").value,
        "password": document.getElementById("newuserPassword").value,
        "roleName": "Distributor",
        "fullName": document.getElementById("newuserFullName").value,
        "phone": document.getElementById("newuserPhone").value.replace(/\D/g, ""),
        "address": document.getElementById("newuserAddress").value,
        "countyId": document.getElementById("newuserDistrict").value,
        "companyName": document.getElementById("newuserCompany").value,
        "companyOfficalName": document.getElementById("newuserAuth").value,
        "taxNumber": document.getElementById("newuserName").value
    };
    if(formData.address.length > 0 &&
       formData.username.length > 0 &&
        formData.email.length > 0 &&
        formData.password.length > 0 &&
        formData.fullName.length > 0 &&
        formData.phone.length > 0 &&
        formData.address.length > 0 &&
        formData.countyId.length > 0 &&
        formData.companyName.length > 0 &&
        formData.companyOfficalName.length > 0 &&
        formData.taxNumber.length > 0) {
          axios({
            method: "post",
            url: "https://www.listefix.com/api/Authenticate/addUserBySUDO",
            data: formData,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
            }).then(function (response) {
                handleClose();
                setSuccesAlertUser(true);
                document.getElementById("addUserError").classList.add("d-none");
                window.location.reload();
            }).catch(function (error) {
              console.log(error.response);
            });
        }
        else {
          document.getElementById("addUserError").classList.remove("d-none");
        }
  } 
  const showPasswordHandle = () => {
    setShowPassword(!showPassword);
    if(!showPassword){
        document.getElementById("newuserPassword").type = "text";
        document.getElementById("newuserPasswordRepeat").type = "text";
    }
    else{
        document.getElementById("newuserPassword").type = "password";
        document.getElementById("newuserPasswordRepeat").type = "password";
    }
  }
  // useEffect(() => {
  //   searchCompany(companyFilter);
  // }, [companyFilter,companies.length]);

  const getDistricts = (cityId) => {
    axios({
        method: "get",
        url: "https://www.listefix.com/api/Location/GetCounties?cityId=" + cityId,
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          setUserDistricts(response.data);
      }).catch(function (error) {
            console.log(error.response);
      })
}
const createDistricts = () => {
    if(userDistricts.length <= 0) {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Location/GetCounties?cityId=1",
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
              setUserDistricts(response.data);
          }).catch(function (error) {
                console.log(error.response);
          })
    }
    return(
        userDistricts.map(dist => 
            <option value={dist.Id}>{dist.Name}</option>
        )
    )
}
    return(
        <div className="table-responsive">
        
        <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
          <p><strong>{companies.length} Satıcı Listelenmekte</strong></p>
          <button className="btn btn-success mb-3 add-company" onClick={()=>addCompanyPop()} variant="success">
              Satıcı Ekle
          </button>
        </div>
        <div className="col-12 mt-3 mb-3 user-filter-box">

          <FilterUser loginUser={loginUser}></FilterUser>
        </div>        
        <table className="table table-hover table-middle table-divider align-middle">
          <thead>
            <tr>
              <th>Satıcı Adı</th>
              <th>Satıcı Adresi</th>
              <th>Satıcı Vergi Numarası</th>
              <th>Satıcı Telefon Numarası</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, index) =>
              <CompanyRow cities={cities} key={index} index={index + 1} company={company} />
              )
            }
          </tbody>
        </table>
        <div className="col-12 d-flex justify-content-center">
          <Pagination>
            { pageIndex == 1 ? <></> : <Pagination.Prev href={window.location.href.replace("PageIndex="+pageIndex,"") + "PageIndex=" + (Number(pageIndex) - 1)} /> }
            <Pagination.Item active>{pageIndex}</Pagination.Item>
            {companies.length == 20 ? <Pagination.Next href={window.location.href.replace("PageIndex="+pageIndex,"") + "PageIndex=" + (Number(pageIndex) + 1)} /> : <></>}
          </Pagination>
        </div>        
        <Toast onClose={() => setSuccesAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={SuccesAlert} delay={3000} autohide>
            <Toast.Body>Değişiklikleriniz Kaydedilmiştir.</Toast.Body>
        </Toast>
        <Toast onClose={() => setSuccesAlertUser(false)} className="position-fixed report-alert bg-success text-white text-center" show={SuccesAlertUser} delay={3000} autohide>
            <Toast.Body>Kullanıcı Kayıt Edilmiştir.</Toast.Body>
        </Toast>        
        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Üye Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Yeni Üye Ekle</p>
                    <Form>
                    <FloatingLabel controlId="newuserName" label="Kullanıcı Adı" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="text" name="Name" onChangeCapture={(e)=>handleValueChange(e)} required  />
                    </FloatingLabel>
                    <FloatingLabel controlId="newuserPassword" label="Parola" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="password" name="Password" onChangeCapture={(e)=>handleValueChange(e)} required  />
                        <a onClick={showPasswordHandle} class={(showPassword ? 'd-none' : 'd-block')+ " position-absolute show-password"}>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.8208 11.181C22.9428 9.261 19.4998 3 11.9998 3C4.49981 3 1.05681 9.261 0.17881 11.181C0.0609485 11.4383 -6.10352e-05 11.718 -6.10352e-05 12.001C-6.10352e-05 12.284 0.0609485 12.5637 0.17881 12.821C1.05681 14.739 4.49981 21 11.9998 21C19.4998 21 22.9428 14.739 23.8208 12.819C23.9384 12.562 23.9993 12.2827 23.9993 12C23.9993 11.7173 23.9384 11.438 23.8208 11.181ZM11.9998 19C5.69281 19 2.74981 13.634 1.99981 12.011C2.74981 10.366 5.69281 5 11.9998 5C18.2918 5 21.2358 10.343 21.9998 12C21.2358 13.657 18.2918 19 11.9998 19Z" fill="#374957"/>
                                <path d="M11.9998 7.00037C11.0109 7.00037 10.0442 7.29361 9.22191 7.84302C8.39966 8.39243 7.7588 9.17332 7.38036 10.0869C7.00192 11.0006 6.90291 12.0059 7.09583 12.9758C7.28876 13.9457 7.76496 14.8366 8.46422 15.5359C9.16349 16.2352 10.0544 16.7114 11.0243 16.9043C11.9942 17.0972 12.9995 16.9982 13.9132 16.6198C14.8268 16.2413 15.6077 15.6005 16.1571 14.7782C16.7065 13.956 16.9998 12.9893 16.9998 12.0004C16.9982 10.6748 16.4709 9.40392 15.5335 8.46659C14.5962 7.52925 13.3254 7.00195 11.9998 7.00037ZM11.9998 15.0004C11.4064 15.0004 10.8264 14.8244 10.333 14.4948C9.8397 14.1651 9.45518 13.6966 9.22812 13.1484C9.00106 12.6002 8.94165 11.997 9.0574 11.4151C9.17316 10.8332 9.45888 10.2986 9.87844 9.87905C10.298 9.45949 10.8325 9.17377 11.4145 9.05801C11.9964 8.94225 12.5996 9.00166 13.1478 9.22873C13.696 9.45579 14.1645 9.84031 14.4942 10.3337C14.8238 10.827 14.9998 11.407 14.9998 12.0004C14.9998 12.796 14.6837 13.5591 14.1211 14.1217C13.5585 14.6843 12.7954 15.0004 11.9998 15.0004Z" fill="#374957"/>
                            </svg>
                        </a>
                        <a onClick={showPasswordHandle} class={(showPassword ? 'd-block' : 'd-none') + " position-absolute show-password"}>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.8208 11.181C22.8547 9.08568 21.4396 7.22849 19.6758 5.74097L22.7078 2.70897L21.2928 1.29297L17.9998 4.58297C16.1772 3.52869 14.1053 2.98207 11.9998 2.99997C4.49981 2.99997 1.05681 9.26097 0.17881 11.181C0.0609485 11.4383 -6.10352e-05 11.718 -6.10352e-05 12.001C-6.10352e-05 12.284 0.0609485 12.5637 0.17881 12.821C1.14495 14.9163 2.56003 16.7734 4.32381 18.261L1.29281 21.293L2.70681 22.707L5.99981 19.417C7.82245 20.4713 9.8943 21.0179 11.9998 21C19.4998 21 22.9428 14.739 23.8208 12.819C23.9384 12.562 23.9993 12.2826 23.9993 12C23.9993 11.7173 23.9384 11.438 23.8208 11.181ZM1.99981 12.011C2.74981 10.366 5.69281 4.99997 11.9998 4.99997C13.5694 4.99079 15.1185 5.35697 16.5178 6.06797L14.7528 7.83297C13.7927 7.19557 12.6417 6.90996 11.495 7.02461C10.3484 7.13926 9.27668 7.64711 8.46181 8.46197C7.64695 9.27683 7.13911 10.3485 7.02446 11.4952C6.90981 12.6419 7.19541 13.7929 7.83281 14.753L5.75381 16.832C4.15418 15.5294 2.87068 13.881 1.99981 12.011ZM14.9998 12C14.9998 12.7956 14.6837 13.5587 14.1211 14.1213C13.5585 14.6839 12.7955 15 11.9998 15C11.5543 14.9982 11.115 14.8957 10.7148 14.7L14.6998 10.715C14.8955 11.1152 14.9981 11.5545 14.9998 12ZM8.99981 12C8.99981 11.2043 9.31588 10.4413 9.87849 9.87865C10.4411 9.31604 11.2042 8.99997 11.9998 8.99997C12.4453 9.00169 12.8846 9.10426 13.2848 9.29997L9.29981 13.285C9.1041 12.8848 9.00154 12.4455 8.99981 12ZM11.9998 19C10.4302 19.0091 8.88117 18.643 7.48181 17.932L9.24681 16.167C10.2069 16.8044 11.3579 17.09 12.5046 16.9753C13.6513 16.8607 14.7229 16.3528 15.5378 15.538C16.3527 14.7231 16.8605 13.6514 16.9752 12.5047C17.0898 11.3581 16.8042 10.207 16.1668 9.24697L18.2448 7.16897C19.8476 8.47278 21.1318 10.125 21.9998 12C21.2358 13.657 18.2918 19 11.9998 19Z" fill="#374957"/>
                            </svg>
                        </a>
                    </FloatingLabel>
                    <FloatingLabel controlId="newuserPasswordRepeat" label="Parola Tekrar" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="password" name="PasswordRepeat" onChangeCapture={(e)=>handleValueChange(e)} required  />
                    </FloatingLabel>
                    <FloatingLabel controlId="newuserFullName" label="Kullanıcı Adı Soyadı" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="text" name="FullName" onChangeCapture={(e)=>handleValueChange(e)} required  />
                    </FloatingLabel>
                    <Form.Select name="County" id="newuserCity" className="user-name-input-box mb-3" onChange={(e)=>handleCityValueChange(e)} required>
                        {   
                            cities.map(city => 
                                <option value={city.Id}>{city.Name}</option>
                            )
                        }
                    </Form.Select>
                    <Form.Select name="CountyDist" id="newuserDistrict" className="user-name-input-box mb-3" required>
                        { createDistricts() }
                    </Form.Select>
                    <FloatingLabel controlId="newuserAddress" label="Adresi" className="search-box__input-box mb-3" >
                            <Form.Control rows={3} as="textarea" className="report-input" name="Address" onChangeCapture={(e)=>handleValueChange(e)} required />
                    </FloatingLabel> 
                    <FloatingLabel controlId="newuserCompany" label="Firma Adı" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="text" name="Company" onChangeCapture={(e)=>handleValueChange(e)} required />
                    </FloatingLabel> 
                    <FloatingLabel controlId="newuserAuth" label="Şirket Yetkilisi" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="text" name="AuthPerson" onChangeCapture={(e)=>handleValueChange(e)} required />
                    </FloatingLabel> 
                    <FloatingLabel controlId="newuserPhone" label="Telefon Numarası" className="company-name-input-box mb-3" >
                        {/* <Form.Control className="company-name-input" type="tel" name="Phone" onChangeCapture={(e)=>handleValueChange(e)} required /> */}
                        <MaskedInput id="newuserPhone" className='form-control' type='text' name='Phone'  onChange={(e) => handleValueChange(e)} mask='1111  111  1111' />
                    </FloatingLabel> 
                    <FloatingLabel controlId="newuserMail" label="Email Adresi" className="company-name-input-box mb-3" >
                        <Form.Control className="company-name-input" type="text" name="Mail" onChangeCapture={(e)=>handleValueChange(e)} required />
                    </FloatingLabel>                    
                    </Form>                                                        
                </Modal.Body>
                <Modal.Footer>
                  <p className="text-danger d-none" id="addUserError">Tüm Alanlar Zorunludur</p>
                    <Button variant="success" onClick={addUser}>
                        Kaydet
                    </Button>
                </Modal.Footer>
        </Modal>              
      </div>
          )
}

export default CompanyList;