import React ,{useState} from "react";
import { Container, Row,Col,Dropdown, Form,Toast,Button } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import SelectCompany from "../../components/CompanyList/SelectCompany";
import SelectBrand from "../../components/Brands/SelectBrand";
import Multiselect from 'react-bootstrap-multiselect'
import FooterResult from "../Shared/FooterResult";
import Select2 from "../../components/SelectDropdown/Select2";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { useEffect } from "react";

const animatedComponents = makeAnimated();
function DistrubutorBrand (props)  {
  const [companies,setCompanies] = useState([]);
  // const brands = JSON.parse(localStorage["brands"]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [brands, setBrands] = useState([{value:"",label:""}]);
  const company = props.company;
  useEffect(() => {
    var tempDataBrand = [];
    company.DistributorAllowedBrands.map((tempBrand) => {
      tempDataBrand.push({value:tempBrand.Brand.Id,label:tempBrand.Brand.Name})
    });
    setOptionSelected(tempDataBrand);
  },[])
  

  function handleSaveClick () {
    if(document.getElementById("companySelect").value != "" && optionSelected.length >= 1){
      assignBrand();

    }
    else {
      setShowAlertError(true);
    }

  }
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleChange = (selected) => {
    setOptionSelected(selected)
    console.log(selected)
  };
  
  // const brands = [
  //   { value: "MAKITA", label: "MAKITA" },
  //   { value: "FACTOR", label: "FACTOR" },
  //   { value: "KNIPEX", label: "KNIPEX" },
  //   { value: "CLIPPER", label: "CLIPPER" },
  //   { value: "WELLER", label: "WELLER" },
  //   { value: "EDN", label: "EDN" }
  // ];

  useEffect(() => {
    const getData = async () => {
      axios({
        method: "get",
        url: "https://www.listefix.com/api/User/Distributors",
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          console.log(response.data);
          setCompanies(response.data.Data);
      }).catch(function (error) {
            console.log(error.response);
      });
    }
    getData();
  },[]);
  useEffect(()=>{
    axios({
      method: "get",
      url: "https://www.listefix.com/api/Product/GetBrands",
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
    }).then(function (response) {
        var tempData = [];
        for(var i=0;i<response.data.Data.length;i++){
          tempData.push({value:response.data.Data[i].Id,label:response.data.Data[i].Name})
        }
        setBrands(tempData);
    }).catch(function (error) {
          console.log(error.response);
    });
  },[]);

  const assignBrand = () => {
    let brandList = [];
    optionSelected.map(opt => {
      brandList.push(opt.value);
    });
    axios({
      method: "post",
      url: "https://www.listefix.com/api/UserRelation/AssignDistributorBrands?distId=" + company.Id,
      data: brandList,
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
    }).then(function (response) {
        setShowAlert(true);
        
    }).catch(function (error) {
          console.log(error.response);
    });
  }
  return (
    <div>
      {/* <HeaderPanel /> */}
      <Container>
        <h1 className="text-center mb-4 mt-4">Marka Distrubutor Eşleştir</h1>
        <Row className="justify-content-center">
          <Col className="col-12 brand-dist-wrapper">


          <Form.Group controlId="companySelect">
            <Form.Label>Firma Seçiniz</Form.Label>
            <Form.Select className="mb-4">
              {/* {companies.map((company, index) =>
                <SelectCompany key={index} index={index + 1} company={company} />
                )
              } */}
              <option value={company.Id} selected>{company.CompanyName}</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="brandSelect">
            <Form.Label>Marka Seçiniz</Form.Label>
            {/* <Form.Control as="select" multiple className="mb-4">
                <option value="" selected disabled>Marka Seçiniz</option>
                {brands.map((brand, index) =>
                  <SelectBrand key={index} index={index + 1} brands={brand} />
                  )
                }
            </Form.Control> */}
            <Select2
            className="mb-4"
              options={brands}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChange}
              allowSelectAll={true}
              value={optionSelected}
            /> 
           
          </Form.Group>
          <Button variant="success" onClick={handleSaveClick}>
                Kaydet
          </Button>

          </Col>
        </Row>
      </Container>
      <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={5000} autohide>
          <Toast.Body>Marka Atamanız Kaydedilmiştir.</Toast.Body>
      </Toast>
      <Toast onClose={() => setShowAlertError(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertError} delay={5000} autohide>
          <Toast.Body>Lütfen Marka ve Firma Seçimi Yapınız.</Toast.Body>
      </Toast>
      {/* <FooterResult/>       */}
    </div>
  );
}
export default DistrubutorBrand;