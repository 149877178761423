import React,{useState} from "react";
import { Container,Col,Row,FloatingLabel,Form, Button, Toast,Modal } from "react-bootstrap";
import HeaderPanel from "../Shared/HeaderPanel";
import ExcelLogo from "../../Content/excel.png";
import FooterResult from "../Shared/FooterResult";
import axios from "axios";
import exampleExcel from '../../Content/listfix.xlsx';
function Excel (props)  {

  const [fileName, setFileName] = useState("");
  const [alertMessage, setAlertMessage] = useState("Lütfen Bir Dosya Seçiniz");
  const [SuccesAlert, setSuccesAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [excelFiles, setexcelFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const loginUser = props.loginUser;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function checkExcel () {
    console.log("sad",excelFile.name)
    if(!fileName.length > 0){
      setAlertMessage("Lütfen Dosya İsmi Giriniz");
      setShowAlert(true);
    }
    else if(!excelFile.name.includes(".xlsx")){
      setAlertMessage("Yüklediğiniz Dosya Excel Formatında Değildir Lütfen '.xlsx' Formatında Bir Excel Yükleyiniz.");
      setShowAlert(true);
    }
    else{
      var data = new FormData();
      data.append('file', excelFile, excelFile.name);
      data.append('FileName', fileName);
      axios({
        method: "post",
        url: "https://www.listefix.com/api/Media/Insert",
        data: data,
        headers: {
          Authorization: "Bearer "+localStorage.getItem("listfixToken")
        }
      }).then(function (response) {
          
          
          localStorage.setItem("fileName",excelFiles);
          fillExcel(response.data.Data);
        }).catch(function (error) {
          console.log(error);
        });

      
    }
  }
  const fillExcel = (mediaID) => {
    axios({
      method: "post",
      url: "https://www.listefix.com/api/Excel/ProductImport?fileName="+mediaID,
      headers: {
        Authorization: "Bearer "+localStorage.getItem("listfixToken")
      }
    }).then(function (response) {
        setSuccesAlert(true);
        window.location.href = "/panel/excel-result";
      }).catch(function (error) {
        console.log(error);
        setAlertMessage("Excel Yüklemesi Başarısız Lütfen Exceli Kontrol Edip Tekrar yükleyiniz.");
        setShowAlert(true);
      });
  }
  const selectFile = (event) => {
    setExcelFile(event.target.files[0]);
  }
  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
}
  return (
    <div>
      <HeaderPanel loginUser={loginUser} />
      <Container fluid={true} className="pt-5 excel-container">
        <Row>
          <Col xs={12} className="excel-header">
            <h3 className="text-center col-12 mb-5">Excel Dosyanızla Ürün Yüklemek İçin Aşağıdaki Adımları Takip Ediniz.</h3>
              <Col className="excel-step col-auto form-control mb-5 mt-5 border-success">
                <p className="d-flex flex-column align-items-center text-center"><strong className="pe-2 h4 text-success">1</strong><span> Örnek excel dosyasını aşağıdaki linkten indiriniz.</span></p>
                <a className=" flex-column text-dark d-flex flex-column align-items-center justify-content-center text-center" download="listefix.xlsx" href={exampleExcel} target="_blank">
                  <img  src={ExcelLogo} alt="excel" height={50} />
                  <span className="text-dark col-12">Excel Dosyasını İndir</span>
                </a>
              </Col>
              <Col className="excel-step col-auto form-control mb-5 border-success">
                <p className="d-flex flex-column align-items-center text-center"><strong className="pe-2 h4 text-success">2</strong><span> Opsiyonel Olan İskonto Alanları Dışında Bütün Sütunları Eksiksiz Doldurunuz Aksi Takdirde Excelinizi Sisteme Yüklemekte Hata Alabilirsiniz.</span></p>
              </Col> 
              <Col className="excel-step col-auto form-control mb-5 border-success d-flex flex-column align-items-center">
                <p className="d-flex flex-column align-items-center text-center"><strong className="pe-2 h4 text-success">3</strong><span> Doldurduğunuz Excel Dosyasını Aşağıdan Sisteme Yükleyiniz.</span></p>
                <FloatingLabel controlId="userMediaName" label="Medya Adı" className="company-name-input-box mb-3 col-12" >
                    <Form.Control className="company-name-input" type="text" name="Media" defaultValue={fileName} onChangeCapture={(e)=>handleFileNameChange(e)} />
                </FloatingLabel>
                <Form.Group controlId="formFileLg" className="excel-file-upload">
                  <Form.Control type="file"
                    onChange={(e) => selectFile(e)} 
                    placeholder={fileName} 
                    label={fileName} size="md" />
                </Form.Group>
                <Button variant="success" onClick={checkExcel} className="excel-upload-button btn btn-success mt-4 mb-3 ms-auto me-auto">
                    Excel Yükle
                </Button>
                <p id="excelTrigger" className="excel-file-upload-trigger text-success d-none">Excel Dosyası Yüklendi Ürünleri Sisteme Yüklemek için tıklayınız.</p>
                <Button id="excelFillTrigger" variant="success" onClick={fillExcel} className="excel-upload-button btn btn-success mt-4 mb-3 ms-auto me-auto d-none">
                    Ürünleri Yükle
                </Button>
                <Button variant="link" onClick={handleShow} className="excel-upload-button btn btn-default mt-1 mb-4 ms-auto me-auto">
                    Yüklenen Excel Dosyalarını Gör
                </Button>                            
              </Col>                           
          </Col>          
        </Row>
        <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlert} delay={3000} autohide>
            <Toast.Body>{alertMessage}</Toast.Body>
        </Toast> 
        <Toast onClose={() => setSuccesAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={SuccesAlert} delay={3000} autohide>
            <Toast.Body>Excel Dosyanız Yüklenmiştir.Excel dosyasındaki ürünleri yükleme için linke tıklayınız.</Toast.Body>
        </Toast>
        <Modal  show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Yüklenen Excel Dosyaları</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 d-flex flex-wrap gap-2">
                  {excelFiles.map((file, index) =>
                      <div key={index} className="col-3 d-flex flex-column align-items-center">
                        <img src={ExcelLogo} alt="" height={30}  />
                        <span className="text-dark text-center excel-file-name">{file}</span>
                      </div>
                    )
                  }                  
                    
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Kapat
                </Button>
            </Modal.Footer>
        </Modal>                      
      </Container>
      <FooterResult></FooterResult>
    </div>
  );
}
export default Excel;