import React, {useRef,useState} from "react";
import "./CompanyRow.scss";
import { Modal,ModalBody,ModalDialog,ModalDialogProps,ModalHeader,Button,Form,FloatingLabel,Toast} from 'react-bootstrap';
import SelectRole from "./SelectRole";
import DistrubutorBrand from "../../pages/Panel/DistrubutorBrand";
import axios from "axios";
import { useEffect } from "react";
import MaskedInput from 'react-maskedinput';
import CompanyRowDetail from "./CompanyRowDetail";
function CompanyRow (props)  {
    const company = props.company;
    const dashboard = props.dashboard;
    const [userDistricts,setUserDistricts] = useState([]);
    const cities = props.cities;
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertFalse, setShowAlertFalse] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let selectedModal = useRef("modal-" + company.Id);
    const handleCloseWithMessage = () => {
        axios({
            method: "patch",
            url: "https://www.listefix.com/api/User",
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            },
            data: {
                Id: company.Id,
                patch: [
                    {
                        path: "/Active",
                        value: company.Active,
                    },
                    {
                        path: "/Email",
                        value: company.Email,
                    },
                    {
                        path: "/FullName",
                        value: company.FullName,
                    },
                    {
                        path: "/PhoneNumber",
                        value: company.PhoneNumber,
                    },
                    {
                        path: "/UserName",
                        value: company.UserName,
                    },
                    {
                        path: "/Address",
                        value: company.Address,
                    },
                    {
                        path: "/TaxNumber",
                        value: company.TaxNumber,
                    },
                    {
                        path: "/CompanyName",
                        value: company.CompanyName,
                    },
                    {
                        path: "/CompanyOfficalName",
                        value: company.CompanyOfficalName,
                    },
                    {
                        path: "/CountyId",
                        value: Number(document.getElementById("userDistrict").value),
                    }
                ],
            }
            }).then(function (response) {
                handleClose();
                setShowAlert(true);
            }
            ).catch(function (error) {
                console.log(error.response);
                setShowAlertFalse(true);
            }
        );
        axios({
            method: "put",
            url: "https://www.listefix.com/api/User/UpdateUserRole/"+company.Id,
            data: [Number(document.getElementById("roleId").value)],
          }).then(function (response) {
            }).catch(function (error) {
                console.log(error.response);
            });        
    }
    const [showPrice, setShowPrice] = useState(false);
    const handleClosePrice = () => setShowPrice(false);
    const handleShowPrice = () => setShowPrice(true);
    const roles =[
        {id:"3",name:"Distrübütör"},
        {id:"4",name:"Bayi"}
    ];

    const handleValueChange = (e) => {
        if(e.target.name == "Active") {
            company[e.target.name] = e.target.checked;
        }
        else if(e.target.name == "PhoneNumber") {
            company [e.target.name] = e.target.value.replace(/\D/g, "");
        }
        else {
            company [e.target.name] = e.target.value;
        }
    }
    const handleCityValueChange = (e) => {
        getDistricts(e.target.value);
    }    
    let selectedcompany = useRef(company.Id+"_company");

    function openDetail(Id) {
        document.getElementById(selectedcompany.current).classList.toggle("open");
    }
    const getDistricts = (cityId) => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Location/GetCounties?cityId=" + cityId,
            headers: {
              Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
          }).then(function (response) {
              setUserDistricts(response.data);
          }).catch(function (error) {
                console.log(error.response);
          })
    }
    const createDistricts = () => {
        if(userDistricts.length <= 0) {
            axios({
                method: "get",
                url: "https://www.listefix.com/api/Location/GetCounties?cityId=" + company.County?.City.Id,
                headers: {
                  Authorization: "Bearer "+localStorage.getItem("listfixToken")
                }
              }).then(function (response) {
                  setUserDistricts(response.data);
              }).catch(function (error) {
                    console.log(error.response);
              })
        }
        return(
            userDistricts.map(dist => 
                <option value={dist.Id} selected={ dist.Id == company.County?.Id ? 'selected' : '' }>{dist.Name}</option>
            )
        )
    }    
    return[
            <tr key={selectedcompany}  >
              <td onClick={()=>openDetail(company.Id)}><strong>{company.CompanyName}</strong></td>
              <td className="company-address-col" onClick={()=>openDetail(company.Id)}>{company.County?.City.Name} / {company.County?.Name}</td>
              <td onClick={()=>openDetail(company.Id)}>{company.TaxNumber}</td>
              <td onClick={()=>openDetail(company.Id)}>{company.PhoneNumber}</td>
              <td className={(dashboard ? "d-none" : "")}><button variant="info" className="btn btn-link text-info edit-button" onClick={handleShow}>Düzenle</button></td>
              <td className={(dashboard ? "d-none" : "")}><button variant="success" className="btn btn-link text-success edit-price-button" onClick={handleShowPrice}>Marka Atama Düzenle</button></td>
            </tr>,
            <tr useRef={company.Id} className={'expandable' + (selectedcompany === company.Id ? ' open': ' ' )}  key="tr-expander" id={company.Id + "_company"}>
                <td className="company-detail-col" colSpan={12}>
                    <div  className="d-flex col-12">
                        <div className="uk-width-3-4 ps-5 pe-5">
                        <p className="d-flex col-12">
                                <strong className="pe-2">Adres:</strong><br/>
                                <span>{company.Address}</span>
                            </p>
                            <p className="d-flex col-12">
                                <strong className="pe-2">Şehir:</strong><br/>
                                <span>{company.County?.City.Name}</span>
                            </p>
                            <p className="d-flex col-12">
                                <strong className="pe-2">İlçe:</strong><br/>
                                <span>{company.County?.Name}</span>
                            </p>
                            <p className="d-flex col-12">
                                <strong className="pe-2">Vergi No:</strong><br/>
                                <span>{company.TaxNumber}</span>
                            </p>
                            <p className="d-flex col-12">
                                <strong className="pe-2">Mail:</strong><br/>
                                <span>{company.Email}</span>
                            </p>
                            <p className="d-flex col-12">
                                <strong className="pe-2">Yetkili Adı:</strong><br/>
                                <span>{company.CompanyOfficalName}</span>
                            </p>
                            <p className="d-flex col-12">
                                <strong className="pe-2">Telefon No:</strong><br/>
                                <span>{company.PhoneNumber}</span>
                            </p>
                            <br />
                            <div className="user-prices">
                                <p className="d-flex col-12"><strong>Bayiler</strong></p>
                                {company.Slaves.map((dealer, index) =>
                                    <CompanyRowDetail dealer={dealer.FullName} phone={dealer.PhoneNumber} mail={dealer.Email} index={index}></CompanyRowDetail>
                                )
                                }                                
                            </div>
                        </div>
                    </div>
                </td>
            </tr>,
            <Modal useRef={selectedModal} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Üye Düzenle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>{company.name}</strong> Üye bilgilerini değiştir.</p>
                <FloatingLabel controlId="userName" label="Kullanıcı Adı" className="user-name-input-box mb-3" >
                    <Form.Control className="user-name-input" type="text" name="UserName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={company.UserName} />
                </FloatingLabel>
                <FloatingLabel controlId="fullName" label="Adı Soyadı" className="user-name-input-box mb-3" >
                    <Form.Control className="user-name-input" type="text" name="FullName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={company.FullName} />
                </FloatingLabel>
                <FloatingLabel controlId="companyName" label="Şirket Adı" className="user-name-input-box mb-3" >
                    <Form.Control className="user-name-input" type="text" name="CompanyName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={company.CompanyName} />
                </FloatingLabel>
                <label htmlFor="roleId">Kullanıcı Rolü</label>
                <Form.Select controlId="roleId" className="user-name-input-box mb-3">
                    {   
                        roles.map(role => 
                            <SelectRole role={role} active={role.id == company.roleId}/>
                        )
                    }
                </Form.Select>
                <FloatingLabel controlId="companyOfficalName" label="Şirket Yetkilisi Adı" className="user-name-input-box mb-3" >
                    <Form.Control className="user-name-input" type="text" name="CompanyOfficalName" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={company.CompanyOfficalName} />
                </FloatingLabel>
                <Form.Select controlId="userCounty" className="user-name-input-box mb-3">
                    {   
                        roles.map(role => 
                            <SelectRole role={role} active={role.id == company.roleId}/>
                        )
                    }
                </Form.Select>                
                <Form.Select name="County" id="userCity" className="user-name-input-box mb-3" onChange={(e)=>handleCityValueChange(e)} required>
                    {   
                        cities.map(city => 
                            <option value={city.Id} selected={city.Id == company.County?.City.Id }>{city.Name}</option>
                        )
                    }
                </Form.Select>
                <Form.Select name="CountyDist" id="userDistrict" className="user-name-input-box mb-3" required>
                    { createDistricts() }
                </Form.Select>
                <FloatingLabel controlId="userAddress" label="Kullanıcı Adresi" className="search-box__input-box mb-3" >
                        <Form.Control rows={3} as="textarea" className="report-input" name="Address" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={company.Address}/>
                </FloatingLabel> 
                <FloatingLabel controlId="usertaxNumber" label="Vergi Numarası" className="user-name-input-box mb-3" >
                    <Form.Control className="user-name-input" type="text" name="TaxNumber" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={company.TaxNumber} />
                </FloatingLabel> 
                <FloatingLabel controlId="userPhone" label="Telefon Numarası" className="user-name-input-box mb-3" >
                    {/* <Form.Control className="user-name-input" type="text" name="PhoneNumber" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={company.PhoneNumber} /> */}
                    <MaskedInput id="userPhone" className='form-control' type='text' name='PhoneNumber' value={company.PhoneNumber} onChange={(e) => handleValueChange(e)} mask='1111  111  1111' />
                </FloatingLabel>  
                <FloatingLabel controlId="userMail" label="Mail Adresi" className="company-name-input-box mb-3" >
                    <Form.Control className="company-name-input" type="email" name="Email" onChangeCapture={(e)=>handleValueChange(e)} defaultValue={company.Email} />
                </FloatingLabel> 
                <Form.Check 
                    disabled
                    defaultChecked={company.EmailConfirmed}
                    label={company.EmailConfirmed ? "Mail Doğrulandı" : "Mail Doğrulanmadı"}
                    type="switch"
                    id={"switch" + company.Id}
                    name="emailConfirmed"
                /> 
                <Form.Check 
                    onChangeCapture={(e)=>handleValueChange(e)}
                    defaultChecked={company.Active}
                    label={company.Active ? "Aktif" : "Aktif Değil"}
                    type="switch"
                    id={"switch" + company.Id}
                />                                                                           
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleCloseWithMessage}>
                    Kaydet
                </Button>
            </Modal.Footer>
        </Modal>,
        <Modal useRef={selectedModal} show={showPrice} onHide={handleClosePrice}>
            <Modal.Header closeButton>
                <Modal.Title>Marka Distrubutor Düzenle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DistrubutorBrand company={company}></DistrubutorBrand>                                                                         
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="danger" onClick={handleCloseWithMessage}>
                    Kapat
                </Button>
            </Modal.Footer> */}
        </Modal>,
            <Toast onClose={() => setShowAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={showAlert} delay={3000} autohide>
                <Toast.Body>Bilgiler Kayıt Edilmiştir.</Toast.Body>
            </Toast>,
            <Toast onClose={() => setShowAlertFalse(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertFalse} delay={3000} autohide>
                <Toast.Body>Bilgiler Kayıt Edilemedi.</Toast.Body>
            </Toast>

        ]
}

export default CompanyRow;