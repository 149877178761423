import {React, useState} from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Toast } from "react-bootstrap";
import logo from '../../Content/pdf.png';

function MediaDetail (props) {
    const media = props.media;
    const mediaName = props.mediaName;
    const [showAlertDelete, setshowAlertDelete] =  useState(false);
    const setAddedMedia = props.setAddedMedia;
    const setAddedMediaNames = props.setAddedMediaNames;
    const getAllMedia = props.getAllMedia;

    const handleDeleteMedia = (media) => {
        axios({
            method: "delete",
            url: "https://www.listefix.com/api/Media/Delete?fileName=" + media,
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
            }).then(function (response) {
                
                setshowAlertDelete(true)
                getAllMedia();
            }).catch(function (error) {
                console.log(error.response);
        });
    }
    const updateList = () => {
        axios({
            method: "get",
            url: "https://www.listefix.com/api/Media/GetAll" ,
            headers: {
                Authorization: "Bearer "+localStorage.getItem("listfixToken")
            }
        }).then(function (response) {
            setAddedMedia(response.data.Data);
            axios({
                method: "get",
                url: "https://www.listefix.com/api/Media/GetAllNames" ,
                headers: {
                    Authorization: "Bearer "+localStorage.getItem("listfixToken")
                }
            }).then(function (response) {
                setAddedMediaNames(response.data.Data);
            }).catch(function (error) {
                console.log(error.response);
            });
        }).catch(function (error) {
            console.log(error.response);
        });
    };
    return[
        <div className={mediaName?.includes('.xlsx') ? 'd-none' : 'media-item d-flex align-items-center justify-content-between col-12 mb-2'} >
            <div className="col-11">
                {( media.includes(".jpg") || media.includes(".jpeg") || media.includes(".png") || media.includes(".webp") ) ? <img src={media} alt={mediaName} width="50" height="50" /> : <img src={logo} alt={mediaName} width="50" height="50" />}
                <label className="ps-2 fw-bold">{mediaName}</label>
            </div>
            
            
            <Button className="float-end" variant="danger" onClick={() => handleDeleteMedia(mediaName)}>X</Button>
        </div>,
        <Toast onClose={() => setshowAlertDelete(false)} className="position-fixed report-alert bg-danger text-white text-center" show={showAlertDelete} delay={3000} autohide>
            <Toast.Body>Medya Silindi</Toast.Body>
        </Toast>
    ];
}

export default MediaDetail;