import {React, useEffect, useState} from "react";
import { Container,Col ,Row} from "react-bootstrap";
import logo from "../../Content/logo-black.png";
import "./Header.scss";
import SearchBarResult from "../../components/SearchBar/SearchBarResult";
import axios from "axios";
function HeaderAccount (props) {
    const tableActive = props.tableActive;
    const activateTable= props.activateTable;
    const searchProduct = props.searchProduct;
    const Logout = () => {
        localStorage.setItem("listfixToken","");
        window.location.href = "/"
    };
    const [loginUser , setLoginUser] = useState({
        "UserRoles" : [
          {
            "Role" : {
              "Name" : "Unauth"
            }
          }
        ]
      });
      useEffect(() => {
        axios({
          method: "get",
          url: "https://www.listefix.com/api/Authenticate/GetUserInfo",
          headers: {
            Authorization: "Bearer "+localStorage.getItem("listfixToken")
          }
        }).then(function (response) {
          
          const user = response?.data?.user;
          setLoginUser(user);
    
        }).catch(function (error) {
          setLoginUser({
            "UserRoles" : [
              {
                "Role" : {
                  "Name" : "Unauth"
                }
              }
            ]
          });
    
        });
      }, []);
      const putLinks = () => {
        if(loginUser.UserRoles[0].Role.Name === "Admin" || loginUser.UserRoles[0].Role.Name === "Distributor"){
            return[
                <a className="text-success pe-4" href="/panel">Panele Git</a>,
                <a className="text-success pe-4" href="/account">Hesabım <br /> <span className="text-dark">{loginUser.UserName}</span></a>,
                <a className="text-danger" href="#" onClick={() => Logout()}>Çıkış Yap</a>
            ]
        }
        else if( loginUser.UserRoles[0].Role.Name === "Dealer") {
            return[
                <a className="text-success pe-4" href="/account">Hesabım <br /> <span className="text-dark">{loginUser.UserName}</span></a>,
                <a className="text-danger" href="#" onClick={() => Logout()}>Çıkış Yap</a>
            ]
        }

      }
    return (
        <Container className="header-container" fluid={true}>
            <Row className="pt-5 pb-5 justify-content-between">
                <Col className={"header-container__logo-box col-md-1 d-flex align-items-center"}>
                    <a className="header-container__logo-box__link" href="/search">
                        <img className="header-container__logo-box__link__image col-12" src={logo} alt="" />
                    </a>
                </Col>
                <Col className="col-auto d-flex align-items-center justify-content-end">
                    {putLinks()}
                </Col>

            </Row>

        </Container>
    );
}

export default HeaderAccount;