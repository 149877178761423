import React, {useState,useEffect} from "react";
import { Toast } from "react-bootstrap";
import ProductRowAdmin from "../ProductRow/ProductRowAdmin";
import "./SearchTable.scss";
import Filter from "./Filter";
import axios from "axios";
import { Pagination } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
function SearchTableAdmin(props)  {
  const [SuccesAlert, setSuccesAlert] = useState(false);
  const products = props.products;
  const [filteredProducts,setFilteredProducts] = useState(products.filter(product => product.Id !== 0));
  const tableActive = props.tableActive;
  const companies = props.companies;
  const loginUser = props.loginUser;
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(searchParams.get("PageIndex"));
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedAreas , setselectedAreas] = useState([
    {
        name: "filter-1",
        active: false,
    },
    {
        name: "filter-2",
        active: true,
    },
    {
        name: "filter-3",
        active: true,
    },
    {
        name: "filter-4",
        active: true,
    },
    {
        name: "filter-5",
        active: true,
    }
]);

  useEffect(() => {
    if((localStorage['selectedAreas'] === undefined || localStorage['selectedAreas'] === null)){
        localStorage['selectedAreas'] = JSON.stringify(selectedAreas);
    }
    if(localStorage['selectedAreas'] !== undefined || localStorage['selectedAreas'] !== null){
        setselectedAreas(JSON.parse(localStorage['selectedAreas']));
    }
    setFilteredProducts(products);

  }, [props.products,selectedBrand]);

    return(
      <div className={'table-responsive product-list ' +  (tableActive ? " d-block" : ' d-none')} >
        <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
          <strong>{filteredProducts.length} Ürün Listelenmekte</strong>
        </div>
        <Filter className="pt-3 pb-3" loginUser={loginUser} products={products} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand}  selectedAreas={selectedAreas} setselectedAreas={setselectedAreas}/>
        <div className="col-12 pt-3"></div>
        <table className="table table-hover table-middle table-divider align-middle pt-3">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className={selectedAreas[3].active?"d-table-cell":"d-none"}>Barkod/Kod</th>
              <th>Marka</th>
              <th className="">Ürün Adı</th>
              <th>Satıcı</th>              
              <th>Liste Fiyatı</th>
              <th className={selectedAreas[0].active?"d-table-cell":"d-none"}>Önerilen Satış Fiyatı</th>
              <th className={selectedAreas[1].active?"d-table-cell":"d-none"}>Size Özel İndirim Oranı</th>
              <th>Döviz Kuru</th>
              <th className={selectedAreas[2].active?"d-table-cell":"d-none"}>Kampanya </th>
              <th>Aktiflik</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) =>
              <ProductRowAdmin key={index} index={index + 1} product={product} selectedAreas={selectedAreas} companies={companies}/>
              )
            }
          </tbody>
        </table>
        <div className="col-12 d-flex justify-content-center">
          <Pagination>
            { pageIndex == 1 ? <></> : <Pagination.Prev href={window.location.href.replace("PageIndex="+pageIndex,"") + "PageIndex=" + (Number(pageIndex) - 1)} /> }
            <Pagination.Item active>{pageIndex}</Pagination.Item>
            {filteredProducts.length == 20 ? <Pagination.Next href={window.location.href.replace("PageIndex="+pageIndex,"") + "PageIndex=" + (Number(pageIndex) + 1)} /> : <></>}
          </Pagination>
        </div>
        <Toast onClose={() => setSuccesAlert(false)} className="position-fixed report-alert bg-success text-white text-center" show={SuccesAlert} delay={3000} autohide>
            <Toast.Body>Değişiklikleriniz Kaydedilmiştir.</Toast.Body>
        </Toast>        
      </div>
          )
}

export default SearchTableAdmin;